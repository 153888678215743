import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AUTH_REQUESTS} from '../../../../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../../../../AuthContext';
import {ConversationApi} from '../../../../../../../client/openapitools/common';
import BSCHeaderBlock from '../../../../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import BSCSelect from '../../../../../../../features/common/bscSelect/bscSelect';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../../../../features/hooks/redux/toast/useReduxToast';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../../../../redux/reducers';
import * as constants from '../../../../../../../shared/constants';
import MessagesTable from './messagesTable';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        background: '#fff',
        boxShadow: '0px 0px 4px #B8C2CC',
        // borderRadius: '4px 4px 0 0',
        padding: theme.spacing(1.5, 2),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            padding: '0',
        },
    },
    menu: {
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        padding: theme.spacing(0.5, 2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.5, 2),
        },
    },
    pageSizeLabel: {
        display: 'flex',
        height: '48px',
        alignItems: 'center',
    },
    expandIcon: {
        color: '#EA3E2E',
    },
    marginRight: {marginRight: theme.spacing(1)},
}));

function MessageList({msgRef}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [perPage, setPerPage] = useState<number>(10);
    const [page, setPage] = useState(0);
    const {apiAuthConfig} = useAuthState();
    const {isMobile} = useScreenSnap();
    const YOUR_HAVE_NO_MESSAGES = 'You Have No Messages';
    // const {loading: conversationHistoryLoading, data: conversationHistory} = useQuery(() => conversationApi.getConversationsForBuyer(), []);
    const [conversationHistoryLoading, setConversationHistoryLoading] = useState(true);
    const [conversationHistory, setConversationHistory] = useState(null);

    const conversationHistoryResults = conversationHistory?.data?.results;
    const startItemNum = perPage * page + 1;
    const endItemNum = perPage * (page + 1);
    const total = conversationHistory?.data?.totalResults;

    const {instance} = useMsal();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    const [pwaOS, setPwaOS] = useState(null);

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (apiAuthConfig) {
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            const response = await conversationApi.getConversationsForBuyer();
                            setConversationHistoryLoading(false);
                            setConversationHistory(response);
                        } catch (error) {
                            console.error('Error fetching Messages', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Messages',
                            });
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        }
    }, [apiAuthConfig]);

    useEffect(() => {
        if (conversationHistoryResults) {
            const unreadMessages = conversationHistoryResults.filter(msg => msg.read === false);
            let tempBuyersMessageCount = '';
            if (unreadMessages.length > 0) {
                tempBuyersMessageCount = unreadMessages.length + '';
            }
            dispatch({
                type: constants.BUYER_MESSAGES_COUNT,
                payload: {buyerMessagesCount: tempBuyersMessageCount},
            });
        }
    }, [conversationHistoryResults]);

    return (
        <>
            {pwaOS === null && <BSCHeaderBlock align="center" label="Messages" size18 />}

            {pwaOS !== null && !conversationHistoryResults && (
                <Grid item xs={12} style={{background: 'none', boxShadow: '0px 0px 4px #B8C2CC'}}>
                    <div style={{padding: 32, textAlign: 'center'}}>
                        <BSCTypography style={{fontSize: 16}} label={YOUR_HAVE_NO_MESSAGES} />
                    </div>
                </Grid>
            )}

            {!isMobile && (
                <>
                    <Grid container direction="row" justifyContent="space-between" className={classes.wrapper}>
                        <div className={classes.menu}>
                            <BSCTypography
                                label={`Viewing ${
                                    (total ?? 0) > 0 ? startItemNum + '-' + (endItemNum > total ? total : endItemNum) : '0'
                                } of ${(total ?? 0).toString()} Messages`}
                            />
                        </div>
                        <div className={classes.menu}>
                            <BSCTypography className={classes.marginRight} label="Results Per Page" />
                            <BSCSelect selectedSortValue={perPage} onSortChange={setPerPage}>
                                <MenuItem value={10}>
                                    <BSCTypography label="10" />
                                </MenuItem>
                                <MenuItem value={20}>
                                    <BSCTypography label="20" />
                                </MenuItem>
                                <MenuItem value={30}>
                                    <BSCTypography label="30" />
                                </MenuItem>
                            </BSCSelect>
                        </div>
                    </Grid>
                </>
            )}
            {conversationHistoryResults?.length > 0 && (
                <MessagesTable
                    page={page}
                    setPage={setPage}
                    msgRef={msgRef}
                    sizePerPage={perPage}
                    conversationHistoryResults={conversationHistoryResults}
                    loading={conversationHistoryLoading}
                />
            )}
        </>
    );
}

export default MessageList;
