/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable no-var */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {Box, Drawer, Menu, MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ChevronLeft, Close, MoreHoriz} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StorefrontIcon from '@material-ui/icons/Storefront';
import {Skeleton} from '@material-ui/lab';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import {Link} from 'react-router-dom';
import {Menus} from '../../../../@types/menus';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {Configuration, ConversationApi} from '../../../../client/openapitools/common';
import {UserApi} from '../../../../client/openapitools/marketplace/api';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import {ReactComponent as BSCBuyersLogo} from '../../../../svgs/bscBuyers.svg';
import AsyncSearchInput from '../../../asyncSearchInput/asyncSearchInput';
import CartButton from '../../../cartButton/cartButton';
import BSCButton from '../../../common/bscButton/bscButton';
import BSCDivider from '../../../common/bscDivider/bscDivider';
import BSCLoading from '../../../common/bscLoading/bscLoading';
import BSCTypography from '../../../common/bscTypography/bscTypography';
import useReduxToast from '../../../hooks/redux/toast/useReduxToast';
import {SignInButton} from '../../../users/signInButton';
import {SignOutButton} from '../../../users/signOutButton';
import SubNavContent from '../subNavContent';
import './styles.scss';

const useStyles = makeStyles((theme: Theme) => ({
    headerContainer: {
        background: 'black',
    },
    headerWrap: {
        maxWidth: 1400,
        margin: 'auto',
        paddingTop: 53,
    },
    logo: {
        height: 50,
        width: 85,
        //This is the width of the Icon Button Divs.  Yes its a hack but it gets things centered correctly
        // marginLeft: '20%',
        ['&:hover']: {
            cursor: 'pointer',
        },
    },

    menuItem: {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
        boxShadow:
            '0px 1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px -1.91111px 3.82222px rgba(0, 0, 0, 0.25), inset 0px 3.82222px 3.82222px rgba(255, 255, 255, 0.24)',
        borderRadius: 4,
        textAlign: 'center',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        ...theme.typography.button,
        textTransform: 'inherit',
        fontSize: theme.typography.body2.fontSize,
    },
    mobileSubNavContainer: {
        background: theme.palette.common.black,
        height: '100%',
        maxHeight: '100%',
    },
    menuTitle: {
        position: 'relative',
        color: theme.palette.common.white,
        padding: theme.spacing(2, 0),
        background: 'linear-gradient(180deg, #41474D -0.23%, #030303 27.26%)',
        textAlign: 'center',
    },
    chevronLeft: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    mobileSubNavWrap: {
        padding: theme.spacing(0, 1),
        // maxHeight: 'calc(90vh-70px)',
        overflowY: 'scroll',
        height: 'calc(100% - 70px)',
    },
    drawer: {
        width: '100%',
    },
    normal: {
        fontStyle: 'normal',
    },
    capitalCase: {
        textTransform: 'none!important' as 'none',
    },
    fillBlack: {
        fill: 'black',
    },
    largeIcon: {
        marginRight: '-15px',
        '& svg': {
            height: 32,
            width: 32,
        },
    },
    lineHeight15: {
        lineHeight: 1.5,
    },
    messagesBadge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 8,
        left: -2,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    messagesBadgeHidden: {
        display: 'none',
    },
    menuMessagesBadge: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.palette.common.white,
        position: 'absolute',
        top: 2,
        right: 8,
        zIndex: 1,
        background: theme.palette.primary.main,
        padding: 5,
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
    },
    navLinks: {
        color: 'transparent',
    },
    filterWrap: {
        marginBottom: 4,
    },
    sliderNoWrap: {
        flexWrap: 'nowrap !important' as 'nowrap',
    },
    horizontalRibbon: {
        marginBottom: 6,
        width: '100%',
        overflowX: 'scroll',
        ['&::-webkit-scrollbar']: {
            display: 'none !important',
        },
    },
    pwaNavbarSearchbox: {
        marginTop: 2,
        marginLeft: 8,
    },
    pwaCartMargins: {
        margin: '4px 8px 0 0 !important',
    },
    playerNameContainer: {
        maxWidth: 225,
    },
    playerNameNumberCombo: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

interface PWANavProps {
    menus: Menus[];
    menusLoading?: boolean;
    userMenuLoading?: boolean;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMenu: undefined;
    pwaSportsFilter?: string;
}

const MenuStepEnum = {
    SPORTS: 0,
    SUB_NAV: 1,
};

function MobileDrawer({menus, open, onClose, menusLoading, setCurrentMenuStep, currentMenuStep, pwaSportsFilter}) {
    const classes = useStyles();
    const [selectedMenu, setSelectedMenu] = useState(null);

    useEffect(() => {
        if (open) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = null;
        }
    }, [open]);

    const [potentialNavigationRoutes, setPotentialNavigationRoutes] = useState([]);
    useEffect(() => {
        if (menus && menus.categories && menus.categories.length > 0) {
            var tempMenus = [];
            for (let x = 0; x < menus.categories.length; x++) {
                tempMenus.push({
                    focus: menus.categories[x].title,
                    url: menus.categories[x].url,
                });
                for (let y = 0; y < menus.categories[x].menus.length; y++) {
                    tempMenus.push({
                        focus: menus.categories[x].title + ' - ' + menus.categories[x].menus[y].title,
                        url: menus.categories[x].menus[y].url,
                    });
                    for (let z = 0; z < menus.categories[x].menus[y].links.length; z++) {
                        tempMenus.push({
                            focus:
                                menus.categories[x].title +
                                ' - ' +
                                menus.categories[x].menus[y].title +
                                ' - ' +
                                menus.categories[x].menus[y].links[z].title,
                            url: menus.categories[x].menus[y].links[z].url,
                        });
                    }
                }
            }
            setPotentialNavigationRoutes(tempMenus);
            if (pwaSportsFilter !== null) {
                setSelectedMenu(menus.categories.find(menu => menu.title === pwaSportsFilter));
            }
        }
    }, [menus, pwaSportsFilter]);

    return (
        <>
            <Drawer open={open} onClose={onClose} anchor="left" className={classes.drawer} PaperProps={{className: classes.drawer}}>
                {pwaSportsFilter === null && (
                    <div className={classes.mobileSubNavContainer}>
                        <div className={classes.menuTitle}>
                            {currentMenuStep > MenuStepEnum.SPORTS && (
                                <IconButton className={classes.chevronLeft} onClick={() => setCurrentMenuStep(s => s - 1)}>
                                    <ChevronLeft fontSize="large" htmlColor="white" />
                                </IconButton>
                            )}
                            <BSCTypography
                                variant={currentMenuStep === 0 ? 'h2' : 'h3'}
                                capitalize
                                label={currentMenuStep === 0 ? 'Sports' : selectedMenu?.title.toLowerCase()}
                            />
                            <IconButton className={classes.closeButton} onClick={onClose}>
                                <Close fontSize="large" htmlColor="white" />
                            </IconButton>
                        </div>
                        <div className={classes.mobileSubNavWrap}>
                            <div>
                                {currentMenuStep === MenuStepEnum.SPORTS && (
                                    <>
                                        <BSCLoading loading={menusLoading} />
                                        {menus?.categories?.map(menu => (
                                            <MenuItem
                                                key={menu.order}
                                                className={classes.menuItem}
                                                onClick={() => {
                                                    setCurrentMenuStep(MenuStepEnum.SUB_NAV);
                                                    setSelectedMenu(menu);
                                                }}
                                            >
                                                {menu.title}
                                            </MenuItem>
                                        ))}
                                    </>
                                )}

                                {currentMenuStep === MenuStepEnum.SUB_NAV && (
                                    <SubNavContent
                                        menus={selectedMenu?.menus}
                                        menuId={selectedMenu?.order}
                                        onGoatClick={onClose}
                                        url={selectedMenu?.url}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {pwaSportsFilter !== null && selectedMenu && selectedMenu.menus && (
                    <div className={classes.mobileSubNavContainer}>
                        <div className={classes.menuTitle}>
                            <BSCTypography variant={'h3'} capitalize label={pwaSportsFilter} />
                            <IconButton className={classes.closeButton} onClick={onClose}>
                                <Close fontSize="large" htmlColor="white" />
                            </IconButton>
                        </div>
                        <div className={classes.mobileSubNavWrap}>
                            <SubNavContent
                                menus={selectedMenu?.menus}
                                menuId={selectedMenu?.order}
                                onGoatClick={onClose}
                                url={selectedMenu?.url}
                            />
                        </div>
                    </div>
                )}
            </Drawer>

            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: -1500, left: -2500}}>
                    {potentialNavigationRoutes &&
                        potentialNavigationRoutes.map((navRoute, index) => (
                            <a href={navRoute.url} key={index} className={classes.navLinks}>
                                {navRoute.focus}
                            </a>
                        ))}
                </div>
            </div>
        </>
    );
}

function PWANav({menus, menusLoading = false, userMenuLoading, userMenu}: PWANavProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [accountInfoOpen, setAccountInfoOpen] = useState(false);
    const {instance, inProgress} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const history = useHistory();
    const dispatch = useDispatch();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    // const {userState} = useReduxUser();
    const [userState, setUserState] = useState(null);
    const [currentMenuStep, setCurrentMenuStep] = useState(0);
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const isPWAAccount = useRouteMatch('/pwa/dashboard/account');
    const isPWABuyerDashboard = useRouteMatch('/pwa/dashboard/buyer');
    const isPWASearch = useRouteMatch('/pwa/dashboard/search');
    const isPWASearchResults = useRouteMatch('/search');
    const isPWAProductListing = useRouteMatch('/product');
    const isPWACart = useRouteMatch('/cart');
    const isPWASupport = useRouteMatch('/support');
    const isStandardFAQs = useRouteMatch('/faq');
    const isPWAFAQs = useRouteMatch('/dashboard/faq');
    const isMessages = useRouteMatch('/dashboard/messages/reply/');

    const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
    const handleMenuOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenMenu(event.currentTarget);
        },
        [setOpenMenu]
    );
    const handleMenuClose = useCallback(() => {
        setOpenMenu(null);
    }, [setOpenMenu]);

    const handleClose = useCallback(() => {
        setOpen(false);
        setAccountInfoOpen(false);
    }, []);

    const [buyerMessagesCount, setBuyerMessagesCount] = useState('');
    const [sellersMessagesCount, setSellersMessagesCount] = useState('');
    const [pwaOS, setPwaOS] = useState(null);
    const [pwaSportsFilter, setPWASportsFilter] = useState(null);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [isShowingBSCLogo, setIsShowingBSCLogo] = useState(true);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.buyerMessagesCount !== '') {
                setBuyerMessagesCount(marketplaceState.buyerMessagesCount);
            } else {
                setBuyerMessagesCount('');
            }
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            if (marketplaceState.pwaNavbarActive > 0) {
                setIsShowingBSCLogo(false);
            } else {
                setIsShowingBSCLogo(true);
            }
        }
    }, [marketplaceState]);

    const [sellerStoreName, setSellerStoreName] = useState('');
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            conversationApi.getConversationsForBuyer().then(res => {
                                const messagesData = res?.data.results;
                                if (messagesData !== null) {
                                    const unreadMessages = messagesData.filter(msg => msg.read === false);
                                    let tempBuyerMessagesCount = '';
                                    if (unreadMessages.length > 0) {
                                        tempBuyerMessagesCount = unreadMessages.length + '';
                                    }
                                    setBuyerMessagesCount(tempBuyerMessagesCount);
                                    dispatch({
                                        type: constants.BUYER_MESSAGES_COUNT,
                                        payload: {buyerMessagesCount: tempBuyerMessagesCount},
                                    });
                                }
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching buyer messages.',
                                contextKey: 'Messages',
                            });
                        }
                        try {
                            const conversationApi = new ConversationApi(apiConfig);
                            conversationApi.getConversationsForSeller().then(res => {
                                const messagesData = res?.data.results;
                                if (messagesData !== null) {
                                    const unreadMessages = messagesData.filter(msg => msg.read === false);
                                    let tempSellersMessagesCount = '';
                                    if (unreadMessages.length > 0) {
                                        tempSellersMessagesCount = unreadMessages.length + '';
                                    }
                                    setSellersMessagesCount(tempSellersMessagesCount);
                                    dispatch({
                                        type: constants.SELLER_MESSAGES_COUNT,
                                        payload: {sellerMessagesCount: tempSellersMessagesCount},
                                    });
                                }
                            });
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching seller messages.',
                                contextKey: 'Messages',
                            });
                        }
                        try {
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            setUserState(response.data);
                            setUserFirstName(response.data.firstName);
                            setUserLastName(response.data.lastName);
                        } catch (error) {
                            console.error('Error fetching user profile', error);
                            addToast({
                                severity: 'error',
                                message: 'Error fetching user profile',
                                contextKey: 'User Profile',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    const [hasSellerDataReady, setHasSellerDataReady] = useState(false);
    useEffect(() => {
        setHasSellerDataReady(false);
        if (marketplaceState.pwaSellerStorefrontCardsSold) {
            setTimeout(() => {
                setHasSellerDataReady(true);
            }, 500);
        }
    }, [marketplaceState.pwaSellerStorefrontCardsSold]);

    const showSportsChips = () => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 0,
            },
        });
    };

    const navToSearch = () => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 2,
            },
        });
        history.push('/pwa/dashboard/search');
    };

    const handleCartSupport = () => {
        history.push('/support');
        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 7,
            },
        });
    };

    const handleCartFAQs = () => {
        history.push('/dashboard/faq');
        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 6,
            },
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleReturnToPWABuyerDashboard = () => {
        if (isMessages) {
            history.goBack();
        } else {
            history.push('/pwa/dashboard/buyer');
            dispatch({
                type: constants.PWA_BUYER_DASHBOARD_SELECTION,
                payload: {pwaBuyerDashboardSelection: 0},
            });
        }
    };

    const handleReturnToSearchQuery = () => {
        history.push('/pwa/dashboard/search');
        dispatch({
            type: constants.SELLER_STOREFRONT_RESET,
            payload: {},
        });
        dispatch({
            type: constants.PWA_SELLER_STOREFRONT_FEEDBACK_RATING,
            payload: {pwaSellerStorefrontFeedbackRating: 0},
        });
        dispatch({
            type: constants.PWA_SELLER_STOREFRONT_CARDS_SOLD,
            payload: {pwaSellerStorefrontCardsSold: 0},
        });
    };

    const checkForPreviousProductListing = () => {
        if (localStorage.getItem('previousProductPageLink') !== null) {
            const quickRoute = localStorage.getItem('previousProductPageLink');
            localStorage.removeItem('previousProductPageLink');
            dispatch({
                type: constants.SELLER_STOREFRONT_RESET,
                payload: {},
            });
            history.push(quickRoute);
        } else {
            handleReturnToSearchQuery();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleReturnToPrevious = () => {
        localStorage.setItem('hasVisitedProductPage', 'true');
        if (!isPWACart) {
            history.goBack();
        } else {
            history.push('/');
        }
    };

    return (
        <>
            <div className={classes.headerContainer}>
                <div className={classes.headerWrap}>
                    <Grid container>
                        {marketplaceState.pwaNavbarActive === 0 && !isPWACart && !isPWASupport && !isPWAFAQs && (
                            <>
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <IconButton style={{visibility: 'hidden'}}>
                                            <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <BSCBuyersLogo
                                            className={classes.logo}
                                            onClick={() => {
                                                showSportsChips();
                                                history.push('/');
                                            }}
                                        />
                                    </Grid>

                                    <Grid item className={classes.pwaCartMargins}>
                                        <CartButton />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container>
                                    <Grid item xs={12} onClick={navToSearch}>
                                        <AsyncSearchInput />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {marketplaceState.pwaNavbarActive === 1 && isPWAAccount && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <IconButton style={{visibility: 'hidden'}}>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid>

                                <Grid item>
                                    <BSCTypography size24 style={{color: '#fff'}} label=" Account" />
                                </Grid>

                                <Grid item className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}
                        {marketplaceState.pwaNavbarActive === 1 && isPWABuyerDashboard && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item xs={10} container direction="column" style={{paddingLeft: 16}}>
                                    <Grid>
                                        <BSCTypography size14 style={{color: '#fff'}} label="Welcome Back," />
                                    </Grid>
                                    <Grid>
                                        <BSCTypography size24 style={{color: '#fff'}} label={`${userFirstName} ${userLastName}`} />
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}
                        {marketplaceState.pwaNavbarActive === 1 &&
                            marketplaceState.pwaBuyerDashboardSelection === 1 &&
                            !isPWACart &&
                            !isPWASupport &&
                            !isPWAFAQs && (
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                    <Grid item onClick={handleReturnToPWABuyerDashboard}>
                                        <IconButton>
                                            <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <BSCTypography size24 style={{color: '#fff'}} label="Profile" />
                                    </Grid>

                                    <Grid item className={classes.pwaCartMargins}>
                                        <CartButton />
                                    </Grid>
                                </Grid>
                            )}
                        {marketplaceState.pwaNavbarActive === 1 &&
                            marketplaceState.pwaBuyerDashboardSelection === 3 &&
                            !isPWACart &&
                            !isPWASupport &&
                            !isPWAFAQs && (
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                    <Grid item onClick={handleReturnToPWABuyerDashboard}>
                                        <IconButton>
                                            <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <BSCTypography size24 style={{color: '#fff'}} label="Order History" />
                                    </Grid>

                                    <Grid item className={classes.pwaCartMargins}>
                                        <CartButton />
                                    </Grid>
                                </Grid>
                            )}
                        {marketplaceState.pwaNavbarActive === 1 &&
                            marketplaceState.pwaBuyerDashboardSelection === 4 &&
                            !isPWACart &&
                            !isPWASupport &&
                            !isPWAFAQs && (
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                    <Grid item onClick={handleReturnToPWABuyerDashboard}>
                                        <IconButton>
                                            <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <BSCTypography size24 style={{color: '#fff'}} label="Offers" />
                                    </Grid>

                                    <Grid item className={classes.pwaCartMargins}>
                                        <CartButton />
                                    </Grid>
                                </Grid>
                            )}
                        {marketplaceState.pwaNavbarActive === 1 &&
                            marketplaceState.pwaBuyerDashboardSelection === 5 &&
                            !isPWACart &&
                            !isPWASupport &&
                            !isPWAFAQs && (
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                    <Grid item onClick={handleReturnToPWABuyerDashboard}>
                                        <IconButton>
                                            <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <BSCTypography size24 style={{color: '#fff'}} label="Messages" />
                                    </Grid>

                                    <Grid item className={classes.pwaCartMargins}>
                                        <CartButton />
                                    </Grid>
                                </Grid>
                            )}
                        {(isPWAFAQs || isStandardFAQs) && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item onClick={handleReturnToPrevious}>
                                    <IconButton>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid>

                                <Grid item>
                                    <BSCTypography size24 style={{color: '#fff'}} label="FAQs" />
                                </Grid>

                                <Grid item className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}
                        {isPWASupport && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item onClick={handleReturnToPrevious}>
                                    <IconButton>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid>

                                <Grid item>
                                    <BSCTypography size24 style={{color: '#fff'}} label="Contact Support" />
                                </Grid>

                                <Grid item className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}
                        {marketplaceState.pwaNavbarActive === 2 && isPWASearch && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                {/* <Grid item onClick={handleReturnToPrevious}>
                                    <IconButton>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid> */}

                                <Grid item>
                                    <AsyncSearchInput />
                                </Grid>

                                <Grid item className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}
                        {marketplaceState.pwaNavbarActive === 2 &&
                            isPWASearchResults &&
                            marketplaceState.sellerStorefront['sellerId'] !== undefined && (
                                <>
                                    <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <IconButton onClick={checkForPreviousProductListing}>
                                                <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            {hasSellerDataReady === false && (
                                                <Grid container direction="column" style={{width: 270}}>
                                                    <Grid item xs={12}>
                                                        <Skeleton
                                                            variant="rect"
                                                            height={48}
                                                            style={{margin: '2px 0', borderRadius: 8, background: '#ccc'}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Skeleton
                                                            variant="rect"
                                                            height={16}
                                                            style={{margin: '4px 0', borderRadius: 2, background: '#ccc'}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {hasSellerDataReady && (
                                                <Grid container direction="column">
                                                    <Grid item xs={12}>
                                                        <BSCTypography
                                                            size24
                                                            label={marketplaceState.sellerStorefront['sellerName']}
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                width: 220,
                                                                lineHeight: 'inherit',
                                                                color: '#fff',
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <BSCTypography
                                                            size14
                                                            style={{color: '#fff'}}
                                                            label={`${marketplaceState.pwaSellerStorefrontFeedbackRating}% Feedback (${marketplaceState.pwaSellerStorefrontCardsSold} Cards Sold)`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item className={classes.pwaCartMargins}>
                                            <CartButton />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                                        <Grid>
                                            <AsyncSearchInput />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        {marketplaceState.pwaNavbarActive === 2 &&
                            isPWASearchResults &&
                            marketplaceState.sellerStorefront['sellerId'] === undefined && (
                                <>
                                    <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                        <Grid>
                                            <IconButton onClick={() => history.push('/pwa/dashboard/search')}>
                                                <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                            </IconButton>
                                        </Grid>

                                        <Grid>
                                            <AsyncSearchInput />
                                        </Grid>

                                        <Grid className={classes.pwaCartMargins}>
                                            <CartButton />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        {marketplaceState.pwaNavbarActive === 2 && isPWAProductListing && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item onClick={handleReturnToPrevious}>
                                    <IconButton>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid>

                                <Grid item container className={classes.playerNameContainer}>
                                    <Grid item xs={12} className={classes.playerNameNumberCombo}>
                                        <BSCTypography
                                            size18
                                            style={{
                                                color: '#fff',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                textAlign: 'right',
                                            }}
                                            label={`${marketplaceState.pwaPlayerName}`}
                                        />
                                        <BSCTypography
                                            size18
                                            style={{
                                                color: '#fff',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                textAlign: 'left',
                                                paddingLeft: 6,
                                            }}
                                            label={`#${marketplaceState.pwaCardNumber}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BSCTypography
                                            size10
                                            style={{
                                                color: '#fff',
                                                textAlign: 'center',
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                            label={
                                                marketplaceState.pwaVariantName !== ''
                                                    ? `${marketplaceState.pwaSetName} - ${marketplaceState.pwaVariantName}`
                                                    : `${marketplaceState.pwaSetName} - ${marketplaceState.pwaVariant}`
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}

                        {marketplaceState.pwaNavbarActive === 4 &&
                            (marketplaceState.pwaBuyerDashboardSelection === 2 || marketplaceState.pwaBuyerDashboardSelection === 3) &&
                            isPWAProductListing && (
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                    <Grid item onClick={handleReturnToPrevious}>
                                        <IconButton>
                                            <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                        </IconButton>
                                    </Grid>

                                    <Grid item container className={classes.playerNameContainer}>
                                        <Grid item xs={12} className={classes.playerNameNumberCombo}>
                                            <BSCTypography
                                                size18
                                                style={{
                                                    color: '#fff',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    textAlign: 'right',
                                                }}
                                                label={`${marketplaceState.pwaPlayerName}`}
                                            />
                                            <BSCTypography
                                                size18
                                                style={{
                                                    color: '#fff',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    textAlign: 'left',
                                                    paddingLeft: 6,
                                                }}
                                                label={`#${marketplaceState.pwaCardNumber}`}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <BSCTypography
                                                size10
                                                style={{
                                                    color: '#fff',
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}
                                                label={
                                                    marketplaceState.pwaVariantName !== ''
                                                        ? `${marketplaceState.pwaSetName} - ${marketplaceState.pwaVariantName}`
                                                        : `${marketplaceState.pwaSetName} - ${marketplaceState.pwaVariant}`
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid className={classes.pwaCartMargins}>
                                        <CartButton />
                                    </Grid>
                                </Grid>
                            )}

                        {isPWACart && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item onClick={() => history.goBack()}>
                                    <IconButton>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid>

                                <Grid item>
                                    <BSCTypography size24 style={{color: '#fff'}} label="Cart" />
                                </Grid>

                                <Grid item onClick={handleMenuOpen}>
                                    <IconButton>
                                        <MoreHoriz htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                        {marketplaceState.pwaNavbarActive === 3 && !isPWACart && (
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                {/* <Grid item onClick={handleReturnToPWABuyerDashboard}>
                                    <IconButton>
                                        <ChevronLeftIcon htmlColor="white" fontSize={'large'} />
                                    </IconButton>
                                </Grid> */}

                                <Grid item>
                                    <BSCTypography size24 style={{color: '#fff', padding: '2px 0 0 16px'}} label="Notifications" />
                                </Grid>

                                <Grid item className={classes.pwaCartMargins}>
                                    <CartButton />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div>
            {
                <Drawer
                    PaperProps={{style: {maxWidth: '90%'}}}
                    anchor={'right'}
                    open={accountInfoOpen}
                    onClose={() => setAccountInfoOpen(false)}
                >
                    <div align="center">
                        <Box p={2}>
                            {inProgress === 'none' && (
                                <>
                                    <AuthenticatedTemplate>
                                        <>
                                            {userState?.firstName && (
                                                <div>
                                                    <BSCTypography
                                                        variant="h4"
                                                        className={classes.lineHeight15}
                                                        align="center"
                                                        label={userState?.firstName ? `Welcome Back, ${userState?.firstName}!` : 'Hello!'}
                                                    />
                                                    <BSCDivider />
                                                </div>
                                            )}
                                            {userMenuLoading && <Skeleton />}
                                            {!userMenuLoading && userMenu && (
                                                <>
                                                    {userMenu.dashboard.map((x, idx) => (
                                                        <div key={`userMenu-${idx}`} style={{position: 'relative'}}>
                                                            <span
                                                                style={x['title'] === 'Messages' ? {display: 'flex'} : {display: 'none'}}
                                                                className={
                                                                    buyerMessagesCount !== ''
                                                                        ? classes.menuMessagesBadge
                                                                        : classes.messagesBadgeHidden
                                                                }
                                                            >
                                                                {buyerMessagesCount}
                                                            </span>
                                                            <BSCTypography
                                                                align="center"
                                                                variant="body2"
                                                                gutterBottom
                                                                size16
                                                                onClick={() => {
                                                                    handleClose();
                                                                    history.push({
                                                                        pathname: x['url'],
                                                                    });
                                                                }}
                                                                label={x['title']}
                                                            />
                                                            <BSCDivider />
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                            <div>
                                                <BSCButton
                                                    className={(classes.normal, classes.capitalCase)}
                                                    variant="text"
                                                    fullWidth
                                                    component={Link}
                                                    startIcon={<StorefrontIcon color="primary" />}
                                                    //Let sellers locker handle sign up / contact us / whatever
                                                    to={'/sellers'}
                                                >
                                                    <BSCTypography align="center" variant="body2" normal size16 label="Seller's Locker" />
                                                </BSCButton>
                                                <BSCDivider />
                                                <SignOutButton
                                                    fullWidth
                                                    align="center"
                                                    size16
                                                    variant="text"
                                                    className={classes.capitalCase}
                                                />
                                            </div>
                                        </>
                                    </AuthenticatedTemplate>
                                    <UnauthenticatedTemplate>
                                        <>
                                            <SignInButton
                                                userMenuLoading={userMenuLoading}
                                                userMenu={userMenu}
                                                className={classes.capitalCase}
                                                fullWidth
                                                align="center"
                                                variant="body2"
                                                color="textPrimary"
                                            />
                                        </>
                                    </UnauthenticatedTemplate>
                                </>
                            )}
                        </Box>
                    </div>
                </Drawer>
            }
            <MobileDrawer
                open={open}
                menus={menus}
                onClose={handleClose}
                currentMenuStep={currentMenuStep}
                setCurrentMenuStep={setCurrentMenuStep}
                menusLoading={menusLoading}
                pwaSportsFilter={pwaSportsFilter}
            />
            <Menu id="simple-menu" anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClose}>
                    <BSCButton color="secondary" onClick={handleCartSupport}>
                        <BSCTypography label="Contact Support" size14 />
                    </BSCButton>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                    <BSCButton fullWidth color="secondary" onClick={handleCartFAQs}>
                        <BSCTypography label="FAQs" size14 />
                    </BSCButton>
                </MenuItem>
            </Menu>
        </>
    );
}

export default memo(PWANav);
