import {AlertProps} from '@material-ui/lab';
import {createAction, createSlice, nanoid} from '@reduxjs/toolkit';
import {addToCart, deleteCart, deleteCartAll, mergeCart, updateCart} from './cart.slice';

export interface Toast {
    id?: string;
    contextKey?: string;
    message: string;
    severity: AlertProps['severity'];
}

interface ToastState {
    que: Toast[];
}

export const addToast = createAction<Toast>('toast/addToast');
export const removeToast = createAction<string>('toast/removeToast');

const initialState = {que: []} as ToastState;

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(addToast, (state, action) => {
            state.que.push({
                id: nanoid(),
                contextKey: action.payload.contextKey,
                message: action.payload.message,
                severity: action.payload.severity,
            });
        });
        builder.addCase(removeToast, (state, action) => {
            const filteredQue = state.que.filter(toast => toast.id !== action.payload);
            state.que = filteredQue;
        });
        builder.addCase(updateCart.rejected, (state, action) => {
            state.que.push({
                id: nanoid(),
                contextKey: 'Update Cart',
                message: action.error.message,
                severity: 'error',
            });
        });
        builder.addCase(addToCart.rejected, (state, action) => {
            state.que.push({
                id: nanoid(),
                contextKey: 'Add To Cart',
                message: action.error.message,
                severity: 'error',
            });
        });
        builder.addCase(deleteCart.rejected, (state, action) => {
            state.que.push({
                id: nanoid(),
                contextKey: 'Delete Cart',
                message: action.error.message,
                severity: 'error',
            });
        });
        builder.addCase(deleteCartAll.rejected, (state, action) => {
            state.que.push({
                id: nanoid(),
                contextKey: 'Delete Cart All',
                message: action.error.message,
                severity: 'error',
            });
        });
        builder.addCase(mergeCart.rejected, (state, action) => {
            state.que.push({
                id: nanoid(),
                contextKey: 'Merge Cart',
                message: action.error.message,
                severity: 'error',
            });
        });
    },
});

export default toastSlice.reducer;
