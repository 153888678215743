import {Redirect, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import * as constants from '../../../../shared/constants';

interface PlaceOrderSuccess {
    email: string;
    estimatedDate: string;
    message: {description: string; level: string};
    orderId: string;
}

interface ThankYouData {
    thankYou: PlaceOrderSuccess;
}

const useStyles = makeStyles(() => ({
    textCenter: {
        textAlign: 'center',
    },
}));

function ThankYou() {
    const {state}: {state: ThankYouData} = useLocation();
    const dispatch = useDispatch();
    const thankYouData = state?.thankYou;
    const classes = useStyles();

    const viewHistoryHandler = () => {
        dispatch({
            type: constants.PWA_NAVBAR_ACTIVE_TAB,
            payload: {
                pwaNavbarActive: 1,
            },
        });
        dispatch({
            type: constants.PWA_BUYER_DASHBOARD_SELECTION,
            payload: {
                pwaBuyerDashboardSelection: 3,
            },
        });
    };

    if (!thankYouData) {
        return <Redirect push to="/cart/checkout" />;
    }

    return (
        <BSCContainer variant="page">
            <BSCCardBox className={classes.textCenter}>
                <BSCTypography variant="h2" label="Thank you!" />
                {/* <BSCTypography label={`Your order will arrive by ${thankYouData.estimatedDate}`} /> */}
                <BSCDivider />
                <BSCTypography
                    label={`For all your order details, check out the confirmation email we’re sending to ${thankYouData.email}`}
                />
                <BSCTypography label={`Confirmation #: ${thankYouData.confirmationNumber}`} />
                <BSCButton onClick={viewHistoryHandler} component={Link} to="/dashboard/orderHistory" color="primary">
                    <BSCTypography size12 label={'View Order History'} />
                </BSCButton>
            </BSCCardBox>
        </BSCContainer>
    );
}

export default ThankYou;
