/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useHistory, useParams} from 'react-router';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {useCardNormalizer} from '../../../../client/common/card/cardNormalizer';
import {Configuration} from '../../../../client/openapitools/common';
import {AccountApi, Product} from '../../../../client/openapitools/seller';
import BSCBack from '../../../../features/common/bscBack/bscBack';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import ProductListings from '../../../marketplace/pages/productPage/productListings';
import SellYourCardAdd from './components/sellYourCardAdd';
import SellYourCardSidebar from './components/sellYourCardSidebar';
import SellYourCardTabs from './components/sellYourCardTabs';
import SellYourCardUpdate from './components/sellYourCardUpdate';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0 0 8px 0',
    },
    rootPWA: {
        padding: '0 0 8px 0',
        // height: 680, hide the footer in PWA
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    detailsContainer: {
        margin: 0,
    },
    fontBlack: {
        color: 'black',
    },
}));

function SellYourCard() {
    const classes = useStyles();
    const normalizer = useCardNormalizer();
    const history = useHistory();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const dispatch = useDispatch();
    const {addToast} = useReduxToast();
    const {isBelowTablet, isMobile} = useScreenSnap();
    const {cardId} = useParams<{cardId: string}>();
    const [newSettings, setNewSettings] = useState(null);
    const {apiAuthConfig} = useAuthState();
    const {instance} = useMsal();
    const authFunc = useAuthFunctions();

    const [pwaOS, setPwaOS] = useState(null);
    const [pwaSellYourCardsActiveTab, setPwaSellYourCardsActiveTab] = useState(0);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
            setPwaSellYourCardsActiveTab(marketplaceState.pwaSellYourCardsActiveTab);
        }
    }, [marketplaceState]);

    const [isVintage, setVintage] = useState(false);
    const [sportId, setSportId] = useState(null);

    const [sellerCard, setSellerCard] = useState(null);
    const [sellerCardLoading, setSellerCardLoading] = useState(true);
    const [sellerCardInstance, setSellerCardInstance] = useState(null);
    const [sellerSettings, setSellerSettings] = useState(null);
    const [sellerSettingsLoading, setSellerSettingsLoading] = useState(true);
    const [selectedListing, setSelectedListing] = useState(null);
    const [sellYourCardsListings, setSellYourCardsListings] = useState(null);
    const [cardYear, setCardYear] = useState(null);
    useEffect(() => {
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setSellerCardLoading(true);
                            const accountApi = new AccountApi(apiConfig);
                            const cardListingsResponse = await accountApi.getCardListings(cardId);
                            if (cardListingsResponse.status === 200) {
                                setSelectedListing(cardListingsResponse.data.listings[0]);
                                setSellerCard(cardListingsResponse);
                                setSellYourCardsListings(cardListingsResponse.data.listings);
                                setCardYear(cardListingsResponse.data.year);
                                dispatch({
                                    type: constants.CARD_IMAGE_DEFAULT,
                                    payload: {
                                        imageFront: cardListingsResponse.data.stockImgFront,
                                        imageBack: cardListingsResponse.data.stockImgBack,
                                    },
                                });
                                dispatch({
                                    type: constants.PWA_PLAYER_NAME,
                                    payload: {pwaPlayerName: cardListingsResponse.data.players},
                                });
                                dispatch({
                                    type: constants.PWA_CARD_NUMBER,
                                    payload: {pwaCardNumber: cardListingsResponse.data.cardNo},
                                });
                                dispatch({
                                    type: constants.PWA_SET_NAME,
                                    payload: {pwaSetName: cardListingsResponse.data.setName},
                                });
                                dispatch({
                                    type: constants.PWA_VARIANT_NAME,
                                    payload: {pwaVariantName: cardListingsResponse.data.variantName},
                                });
                                dispatch({
                                    type: constants.PWA_VARIANT,
                                    payload: {pwaVariant: cardListingsResponse.data.variant},
                                });
                                if (cardListingsResponse.data.year) {
                                    const tempYear = Number(cardListingsResponse.data.year);
                                    if (tempYear <= 1980) {
                                        setVintage(true);
                                    } else {
                                        setVintage(false);
                                    }
                                }
                                setSportId(cardListingsResponse.data.sport);
                            }
                            setSellerCardLoading(false);
                            setSellerSettingsLoading(true);
                            const productConfigResponse = await accountApi.getProductConfig(cardId);
                            if (productConfigResponse.status === 200) {
                                setSellerSettingsLoading(false);
                                setSellerSettings(productConfigResponse);
                                setSellerCardInstance(productConfigResponse);
                            }
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching Card listings',
                                contextKey: 'Sell Your Card',
                            });
                            setSellerCardLoading(false);
                            setSellerSettingsLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [cardId, instance, apiAuthConfig]);

    const [addCardLoading, setAddCardLoading] = useState(false);
    const [listings, setListings] = useState<Product[]>();
    const [totalAvailableQuantity, setTotalAvailableQuantity] = useState(0);
    const [sellerResults, setSellerResults] = useState(null);
    useEffect(() => {
        setListings(sellerCard?.data.listings);
        if (sellerCard && sellerCard.data) {
            let total = 0;
            sellerCard.data.listings?.forEach(listing => (total += listing.availableQuantity));
            setTotalAvailableQuantity(total);
            setSellerResults(sellerCard?.data);
        }
    }, [sellerCard]);

    const handleAddCard = data => {
        const account = instance.getActiveAccount();
        if (cardId && account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            setAddCardLoading(true);
                            let additionalQuantity = 0;
                            additionalQuantity = totalAvailableQuantity + parseInt(data.availableQuantity, 10);
                            setTotalAvailableQuantity(additionalQuantity);
                            data.productId = newSettings?.productId ?? sellerSettings.data.productId;
                            data['sportId'] = sportId;
                            // API removed from the data object
                            if (data.quantity) {
                                delete data.quantity;
                            }
                            const accountApi = new AccountApi(apiConfig);
                            const sellerCardInformation = {
                                action: 'add',
                                listing: {...data},
                            };
                            const sellerInfoResponse = await accountApi.updateSellerCardInformation(sellerCardInformation, cardId);
                            if (sellerInfoResponse.status === 200) {
                                setAddCardLoading(false);
                                setListings(sellerInfoResponse.data.listings);
                                addToast({
                                    severity: 'success',
                                    message: 'Listing Added',
                                });
                                dispatch({
                                    type: constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB,
                                    payload: {sellersLockerCardsActiveTab: 1},
                                });
                                accountApi.getProductConfig(cardId).then(productConfigResponse => {
                                    setNewSettings(productConfigResponse.data);
                                });
                                setTimeout(() => {
                                    history.goBack();
                                }, 1000);
                            }
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error updating Seller Card Information',
                                contextKey: 'Sell Your Card',
                            });
                            setAddCardLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    return (
        <>
            {
                <>
                    <BSCContainer className={pwaOS === null ? classes.root : classes.rootPWA} variant="page">
                        {!isMobile && !isBelowTablet && <BSCBack label="Back" />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <SellYourCardSidebar sellerResults={sellerResults} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {pwaOS === null && (
                                    <>
                                        <SellYourCardTabs listingsCount={totalAvailableQuantity} />
                                        <Switch>
                                            <Redirect from="/sellers/sell-your-card/" to={`/sellers/sell-your-card/add/${cardId}`} exact />
                                            <Route path={'/sellers/sell-your-card/add/:cardId'}>
                                                <SellYourCardAdd
                                                    updateProduct={handleAddCard}
                                                    sellerSettings={newSettings ?? sellerSettings?.data}
                                                    sellerCardData={sellerCard?.data}
                                                    loading={sellerSettingsLoading || addCardLoading}
                                                    isVintage={isVintage}
                                                />
                                            </Route>
                                            <Route exact path={'/sellers/sell-your-card/edit/:cardId'}>
                                                <SellYourCardUpdate
                                                    sellerCardInstance={sellerCardInstance}
                                                    cardListing={listings}
                                                    sellerSettings={sellerSettings?.data}
                                                    sellerCardData={sellerCard?.data}
                                                    loading={sellerCardLoading || sellerSettingsLoading}
                                                    isVintage={isVintage}
                                                />
                                            </Route>
                                        </Switch>
                                    </>
                                )}
                                {pwaOS !== null && pwaSellYourCardsActiveTab === 0 && (
                                    <>
                                        <SellYourCardTabs listingsCount={totalAvailableQuantity} />
                                        <Switch>
                                            <Redirect from="/sellers/sell-your-card/" to={`/sellers/sell-your-card/add/${cardId}`} exact />
                                            <Route path={'/sellers/sell-your-card/add/:cardId'}>
                                                <SellYourCardAdd
                                                    updateProduct={handleAddCard}
                                                    sellerSettings={newSettings ?? sellerSettings?.data}
                                                    sellerCardData={sellerCard?.data}
                                                    loading={sellerSettingsLoading || addCardLoading}
                                                    isVintage={isVintage}
                                                />
                                            </Route>
                                            <Route exact path={'/sellers/sell-your-card/edit/:cardId'}>
                                                <SellYourCardUpdate
                                                    sellerCardInstance={sellerCardInstance}
                                                    cardListing={listings}
                                                    sellerSettings={sellerSettings?.data}
                                                    sellerCardData={sellerCard?.data}
                                                    loading={sellerCardLoading || sellerSettingsLoading}
                                                    isVintage={isVintage}
                                                />
                                            </Route>
                                        </Switch>
                                    </>
                                )}
                                {(isMobile || isBelowTablet) && pwaSellYourCardsActiveTab === 1 && (
                                    <Grid item xs={12} container spacing={2} className={classes.detailsContainer}>
                                        <Grid item xs={4}>
                                            <BSCTypography className={classes.fontBlack} label="Set:" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <BSCTypography className={classes.fontBlack} label={sellerResults?.setName} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSCTypography className={classes.fontBlack} label="Variant:" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <BSCTypography
                                                className={classes.fontBlack}
                                                label={normalizer.getVariantAndVariantName(sellerResults)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSCTypography className={classes.fontBlack} label="Card #:" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <BSCTypography className={classes.fontBlack} label={sellerResults?.cardNo} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSCTypography className={classes.fontBlack} variant="subtitle1" label="Team:" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <BSCTypography
                                                className={classes.fontBlack}
                                                variant="subtitle1"
                                                label={sellerResults?.teamName}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSCTypography className={classes.fontBlack} variant="subtitle1" label="Attribute(s):" />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <BSCTypography
                                                variant="subtitle1"
                                                className={classes.fontBlack}
                                                //Old version.  playerAttributeDesc disappeared and only playerAttribute uis present so lets not break things
                                                //label={sellerResults?.playerAttributeDesc + '(' + sellerResults?.playerAttribute + ')'}
                                                label={sellerResults?.playerAttribute}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {(isMobile || isBelowTablet) && pwaSellYourCardsActiveTab === 2 && (
                                    <ProductListings
                                        selectedListing={selectedListing}
                                        onProductSelect={() => {}}
                                        onAddToCart={() => {}}
                                        onMakeAnOffer={() => {}}
                                        loading={sellerCardLoading}
                                        productListingsData={sellYourCardsListings}
                                        addToCartLoading={sellerCardLoading}
                                        cardYear={cardYear}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </BSCContainer>
                </>
            }
        </>
    );
}

export default SellYourCard;
