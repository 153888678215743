/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useCardNormalizer} from '../../client/common/card/cardNormalizer';
import Normalizer from '../../client/common/normalizer/normalizer';
import {SearchCard} from '../../client/openapitools/search';
import SearchShopResultsNormalizer from '../../client/search/shop/results/searchShopResultsNormalizer';
import useScreenSnap from '../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../redux/reducers';
import * as constants from '../../shared/constants';
import BSCImage from '../common/bscImage/bscImage';
import BSCTab from '../common/bscTab/bscTab';
import BSCTabs from '../common/bscTabs/bscTabs';
import BSCTypography from '../common/bscTypography/bscTypography';

const useStyles = makeStyles(theme => ({
    cardContainer: {
        background: theme.palette.common.white,
        padding: theme.spacing(1),
        borderRadius: 4,
        boxShadow: '0px 0px 4px #B8C2CC',
        display: 'flex',
        cursor: 'pointer',
        flex: '1',
        //Needs to be double the padding
        height: `calc(100% - ${theme.spacing(2)}px)`,
        ['&:link, &:visited']: {
            textDecoration: 'none !important',
            color: 'inherit !important',
        },
    },
    cardLeft: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '30%',
        },
    },
    cardRight: {flex: 1, justifyContent: 'space-between', display: 'flex', flexDirection: 'column', paddingLeft: theme.spacing(2)},
    chipContainer: {
        marginTop: 10,
        marginBottom: theme.spacing(1),
    },
    chip: {marginRight: 6},
    tabs: {
        boxShadow: 'inset 0px -4px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        border: '3px solid #B8C2CC',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        minHeight: 'auto',
    },
    tab: {
        flex: 1,
        background: '#B8C2CC',
        transition: '1s ease',
        minWidth: 'auto',
        padding: theme.spacing(0.2),
        minHeight: 28,
        maxWidth: 'none',
        fontStyle: 'italic',
        textTransform: 'uppercase',
    },
    tabSelected: {
        background: '#F5F8FA',
        boxShadow: 'inset 0px 4px 4px #FFFFFF',
        borderRadius: 4,
    },
    priceContainer: {
        boxShadow: 'inset 0px -4px 4px #B8C2CC',
        padding: theme.spacing(1, 2),
        textAlign: 'center',
        flex: 1,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    price: {
        color: theme.palette.primary.main,
    },
    cardName: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.25),
    },
    setName: {
        fontSize: 10,
        fontWeight: 600,
        lineHeight: 1,
        color: '#444',
    },
    variantName: {
        fontSize: 10,
        marginTop: theme.spacing(0.1),
        marginBottom: theme.spacing(1.5),
        lineHeight: 1,
        fontWeight: 600,
        color: '#888',
    },
    marginRight: {
        marginRight: theme.spacing(0.5),
    },
    baseChip: {
        textTransform: 'capitalize',
        background: '#DAE0E6',
        fontSize: theme.typography.subtitle2.fontSize,
        borderRadius: 6,
        height: 'auto',
        padding: theme.spacing(0.75),
        boxShadow: 'inset 0px 0px 4px #B8C2CC',

        '& span': {
            padding: 0,
        },
    },
    gold: {
        background: '#EECB1C',
        color: 'black',
    },
    red: {
        background: theme.palette.primary.main,
    },
    cardImg: {
        height: 'auto',
        maxHeight: 180,
        maxWidth: 130,
        //boxShadow: '0px 2.87657px 2.87657px rgba(0, 0, 0, 0.25)',
        objectFit: 'contain',
    },
    h1Zero: {
        display: 'none',
        width: 0,
        height: 0,
        margin: 0,
        padding: 0,
        color: 'transparent',
    },
}));

interface PlayerCardProps {
    data: SearchCard;
    loading?: boolean;
    sortOrder?: string;
    condition?: string;
}

const tabEnum = {
    RAW: 0,
    GRADED: 1,
};

function PlayerCard({data, loading = false, sortOrder = '', condition}: PlayerCardProps) {
    const [activeTab, setActiveTab] = useState<number>(0);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {isMobile} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const handleTabChange = useCallback((value: number, e: ChangeEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setActiveTab(value);
    }, []);
    const getAmount = price => {
        if (price >= 1000) {
            return '$' + Math.ceil(price).toLocaleString('en-Us');
        } else {
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        }
    };
    const isInStock = price => price > 0;
    const getPriceDisplay = price => {
        if (price === undefined || price === null) {
            return <Skeleton />;
        } else if (price <= 0) {
            return 'Out of Stock';
        } else {
            return `${getAmount(price)}`;
        }
    };

    const normalizer = useCardNormalizer();

    const [hasSellerPresent, setHasSellerPresent] = useState(false);
    const [totalRawCountForCard, setTotalRawCountForCard] = useState(0);
    const [totalGradedCountForCard, setTotalGradedCountForCard] = useState(0);
    const [sellerPriceForCard, setSellerPriceForCard] = useState(0);
    const [h1Data, setH1Data] = useState<string>('');
    const [productLink, setProductLink] = useState<string>('');

    const handlePlayerTap = () => {
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            let playerNameData = '';
            const playersArray = [...data.players];
            playersArray.forEach(element => {
                if (playerNameData.length === 0) {
                    playerNameData += element;
                } else {
                    playerNameData += ' , ' + element;
                }
            });
            dispatch({
                type: constants.PWA_PLAYER_NAME,
                payload: {pwaPlayerName: playerNameData},
            });
            dispatch({
                type: constants.PWA_CARD_NUMBER,
                payload: {pwaCardNumber: data.cardNo},
            });
            dispatch({
                type: constants.PWA_SET_NAME,
                payload: {pwaSetName: data.setName},
            });
            dispatch({
                type: constants.PWA_VARIANT_NAME,
                payload: {pwaVariantName: data.variantName},
            });
            dispatch({
                type: constants.PWA_VARIANT,
                payload: {pwaVariant: data.variant},
            });
        }
    };

    useEffect(() => {
        let totalRawCount = 0;
        let totalGradedCount = 0;
        let totalRawPrice = 0;
        let totalGradedPrice = 0;

        if (data?.players) {
            let h1Data = '';
            const playersArray = [...data.players];
            playersArray.forEach(element => {
                if (h1Data.length === 0) {
                    h1Data += element;
                } else {
                    h1Data += ' , ' + element;
                }
            });
            setH1Data(h1Data);
            const qLinkRaw =
                encodeURIComponent(data.players).replace(new RegExp('%20', 'g'), '-') +
                ' ' +
                data.cardNo +
                ' ' +
                data.setName +
                ' ' +
                data.variant +
                ' ' +
                data.variantName +
                ' ' +
                data.teamName +
                ' ' +
                data.playerAttribute +
                ' ' +
                data.playerAttributeDesc;
            setProductLink(encodeURIComponent(qLinkRaw).replace(new RegExp('%20', 'g'), '-'));
        }

        if (
            data &&
            marketplaceState &&
            marketplaceState.sellerStorefront !== {} &&
            marketplaceState.sellerStorefront['sellerId'] !== undefined
        ) {
            setHasSellerPresent(true);
            const sellerCardData = data?.products.filter(
                dataProduct => dataProduct.sellerId === marketplaceState.sellerStorefront['sellerId']
            );
            let sellerLowestData;
            if (sellerCardData) {
                if (sellerCardData.length > 0) {
                    sellerLowestData = sellerCardData.reduce((previousValue, currentValue, index, array) => {
                        return currentValue.price < previousValue.price ? currentValue : previousValue;
                    });
                }
            }
            if (sellerLowestData) {
                if (sellerLowestData?.productType === 'raw') {
                    setTotalRawCountForCard(sellerLowestData.quantity);
                }
                if (sellerLowestData?.productType === 'graded') {
                    setTotalGradedCountForCard(sellerLowestData.quantity);
                }
                setSellerPriceForCard(sellerLowestData.price);
                if (!sellerLowestData.productType || sellerLowestData.productType === 'all') {
                    if ((sellerLowestData.price <= sellerLowestData.price && sellerLowestData.price > 0) || sellerLowestData.price === 0) {
                        setActiveTab(0);
                    } else {
                        setActiveTab(1);
                    }
                } else if (sellerLowestData.productType === 'raw') {
                    setActiveTab(0);
                } else {
                    setActiveTab(1);
                }
            }
        } else if (data && (marketplaceState.sellerStorefront === {} || marketplaceState.sellerStorefront['sellerId'] === undefined)) {
            if (data && data.products !== undefined && data.products !== null) {
                data.products.forEach(product => {
                    if (product.productType === 'raw') {
                        totalRawCount += product.quantity;
                    }
                    if (product.productType === 'graded') {
                        totalGradedCount += product.quantity;
                    }
                    if (product.productType === 'raw' && totalRawPrice === 0) {
                        totalRawPrice = product.price;
                    } else if (product.productType === 'raw' && product.price < totalRawPrice) {
                        totalRawPrice = product.price;
                    }
                    if (product.productType === 'graded' && totalGradedPrice === 0) {
                        totalGradedPrice = product.price;
                    } else if (product.productType === 'graded' && product.price < totalGradedPrice) {
                        totalGradedPrice = product.price;
                    }
                });
                setTotalRawCountForCard(totalRawCount);
                setTotalGradedCountForCard(totalGradedCount);
                if (condition === 'raw') {
                    setActiveTab(0);
                } else if (condition === 'graded') {
                    setActiveTab(1);
                } else {
                    if (
                        (condition === 'all' && totalRawPrice < totalGradedPrice && totalRawPrice > 0) ||
                        totalRawCount > totalGradedCount
                    ) {
                        setActiveTab(0);
                    } else {
                        setActiveTab(1);
                    }
                    if (
                        (condition === 'all' && totalGradedPrice < totalRawPrice && totalGradedPrice > 0) ||
                        totalGradedCount > totalRawCount
                    ) {
                        setActiveTab(1);
                    } else {
                        setActiveTab(0);
                    }
                }
            }
        }
    }, [marketplaceState, data]);

    useEffect(() => {
        if (!marketplaceState && !marketplaceState?.sellerStorefront) {
            if (!condition || condition === 'all') {
                if ((data?.rawLeastPrice <= data?.gradedLeastPrice && data?.rawLeastPrice > 0) || data?.gradedLeastPrice === 0) {
                    setActiveTab(0);
                } else {
                    setActiveTab(1);
                }
            } else if (condition === 'raw') {
                setActiveTab(0);
            } else {
                setActiveTab(1);
            }
        }
    }, []);

    return (
        <div onClick={handlePlayerTap}>
            {data && (
                <Link
                    className={classes.cardContainer}
                    to={
                        hasSellerPresent
                            ? `/product/${data?.id}/seller/${marketplaceState.sellerStorefront['sellerId']}?search=${productLink}`
                            : `/product/${data?.id}?search=${productLink}}`
                    }
                >
                    <h1 className={classes.h1Zero}>{h1Data}</h1>
                    <div className={classes.cardLeft}>
                        {loading ? (
                            <Skeleton variant="rect" width={130} height={180} />
                        ) : (
                            // <Link
                            //     to={
                            //         hasSellerPresent
                            //             ? `/product/${data?.id}/seller/${marketplaceState.sellerStorefront['sellerId']}?search=${productLink}`
                            //             : `/product/${data?.id}?search=${productLink}}`
                            //     }
                            // >
                            <BSCImage className={classes.cardImg} src={Normalizer.getS3ImagePath(data?.stockImgFront)} />
                            // </Link>
                        )}
                    </div>
                    <div className={classes.cardRight}>
                        <div>
                            <BSCTypography
                                className={classes.setName}
                                variant="subtitle2"
                                label={data?.setName}
                                loading={loading}
                                style={
                                    isMobile
                                        ? {
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              width: 208,
                                              lineHeight: 'inherit',
                                          }
                                        : {
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              width: 168,
                                              lineHeight: 'inherit',
                                          }
                                }
                            />
                            <BSCTypography
                                className={classes.variantName}
                                variant="subtitle2"
                                label={data?.variantName === '' ? '\u00a0' : data?.variantName}
                                loading={loading}
                                style={
                                    isMobile
                                        ? {
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              width: 208,
                                              lineHeight: 'inherit',
                                          }
                                        : {
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              width: 168,
                                              lineHeight: 'inherit',
                                          }
                                }
                            />
                            <BSCTypography
                                className={classes.cardName}
                                capitalize
                                semibold
                                label={normalizer.getPlayerName(data)}
                                loading={loading}
                                maxCharacters={28}
                                showValueAfter={'#'}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className={classes.chipContainer}>
                                {data?.variant && (
                                    <Chip className={classNames(classes.marginRight, classes.baseChip)} label={data.variant} />
                                )}
                                {SearchShopResultsNormalizer.getPlayerAttributes(data?.playerAttribute)?.map(labelObject => (
                                    <Chip
                                        key={labelObject.label}
                                        className={classNames(
                                            {[classes.gold]: labelObject.color === 'gold', [classes.red]: labelObject.color === 'red'},
                                            classes.baseChip,
                                            classes.marginRight
                                        )}
                                        label={labelObject.stripedLabel}
                                        color="primary"
                                    />
                                ))}
                            </div>
                            <BSCTabs radius="4px 4px 0px 0px" activeTab={activeTab} onTabChange={handleTabChange}>
                                <BSCTab skinny label={`Raw (${totalRawCountForCard})`} />
                                <BSCTab skinny label={`Graded (${totalGradedCountForCard})`} />
                            </BSCTabs>
                            <div className={classes.priceContainer}>
                                {isInStock(tabEnum.RAW === activeTab ? data?.rawLeastPrice : data?.gradedLeastPrice) && (
                                    <BSCTypography semibold variant="subtitle2" display="inline" label="Low Price: " />
                                )}
                                <BSCTypography
                                    bold
                                    variant="subtitle2"
                                    semibold
                                    className={classes.price}
                                    display="inline"
                                    label={
                                        hasSellerPresent
                                            ? getPriceDisplay(sellerPriceForCard)
                                            : getPriceDisplay(tabEnum.RAW === activeTab ? data?.rawLeastPrice : data?.gradedLeastPrice)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
}

export default PlayerCard;
