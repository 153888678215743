/* eslint-disable @typescript-eslint/no-unused-vars */
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {AUTH_REQUESTS} from '../../../../authConfig';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import useDateNormalizer from '../../../../client/common/normalizer/dateTimeNormalizer';
import {Configuration} from '../../../../client/openapitools/seller';
import {OrderApi} from '../../../../client/openapitools/seller/api';
import SellersFormValidationsClient from '../../../../client/s3/sellersFormValidations/sellersFormValidationsClient';
import SellersFormValidationsNormalizer from '../../../../client/s3/sellersFormValidations/sellersFormValidationsNormalizer';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCHeaderBlock from '../../../../features/common/bscHeaderBlock/bscHeaderBlock';
import BSCPagination from '../../../../features/common/bscPagination/bscPagination';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import useForm from '../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import OrderListFilter from './components/orderListFilter';
import OrdersTable from './components/ordersTable';
import SellerOrderResultsHeader from './components/sellerOrderResultsHeader';
import './orders.scss';

const useStyles = makeStyles((theme: Theme) => ({
    topPadding: {
        marginTop: theme.spacing(2),
    },
    pwaWrapper: {
        height: 'calc(100vh - 220px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
}));

const STATUS = [
    /*UNSHIPPED:*/ ['READY_TO_SHIP', 'PARTIALLY_REFUNDED_READY_TO_SHIP'],
    /*CANCELLED:*/ ['CANCELLED'],
    /*SHIPPED:*/ ['SHIPPED', 'PARTIALLY_REFUNDED_SHIPPED'],
];

const defaultOrderSearch = {
    name: '',
    orderNo: '',
    fromDate: null,
    toDate: null,
    page: 0,
    size: 100,
    status: '-1',
};

function SellersOrders() {
    const {instance} = useMsal();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {apiAuthConfig} = useAuthState();
    let orderApi = new OrderApi(apiAuthConfig);
    const {isDesktop, isMobile} = useScreenSnap();
    const history = useHistory();
    const location = useLocation();
    let orderNo = location?.state?.orderNumber;
    const [perPage, setPerPage] = useState<number>(defaultOrderSearch.size);
    const [page, setPage] = useState(defaultOrderSearch.page);
    const classes = useStyles();
    const dateNormalizer = useDateNormalizer();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const [orderList, setOrderList] = useState(null);
    const [orderListResultsLoading, setOrderListResultsLoading] = useState(true);

    const resultCount = orderList?.data?.totalElements ?? 0;
    const [search, setSearch] = useState(defaultOrderSearch);

    const pageHandler = useCallback(
        (event, page) => {
            let tmpPage = 0;
            if (page - 1 < 0) {
                tmpPage = 0;
            } else {
                tmpPage = page;
            }

            const pageCount = Math.floor(resultCount / perPage) + (resultCount % perPage > 0 ? 1 : 0);

            if (page + 1 > pageCount) {
                tmpPage = pageCount;
            }

            setPage(tmpPage);
            performSearch({
                ...search,
                page: tmpPage - 1,
            });
        },
        [page, perPage, search, orderList?.data]
    );

    const [hasClearedSearch, setHasClearedSearch] = useState(false);
    const clear = () => {
        setHasClearedSearch(true);
        const tmpSearch = {
            ...defaultOrderSearch,
            //page: page,
            size: perPage,
        };
        tmpSearch.orderNo = '';

        history.push('/sellers/orders');
        // setSearch(tmpSearch);
        resetValues();
        performSearch(tmpSearch);
    };

    useEffect(() => {
        setPage(defaultOrderSearch.page);
        if (!location?.state?.orderNumber) {
            performSearch({
                ...search,
                size: perPage,
                page: 0,
            });
        }
    }, [perPage]);

    const {data: sellersFormValidations, loading: sellersFormValidationsLoading} = useQuery(
        () => SellersFormValidationsClient.getSellersFormValidations(),
        []
    );

    const {
        formState,
        validatedSchema: sellersFormStateValidations,
        registerFormChange,
        formComplete,
        setDefaultValues,
        resetValues,
    } = useForm({
        validationSchema: SellersFormValidationsNormalizer.getOrders(sellersFormValidations),
    });

    //lets call the API here
    useEffect(() => {
        const tmpSearch = {
            ...search,
            orderNo: orderNo ? orderNo : location?.state?.orderNumber,
        };
        performSearch(tmpSearch);

        if (orderNo) orderNo = '';
    }, []);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        setDefaultValues({
            customerIdx: defaultOrderSearch?.name,
            orderId: defaultOrderSearch?.orderNo ?? location?.state?.orderNumber,
            toDate: defaultOrderSearch?.toDate,
            fromDate: defaultOrderSearch?.fromDate,
            status: defaultOrderSearch?.status,
            page: defaultOrderSearch?.page,
            size: defaultOrderSearch?.size,
        });
    }, [defaultOrderSearch]);

    useEffect(() => {
        setSearch({
            ...search,
            name: formState.customerIdx,
            orderNo: formState.orderId ?? location?.state?.orderNumber,
            fromDate: formState.fromDate ? dateNormalizer.getApiDateTime(formState.fromDate) : '',
            toDate: formState.toDate ? dateNormalizer.getApiDateTime(formState.toDate) : '',
            status: formState?.status,
        });
    }, [formState]);

    const handleRadioSearch = e => {
        const tmpSearch = {
            ...search,
            status: e.target.value,
        };
        performSearch(tmpSearch);
    };

    const searchOrders = async searchObj => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            orderApi = new OrderApi(apiConfig);
                            const response = await orderApi.getOrderHistory(searchObj);
                            setOrderList(response);
                            setOrderListResultsLoading(false);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error fetching seller orders.',
                                contextKey: 'Orders',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const performSearch = searchObj => {
        setSearch(searchObj);

        const tmpSearch = {
            ...searchObj,
            status: searchObj.status === '-1' ? [] : STATUS[searchObj.status],
        };

        window.scrollTo(0, 0);
        searchOrders(tmpSearch);
    };

    const handleOnSearch = () => {
        performSearch(search);
    };

    return (
        <BSCContainer variant="page" className={pwaOS !== null ? classes.pwaWrapper : null}>
            {isDesktop && (
                <>
                    <BSCHeaderBlock align="center" label="Search Orders" size18 />
                    <OrderListFilter
                        onSearch={handleOnSearch}
                        onClear={clear}
                        loading={orderListResultsLoading}
                        formState={formState}
                        sellersFormStateValidations={sellersFormStateValidations}
                        registerFormChange={registerFormChange}
                        formComplete={formComplete}
                        sellersFormValidationsLoading={sellersFormValidationsLoading}
                    />
                </>
            )}
            {!isDesktop && (
                <Grid container style={{padding: '8px 0'}}>
                    <Grid item xs={12}>
                        <Accordion style={{borderRadius: 0}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <BSCTypography size16>Search Orders</BSCTypography>
                            </AccordionSummary>
                            <AccordionDetails style={{backgroundColor: '#DAE0E6'}}>
                                <OrderListFilter
                                    onSearch={handleOnSearch}
                                    onClear={clear}
                                    loading={orderListResultsLoading}
                                    formState={formState}
                                    sellersFormStateValidations={sellersFormStateValidations}
                                    registerFormChange={registerFormChange}
                                    formComplete={formComplete}
                                    sellersFormValidationsLoading={sellersFormValidationsLoading}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            )}

            <div className={classes.topPadding}></div>
            <BSCHeaderBlock align="center" label="Order List" size18 />
            <SellerOrderResultsHeader
                perPage={perPage}
                onPageSizeChange={setPerPage}
                unshippedCount={orderList?.data?.totalUnshipped}
                formState={formState}
                handleRadioSearch={handleRadioSearch}
                sellersFormStateValidations={sellersFormStateValidations}
                registerFormChange={registerFormChange}
            />
            <OrdersTable sizePerPage={perPage} page={page} ordersHistory={orderList?.data?.results} loading={orderListResultsLoading} />
            {!isMobile && !location?.state?.orderNumber && (
                <BSCPagination
                    total={orderList?.data?.totalElements ?? 0}
                    sizePerPage={perPage}
                    defaultPage={page}
                    onChange={pageHandler}
                />
            )}
        </BSCContainer>
    );
}

export default SellersOrders;
