/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import classNames from 'classnames';
import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import ProductReferencesNormalizer from '../../../../client/common/card/product/productReferencesNormalizer';
import CardListingNormalizer from '../../../../client/marketplace/card/{cardId}/card-listing/cardListingNormalizer';
import {CartItem, Product} from '../../../../client/openapitools/marketplace';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCSelect from '../../../../features/common/bscSelect/bscSelect';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {useProductReferenceContext} from '../../../../ProductReferenceContext';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import ProductListingTable from './productListingTable';
// import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) => ({
    productListingBox: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    buttonContainer: {
        padding: theme.spacing(2),
    },
    buttonContainerMobile: {
        padding: '12px 8px',
    },
    dropdown: {
        // marginBottom: theme.spacing(2),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropdownMobile: {
        marginBottom: '10px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    productListingsWrap: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        ['& > :first-child']: {
            flex: 1,
        },
    },
    buttonWrapSelectedListing: {
        display: 'flex',
        '& > :first-child': {
            marginRight: theme.spacing(2),
        },
    },
    buttonWrapper: {
        paddingTop: theme.spacing(1.5),
    },
    noItalic: {
        '& *': {
            fontStyle: 'normal',
            right: '0px',
            '& div': {
                fontSize: '18px',
                overflow: 'hidden',
            },
        },
        fontSize: '14px',
        width: '-webkit-fill-available',
        margin: '0 8px',
    },
    noItalicMobile: {
        '& *': {
            fontStyle: 'normal',
            right: '0px',
        },
        fontSize: '14px',
        width: 'calc(50vw - 16px)',
    },
    addToCartMobile: {
        '& *': {
            fontStyle: 'normal',
            right: '0px',
        },
        fontSize: '14px',
        width: '-webkit-fill-available',
    },
    tabMobile: {
        minHeight: '32px',
    },
    menuSelectionMobile: {
        fontSize: '14px',
    },
    menuDesktop: {
        fontSize: '18px',
    },
}));

interface ProductListingsProps {
    productListingsData: Product[];
    loading: boolean;
    addToCartLoading: boolean;
    onAddToCart: () => void;
    onMakeAnOffer: () => void;
    onProductSelect: (selectedProduct) => void;
    selectedListing: CartItem;
    actionsVisible?: boolean;
    cardYear: string;
}

const listingTypes = {
    GRADED: 'graded',
    RAW: 'raw',
};
function ProductListings({
    productListingsData,
    loading,
    addToCartLoading,
    onAddToCart,
    onMakeAnOffer,
    onProductSelect,
    selectedListing,
    cardYear,
    actionsVisible = true,
}: ProductListingsProps) {
    const classes = useStyles('key');
    const dispatch = useDispatch();
    const isSellers = useRouteMatch('/sellers');
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {isBelowTablet, isMobile} = useScreenSnap();
    const [selectedCondition, setSelectedCondition] = useState('');
    const [selectedConditionType, setSelectedConditionType] = useState('');
    const {productReferences: productReferences} = useProductReferenceContext();
    const refConditions = ProductReferencesNormalizer.getCondition(productReferences);
    const refVintageConditions = ProductReferencesNormalizer.getConditionVintage(productReferences);
    const refCompany = ProductReferencesNormalizer.getCompany(productReferences);
    const refGrades = ProductReferencesNormalizer.getGrades(productReferences);
    const selectedListingOffer = CardListingNormalizer.getSelectedListingOffer(selectedListing);
    const loadedConditions = productListingsData ? [...new Set(productListingsData?.map(item => item.condition))] : refConditions;

    const loadedGradeCos = productListingsData ? [...new Set(productListingsData?.map(item => item.gradingCompany))] : refCompany;

    const [filteredProductListings, setFilteredProductListings] = useState(null);

    const [isSelectTypeOpen, setIsSelectTypeOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isSelectConditionOpen, setIsSelectConditionOpen] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSelection = (selection: string) => {
        dispatch({
            type: constants.SELLER_RESET,
        });
    };

    const [previousSelectedCondition, setPreviousSelectedCondition] = useState('');
    const [hasRawSelected, setHasRawSelected] = useState(false);
    const [hasGradedSelected, setHasGradedSelected] = useState(false);
    const handleSelectedConditionType = (selectionType: string) => {
        setSelectedConditionType(selectionType);
        if (selectionType === 'raw') {
            const filteredCardListing = productListingsData.filter(card => {
                return card.productType === listingTypes.RAW;
            });
            setFilteredProductListings(filteredCardListing);
            setHasRawSelected(true);
            setHasGradedSelected(false);
            setSelectedCondition('');
            return;
        }
        if (selectionType === 'graded') {
            const filteredCardListing = productListingsData.filter(card => {
                return card.productType === listingTypes.GRADED;
            });
            setFilteredProductListings(filteredCardListing);
            setHasRawSelected(false);
            setHasGradedSelected(true);
            setSelectedCondition('');
            return;
        }
        if (selectionType === '') {
            setHasRawSelected(false);
            setHasGradedSelected(false);
            setSelectedCondition('');
            setFilteredProductListings(productListingsData);
            return;
        }
    };

    const handleSelectedCondition = (selection: string) => {
        setSelectedCondition(selection);
        if (selection === '') {
            setPreviousSelectedCondition(selection);
            if (hasRawSelected) {
                const filteredCardListing = productListingsData.filter(card => {
                    return card.productType === listingTypes.RAW;
                });
                setFilteredProductListings(filteredCardListing);
                return;
            }
            if (hasGradedSelected) {
                const filteredCardListing = productListingsData.filter(card => {
                    return card.productType === listingTypes.GRADED;
                });
                setFilteredProductListings(filteredCardListing);
                return;
            }
        }
        if (selection === '_raw') {
            setSelectedCondition(previousSelectedCondition);
            return false;
        }
        if (selection === '_graded') {
            setSelectedCondition(previousSelectedCondition);
            return false;
        }
        setPreviousSelectedCondition(selection);
        if (selection.includes('|')) {
            setHasRawSelected(false);
            setHasGradedSelected(true);
            setSelectedConditionType('graded');
            const gradeSplit = selection.split('|');
            const filteredCardListing = productListingsData.filter(card => {
                return card.productType === listingTypes.GRADED && card.gradingCompany === gradeSplit[0] && card.grade === gradeSplit[1];
            });
            setFilteredProductListings(filteredCardListing);
            return;
        } else {
            setHasRawSelected(true);
            setHasGradedSelected(false);
            setSelectedConditionType('raw');
            const filteredCardListing = productListingsData.filter(card => {
                return card.productType === listingTypes.RAW && card.condition === selection;
            });
            setFilteredProductListings(filteredCardListing);
        }
    };

    useEffect(() => {
        onProductSelect(null);
    }, [onProductSelect, selectedCondition]);

    const [totalRawCountForCard, setTotalRawCountForCard] = useState(0);
    const [totalGradedCountForCard, setTotalGradedCountForCard] = useState(0);
    const [grandTotalCountForCard, setGrandTotalCountForCard] = useState(0);
    useEffect(() => {
        if (productListingsData) {
            let totalRawCount = 0;
            let totalGradedCount = 0;
            productListingsData.forEach(product => {
                if (product.productType === 'raw') {
                    totalRawCount += product.availableQuantity;
                }
                if (product.productType === 'graded') {
                    totalGradedCount += product.availableQuantity;
                }
            });
            setTotalRawCountForCard(totalRawCount);
            setTotalGradedCountForCard(totalGradedCount);
            setGrandTotalCountForCard(totalRawCount + totalGradedCount);

            setFilteredProductListings(productListingsData);
        }
    }, [productListingsData]);

    const [filteredConditionsList, setFilteredConditionsList] = useState(null);
    const [filteredGradesList, setFilteredGradesList] = useState(null);
    useEffect(() => {
        if (refConditions && refVintageConditions && refGrades && refCompany && productListingsData && cardYear) {
            const filteredConditions = refConditions.filter(item => {
                return productListingsData.find(listing => listing.condition === item.key);
            });

            const filteredVintageConditions = refVintageConditions.filter(item => {
                return productListingsData.find(listing => listing.condition === item.key);
            });

            const tempFilteredConditionsList = [];
            productListingsData.forEach(listing => {
                if (listing.productType === 'raw') {
                    tempFilteredConditionsList.push({key: listing.condition, quantity: listing.availableQuantity});
                }
            });

            const conditionsResult = [];
            if (cardYear <= 1980) {
                refVintageConditions.forEach(refCondition => {
                    if (refCondition) {
                        const matchingConditions = tempFilteredConditionsList.filter(condition => condition.key === refCondition.key);
                        const totalCondition = matchingConditions.reduce((acc, obj) => acc + obj.quantity, 0);
                        if (totalCondition > 0) {
                            conditionsResult.push({key: refCondition.key, quantity: totalCondition, name: refCondition.name});
                        }
                    }
                });
            } else {
                loadedConditions.forEach(loadedCondition => {
                    if (loadedCondition) {
                        const matchingConditions = tempFilteredConditionsList.filter(condition => condition.key === loadedCondition);
                        const totalCondition = matchingConditions.reduce((acc, obj) => acc + obj.quantity, 0);
                        if (totalCondition > 0) {
                            conditionsResult.push({key: loadedCondition, quantity: totalCondition, name: loadedCondition.name});
                        }
                    }
                });
            }

            if (cardYear <= 1980) {
                conditionsResult.forEach(uniqueCondition => {
                    const tempConditionTarget = filteredVintageConditions.find(condition => condition.key === uniqueCondition.key);
                    if (tempConditionTarget) {
                        uniqueCondition.option = tempConditionTarget.name;
                    }
                });
            } else {
                conditionsResult.forEach(uniqueCondition => {
                    const tempConditionTarget = filteredConditions.find(condition => condition.key === uniqueCondition.key);
                    uniqueCondition.option = tempConditionTarget.name;
                });
            }

            const orderedConditions = [];
            if (cardYear <= 1980) {
                refVintageConditions.forEach(refCondition => {
                    const matchingCondition = conditionsResult.find(condition => condition.key === refCondition.key);
                    if (matchingCondition) {
                        orderedConditions.push({
                            key: matchingCondition.key,
                            option: matchingCondition.option,
                            quantity: matchingCondition.quantity,
                        });
                    }
                });
            } else {
                refConditions.forEach(refCondition => {
                    const matchingCondition = conditionsResult.find(condition => condition.key === refCondition.key);
                    if (matchingCondition) {
                        orderedConditions.push({
                            key: matchingCondition.key,
                            option: matchingCondition.option,
                            quantity: matchingCondition.quantity,
                        });
                    }
                });
            }

            setFilteredConditionsList(orderedConditions);

            const filteredGraders = refCompany.filter(item => {
                return productListingsData.find(listing => listing.gradingCompany === item.key);
            });

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const filteredGrades = refGrades.filter(item => {
                return productListingsData.find(listing => listing.grade === item.key);
            });

            const tempFilteredGradesList = [];
            productListingsData.forEach(listing => {
                if (listing.productType === 'graded') {
                    tempFilteredGradesList.push({key: listing.gradingCompany, grade: listing.grade, quantity: listing.availableQuantity});
                }
            });

            const gradersResult = [];
            loadedGradeCos.forEach(loadedGrade => {
                if (loadedGrade) {
                    const matchingGrades = tempFilteredGradesList.filter(grade => grade.key === loadedGrade);
                    refCompany.forEach(refCompany => {
                        const matchingGrade = matchingGrades.filter(grade => grade.key === refCompany.key);
                        if (matchingGrade) {
                            matchingGrade.forEach(grade => {
                                gradersResult.push({key: grade.key, grade: grade.grade, quantity: grade.quantity});
                            });
                        }
                    });
                }
            });

            gradersResult.forEach(uniqueGrader => {
                const tempGraderTarget = filteredGraders.find(grader => grader.key === uniqueGrader.key);
                uniqueGrader.key = tempGraderTarget.key;
                uniqueGrader.option = tempGraderTarget.name;
            });

            const orderedGradingCompanies = [];
            refCompany.forEach(refCo => {
                const matchingGrader = gradersResult.filter(grader => grader.key === refCo.key);
                if (matchingGrader) {
                    matchingGrader.forEach(grader => {
                        orderedGradingCompanies.push({
                            key: grader.key,
                            option: grader.option,
                            grade: grader.grade,
                            quantity: grader.quantity,
                        });
                    });
                }
            });

            const orderedGrades = [];
            refGrades.forEach(refGrade => {
                const matchingGrader = orderedGradingCompanies.filter(grader => grader.grade === refGrade.key);
                if (matchingGrader) {
                    matchingGrader.forEach(grader => {
                        orderedGrades.push({
                            key: grader.key,
                            option: grader.option,
                            grade: grader.grade,
                            quantity: grader.quantity,
                        });
                    });
                }
            });

            setFilteredGradesList(orderedGrades);
        }
    }, [refConditions, refVintageConditions, refGrades, refCompany, productListingsData, cardYear]);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            setPwaOS(marketplaceState.pwaSettings.pwaOS);
        }
    }, [marketplaceState]);

    return (
        <BSCCardBox className={classes.productListingBox}>
            <div className={isBelowTablet || isMobile ? classes.buttonContainerMobile : classes.buttonContainer}>
                <Grid container style={{textAlign: 'center'}}>
                    <Grid item md={6} style={{margin: 'auto'}}>
                        <BSCSelect
                            className={isBelowTablet || isMobile ? classes.noItalicMobile : classes.noItalic}
                            style={{width: '-moz-available'}}
                            selectedSortValue={selectedConditionType}
                            onOpenChange={() => setIsSelectTypeOpen(true)}
                            onSortChange={e => {
                                handleSelectedConditionType(e), handleSelection(e);
                            }}
                            onCloseChange={() => setIsSelectTypeOpen(false)}
                        >
                            <MenuItem className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop} value="">
                                All Cards ({grandTotalCountForCard})
                            </MenuItem>
                            {hasRawSelected && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value="raw"
                                >
                                    Raw {isSelectTypeOpen && `(${totalRawCountForCard})`}
                                </MenuItem>
                            )}
                            {!hasRawSelected && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value="raw"
                                >
                                    Raw ({totalRawCountForCard})
                                </MenuItem>
                            )}
                            {hasGradedSelected && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value="graded"
                                >
                                    Graded {isSelectTypeOpen && `(${totalGradedCountForCard})`}
                                </MenuItem>
                            )}
                            {!hasGradedSelected && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value="graded"
                                >
                                    Graded ({totalGradedCountForCard})
                                </MenuItem>
                            )}
                        </BSCSelect>
                    </Grid>
                    <Grid item md={6} style={{margin: 'auto'}}>
                        <BSCSelect
                            className={isBelowTablet || isMobile ? classes.noItalicMobile : classes.noItalic}
                            style={{width: '-moz-available'}}
                            selectedSortValue={selectedCondition}
                            onOpenChange={() => setIsSelectConditionOpen(true)}
                            onSortChange={e => {
                                handleSelectedCondition(e), handleSelection(e);
                            }}
                        >
                            {selectedConditionType === '' && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value=""
                                >
                                    Condition
                                </MenuItem>
                            )}
                            {selectedConditionType !== '' && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value=""
                                >
                                    All Conditions {hasRawSelected && `(${totalRawCountForCard})`}
                                    {hasGradedSelected && `(${totalGradedCountForCard})`}
                                </MenuItem>
                            )}
                            {totalRawCountForCard > 0 && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value="_raw"
                                >
                                    Raw:
                                </MenuItem>
                            )}
                            {filteredConditionsList &&
                                filteredConditionsList.map(condition => (
                                    <MenuItem
                                        className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                        key={condition.key}
                                        value={condition.key}
                                        style={{paddingLeft: 40}}
                                    >
                                        {/* <Checkbox color="primary" /> */}
                                        {condition.option}&nbsp;({condition.quantity})
                                    </MenuItem>
                                ))}
                            {totalRawCountForCard > 0 && totalGradedCountForCard > 0 && <BSCDivider />}
                            {totalGradedCountForCard > 0 && (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    value="_graded"
                                >
                                    Graded:
                                </MenuItem>
                            )}
                            {filteredGradesList?.map(grade => (
                                <MenuItem
                                    className={isBelowTablet || isMobile ? classes.menuSelectionMobile : classes.menuDesktop}
                                    key={grade.key}
                                    value={grade.key + '|' + grade.grade}
                                    style={{paddingLeft: 40}}
                                >
                                    {/* <Checkbox color="primary" /> */}
                                    {grade.option}&nbsp;{grade.grade}&nbsp;({grade.quantity})
                                </MenuItem>
                            ))}
                        </BSCSelect>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.productListingsWrap}>
                <ProductListingTable
                    loading={loading}
                    selectedListing={selectedListing}
                    listings={filteredProductListings}
                    onProductSelect={onProductSelect}
                    cardYear={cardYear}
                />
                {!isSellers && <BSCDivider noMargin />}
                {pwaOS === null && (
                    <div
                        className={classNames(classes.buttonContainer, classes.buttonWrapper, {
                            [classes.buttonWrapSelectedListing]: selectedListingOffer,
                        })}
                    >
                        {selectedListingOffer && (
                            <BSCButton
                                className={isBelowTablet || isMobile ? classes.addToCartMobile : classes.noItalic}
                                style={{width: '-moz-available'}}
                                fullWidth
                                loading={loading}
                                disabled={!productListingsData || !selectedListing || addToCartLoading || !actionsVisible}
                                onClick={onMakeAnOffer}
                                gutterBottom={false}
                                color="secondary"
                            >
                                Make an Offer
                            </BSCButton>
                        )}
                        {!isSellers && (
                            <BSCButton
                                className={isBelowTablet || isMobile ? classes.addToCartMobile : classes.noItalic}
                                style={{width: '-moz-available'}}
                                fullWidth
                                loading={loading || addToCartLoading}
                                disabled={!productListingsData || !selectedListing || addToCartLoading || !actionsVisible}
                                onClick={onAddToCart}
                                gutterBottom={false}
                                color="primary"
                            >
                                {!productListingsData || !selectedListing || addToCartLoading || !actionsVisible
                                    ? 'Select Listing Above'
                                    : 'Add To Cart'}
                            </BSCButton>
                        )}
                    </div>
                )}
            </div>

            {pwaOS !== null && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        height: 90,
                        width: '100%',
                        background: '#fff',
                        borderTop: '2px solid #d5d5d5',
                    }}
                >
                    <Grid container>
                        {selectedListingOffer && (
                            <Grid item xs={6}>
                                <BSCButton
                                    className={classes.addToCartMobile}
                                    style={{width: '-moz-available', margin: 16}}
                                    fullWidth
                                    loading={loading}
                                    disabled={!productListingsData || !selectedListing || addToCartLoading || !actionsVisible}
                                    onClick={onMakeAnOffer}
                                    gutterBottom={false}
                                    color="secondary"
                                >
                                    Make an Offer
                                </BSCButton>
                            </Grid>
                        )}
                        {!isSellers && (
                            <Grid item xs={!productListingsData || !selectedListing || addToCartLoading || !actionsVisible ? 12 : 6}>
                                <BSCButton
                                    className={classes.addToCartMobile}
                                    style={{width: '-moz-available', margin: 16}}
                                    fullWidth
                                    loading={loading || addToCartLoading}
                                    disabled={!productListingsData || !selectedListing || addToCartLoading || !actionsVisible}
                                    onClick={onAddToCart}
                                    gutterBottom={false}
                                    color="primary"
                                >
                                    {!productListingsData || !selectedListing || addToCartLoading || !actionsVisible
                                        ? 'Select Listing Above'
                                        : 'Add To Cart'}
                                </BSCButton>
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}
        </BSCCardBox>
    );
}

export default memo(ProductListings);
