import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {Tooltip} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useAuthFunctions, useAuthState} from '../../../../../AuthContext';
import {useProductReferenceContext} from '../../../../../ProductReferenceContext';
import {AUTH_REQUESTS} from '../../../../../authConfig';
import useDateTimeNormalizer from '../../../../../client/common/normalizer/dateTimeNormalizer';
import Normalizer from '../../../../../client/common/normalizer/normalizer';
import {Configuration, ProductApi} from '../../../../../client/openapitools/common';
import {Card, Product, ProductConfig} from '../../../../../client/openapitools/seller';
import SellersFormValidationsClient from '../../../../../client/s3/sellersFormValidations/sellersFormValidationsClient';
import SellersFormValidationsNormalizer from '../../../../../client/s3/sellersFormValidations/sellersFormValidationsNormalizer';
import BSCButton from '../../../../../features/common/bscButton/bscButton';
import BSCCardBox from '../../../../../features/common/bscCardBox/bscCardBox';
import BSCDivider from '../../../../../features/common/bscDivider/bscDivider';
import BscToggledEditButton from '../../../../../features/common/bscEditIcon/bscEditIcon';
import BSCLoading from '../../../../../features/common/bscLoading/bscLoading';
import BSCSelect from '../../../../../features/common/bscSelect/bscSelect';
import BSCTextField from '../../../../../features/common/bscTextField/bscTextField';
import BSCTypography from '../../../../../features/common/bscTypography/bscTypography';
import useReduxToast from '../../../../../features/hooks/redux/toast/useReduxToast';
import useForm from '../../../../../packages/core/src/hooks/useForm';
import {useQuery} from '../../../../../packages/core/src/hooks/useQuery';
import useScreenSnap from '../../../../../packages/core/src/hooks/useScreenSnap';
import {currencyFormatter, isTruthy} from '../../../../../packages/core/src/util/util';
import {RootState} from '../../../../../redux/reducers';
import * as constants from '../../../../../shared/constants';
import CardConditions from './CardConditions';
import DeleteConfirmationModal from './deleteConfirmationModal';
import MobileImageRequirementsModal from './mobileImageRequirementsModal';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: '#fff',
        marginBottom: 0,
        paddingBottom: 0,
        fontFamily: 'Poppins',
        minHeight: 420,
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    imgGroup: {
        // margin: theme.spacing(1, 0),
        width: '100%',
    },
    image: {
        height: 240,
        margin: 'auto',
    },
    imageHint: {
        fontSize: 10,
    },
    imageBackgroundContainer: {
        position: 'relative',
        height: 218,
        width: '100%',
        filter: 'drop-shadow(3px 3px 3px rgba(0, 0, 0, 0))',
    },
    deleteButtonWrapper: {
        zIndex: 100,
        position: 'absolute',
        height: 24,
        width: 24,
        top: -10,
        right: -10,
        background: '#dcdcdc',
        borderRadius: '50%',
        cursor: 'pointer',
        color: '#444',
        fontWeight: 700,
        filter: 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.25))',
    },
    deleteButtonWrapperContent: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        fontSize: 12,
        transform: 'translate(-50%, -50%)',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    titleNoCTAs: {
        marginBottom: 24,
    },
    button: {
        display: 'flex',
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid #B8C2CC',
        boxSizing: 'border-box',
        boxShadow: 'inset 0px -4px 4px #DAE0E6',
        borderRadius: 4,
    },
    maxPrice: {
        borderRadius: '4px 0 0 4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.24)',
        '& input': {
            textAlign: 'center',
            fontSize: 16,
            padding: 12,
            paddingBottom: 16,
        },
        '& *': {
            borderRadius: 0,
        },
        '& label': {
            transform: 'translate(0px, 8px) scale(1)',
            fontSize: theme.typography.h6.fontSize,
            right: 0,
            top: 8,
        },
    },
    shippingPrice: {
        color: 'black',
        paddingTop: 14,
        textAlign: 'left',
        fontSize: 14,
    },
    shippingPriceMobile: {
        color: 'black',
        textAlign: 'left',
        paddingTop: 14,
        fontSize: 10,
    },
    price: {
        color: theme.palette.common.white,
        background: '#EF2424',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(255, 255, 255, 0.24)',
    },
    deleteButton: {
        boxShadow: 'none',
        background: '#0001',
    },
    imgPlaceHolder: {
        fontSize: 10,
    },
    verticalSeparatorWrapper: {
        maxWidth: 32,
        padding: '16px 0px !important',
    },
    verticalSeparator: {
        borderRight: 'solid 3px #DAE0E6',
        margin: '16px 0',
        paddingTop: 0,
        boxSizing: 'border-box',
    },
    selectDimensions: {
        height: 34,
        paddingTop: 10,
    },
    selectEllipsis: {
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    quantitySection: {
        padding: '10px 8px 24px',
    },
    notesSection: {
        padding: '9px 8px 16px',
    },
    notesMobileSection: {
        padding: '0 8px 24px 8px',
    },
    headingEmphasis: {
        fontSize: 18,
    },
    reducedDivider: {
        margin: '8px 0px',
        borderBottom: '3px solid #DAE0E6',
    },
    reducedButtonsDivider: {
        position: 'relative',
        top: 8,
        margin: 0,
        borderBottom: '3px solid #DAE0E6',
    },
    lowButton: {
        color: '#EF2424',
        background: '#e8e8e8',
    },
    highButton: {
        color: '#358856',
        background: '#e8e8e8',
    },
    instructions: {
        fontSize: 12,
        paddingTop: 4,
    },
    editUpdatedStamp: {
        flex: 1,
        textAlign: 'right',
        paddingTop: 10,
        minWidth: '94%',
        fontSize: 12,
    },
    editUpdatedStampMobile: {
        flex: 1,
        textAlign: 'right',
        paddingTop: 10,
        minWidth: '90%',
        fontSize: 12,
    },
    editCTA: {
        flex: 1,
        textAlign: 'right',
        cursor: 'pointer !important',
    },
    editCTAoutline: {
        border: 'solid 2px black',
        borderRadius: 4,
        cursor: 'pointer',
    },
    cardImg: {
        maxHeight: 218,
        maxWidth: '100%',
        objectFit: 'contain',
        background: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.75)',
    },
}));

const ProductTypes = {
    RAW: 'raw',
    GRADED: 'graded',
};

interface SellCardProps {
    isFormDisabled: boolean;
    loading: boolean;
    isVintage: boolean;
    setting: ProductConfig;
    product: Product;
    sellerCardData: Card;
    leftButtonLabel: string;
    rightButtonLabel: string;
    onLeftButtonClick: Function;
    onFormSubmit: Function;
    disableGrades?: boolean;
    enableImages?: boolean;
}

const styles = {
    box: {
        height: 218,
        justifyContent: 'center',
        background: 'rgba(218, 224, 229, 1)',
        borderRadius: 4,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '8px',
        border: 'dashed #bbb',
        boxSizing: 'border-box',
    },
    displayNone: {
        display: 'none',
    },
};

function AddImage({
    loading = false,
    isDisabled,
    isRequiredImage,
    imgFront,
    imgBack,
    onImgFrontAdded,
    onImgBackAdded,
    onImgFrontDelete,
    onImgBackDelete,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isStockImageFront,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isStockImageBack,
}) {
    const classes = useStyles();
    const handleFrontImg = e => {
        onImgFrontAdded?.(e.target.files[0]);
    };
    const handleBackImg = e => {
        onImgBackAdded?.(e.target.files[0]);
    };
    const handleFrontImgDelete = imgFront => {
        onImgFrontDelete?.(imgFront);
    };
    const handleBackImgDelete = imgBack => {
        onImgBackDelete?.(imgBack);
    };

    const frontCardFileChooser = useRef(null);
    const backCardFileChooser = useRef(null);
    const triggerFrontCardFileChooser = () => {
        frontCardFileChooser.current.click();
    };
    const triggerBackCardFileChooser = () => {
        backCardFileChooser.current.click();
    };

    if (loading) {
        return <BSCLoading loading />;
    }

    return (
        <>
            <div style={{height: 22}}>
                <BSCTypography
                    label="Images:"
                    style={{display: 'inline-block', paddingLeft: 16}}
                    className={classes.headingEmphasis}
                    variant="h4"
                />
                {isRequiredImage && (
                    <div style={{display: 'inline-block', paddingLeft: 16}}>
                        <BSCTypography label="(Required)" style={{color: '#EF2424', fontSize: 16, fontWeight: 'bold'}} />
                    </div>
                )}
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.imgGroup}>
                    <Grid container>
                        <Grid item xs={6} style={{padding: 8}}>
                            <input
                                disabled={isDisabled}
                                style={styles.displayNone}
                                ref={frontCardFileChooser}
                                type="file"
                                onChange={handleFrontImg}
                                accept="image/*"
                            />
                            {imgFront ? (
                                <div className={classes.imageBackgroundContainer}>
                                    {imgFront !== undefined && imgFront !== '' && (
                                        <img className={classes.cardImg} src={Normalizer.getS3ImagePath(imgFront)} />
                                    )}
                                    {imgFront !== undefined && imgFront === '' && (
                                        <img className={classes.cardImg} style={{display: 'none'}} />
                                    )}
                                    {!isDisabled && (
                                        <div onClick={() => handleFrontImgDelete(imgFront)} className={classes.deleteButtonWrapper}>
                                            <div className={classes.deleteButtonWrapperContent}>X</div>
                                        </div>
                                    )}
                                    {isStockImageFront && (
                                        <div>
                                            <BSCTypography label="(Stock Image)" style={{color: '#aaa', fontSize: 9, fontWeight: 400}} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <label htmlFor="fileUpload1" onClick={triggerFrontCardFileChooser}>
                                    <div style={styles.box}>
                                        <div>
                                            <BSCTypography label="UPLOAD" />
                                            <BSCTypography className={classes.imgPlaceHolder} label="(FRONT)" />
                                        </div>
                                    </div>
                                </label>
                            )}
                        </Grid>
                        <Grid item xs={6} style={{padding: 8}}>
                            <input
                                disabled={isDisabled}
                                style={styles.displayNone}
                                ref={backCardFileChooser}
                                type="file"
                                onChange={handleBackImg}
                                accept="image/*"
                            />
                            {imgBack ? (
                                <div className={classes.imageBackgroundContainer}>
                                    {imgBack !== undefined && imgBack !== '' && (
                                        <img className={classes.cardImg} src={Normalizer.getS3ImagePath(imgBack)} />
                                    )}
                                    {imgBack !== undefined && imgBack === '' && (
                                        <img className={classes.cardImg} style={{display: 'none'}} />
                                    )}
                                    {!isDisabled && (
                                        <div onClick={() => handleBackImgDelete(imgBack)} className={classes.deleteButtonWrapper}>
                                            <div className={classes.deleteButtonWrapperContent}>X</div>
                                        </div>
                                    )}
                                    {isStockImageBack && (
                                        <div>
                                            <BSCTypography label="(Stock Image)" style={{color: '#aaa', fontSize: 9, fontWeight: 400}} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <label htmlFor="fileUpload2" onClick={triggerBackCardFileChooser}>
                                    <div style={styles.box}>
                                        <div>
                                            <BSCTypography label="UPLOAD" />
                                            <BSCTypography className={classes.imgPlaceHolder} label="(BACK)" />
                                        </div>
                                    </div>
                                </label>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}

const findPrices = (prices, productType, gradingCompany, grade, condition) => {
    return (
        prices?.find(price => {
            if (
                productType === price.productType &&
                ((gradingCompany === price.gradingCompany?.toLowerCase() && grade === price.grade?.toLowerCase()) ||
                    condition === price.condition)
            ) {
                return true;
            }

            return false;
        }) ?? null
    );
};

/* eslint-disable */

const validateFormState = (formState: SellCardFormState): boolean => {
    return (
        isTruthy(formState?.quantity) &&
        isTruthy(formState?.price) &&
        ((formState?.productType === ProductTypes.RAW && isTruthy(formState.condition)) ||
            (formState?.productType === ProductTypes.GRADED && isTruthy(formState.grade)))
    );
};

const SellerSKUSection = ({isDisabled, onSetSellerSku, sellerSkuValue}) => {
    const classes = useStyles();
    const {isBelowDesktop, isMobile} = useScreenSnap();
    return (
        <Grid container className={isBelowDesktop ? classes.notesMobileSection : classes.notesSection}>
            <Grid item md={isBelowDesktop ? 4 : 6} xs={4} style={{textAlign: 'left', paddingTop: 10}}>
                <BSCTypography className={classes.headingEmphasis} label="Seller SKU:" variant="h4" />
                <BSCTypography label="(Optional)" size14 style={{color: '#aaa'}} />
            </Grid>
            <Grid item md={isBelowDesktop ? 4 : 6} xs={8}>
                <div
                    style={
                        isBelowDesktop
                            ? isMobile
                                ? {textAlign: 'left', paddingTop: 10, paddingLeft: 4}
                                : {textAlign: 'left', paddingTop: 10, paddingLeft: 10}
                            : {paddingLeft: 4}
                    }
                >
                    <BSCTextField
                        disabled={isDisabled}
                        className={classes.maxPrice}
                        style={{marginRight: 4}}
                        placeholder="Optional"
                        onChange={e => onSetSellerSku(e.target.value)}
                        value={sellerSkuValue}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

interface SellCardFormState {
    productType?: string;
    condition?: string;
    grade?: string;
    price?: string;
    quantity?: number;
    shippingCost?: string;
    gradingCompany?: string;
}

const SellCard = ({
    isFormDisabled,
    loading,
    setting,
    product,
    sellerCardData,
    leftButtonLabel,
    rightButtonLabel,
    onLeftButtonClick,
    onFormSubmit,
    disableGrades,
    isVintage,
}: SellCardProps) => {
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();
    const {isDesktop, isBelowTablet, isMobile} = useScreenSnap();
    const dispatch = useDispatch();
    const sellerState = useSelector((state: RootState) => state.seller);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const {cardId} = useParams<{cardId: string}>();
    const classes = useStyles();
    const {loading: formValidationsLoading, data: formValidations} = useQuery(
        () => SellersFormValidationsClient.getSellersFormValidations(),
        []
    );
    const {productReferences: cardRef} = useProductReferenceContext();

    const [loadingFrontImg, setLoadingFrontImg] = useState(false);
    const [frontImgData, setFrontImgData] = useState(null);
    const [loadingBackImg, setLoadingBackImg] = useState(false);
    const [backImgData, setBackImgData] = useState(null);

    const addFrontImg = (cardId, productId, fileData) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const productApi = new ProductApi(apiConfig);
                            setLoadingFrontImg(true);
                            const frontImgData = await productApi.uploadProductAttachment(cardId, productId, fileData);
                            setFrontImgData(frontImgData);
                            setLoadingFrontImg(false);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error attaching image.',
                                contextKey: 'Inventory',
                            });
                            setLoadingFrontImg(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const addBackImg = (cardId, productId, fileData) => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig: Configuration = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const productApi = new ProductApi(apiConfig);
                            setLoadingBackImg(true);
                            const frontImgData = await productApi.uploadProductAttachment(cardId, productId, fileData);
                            setBackImgData(frontImgData);
                            setLoadingBackImg(false);
                        } catch (error) {
                            addToast({
                                severity: 'error',
                                message: 'Error attaching image.',
                                contextKey: 'Inventory',
                            });
                            setLoadingBackImg(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const [hasFrontImage, setHasFrontImage] = useState(false);
    const [hasBackImage, setHasBackImage] = useState(false);
    const [frontImg, setFrontImg] = useState(null);
    const [backImg, setBackImg] = useState(null);

    const [formValid, setFormValid] = useState(false);
    const [editToggle, setEditToggle] = useState(true);

    const addFrontImgAttachment = file => {
        addFrontImg(cardId, setting?.productId, file);
    };

    const addBackImgAttachment = file => {
        addBackImg(cardId, setting?.productId, file);
    };

    const {
        formState,
        validatedSchema: formStateValidations,
        registerFormBlur,
        registerFormChange,
        setDefaultValues,
        resetValues,
    } = useForm<SellCardFormState>({
        validationSchema: SellersFormValidationsNormalizer.getSellYourCard(formValidations),
    });

    const imgFrontKey = frontImg?.objectKey ?? product?.sellerImgFront;
    const imgBackKey = backImg?.objectKey ?? product?.sellerImgBack;
    const [cardProductType, setCardProductType] = useState('');
    const [isEditingButtonVisible, setEditingButtonVisible] = useState(false);
    const productType = formState?.productType;
    const condition = formState?.condition;
    const gradingCompany = String(formState?.gradingCompany)?.toLowerCase();
    const grade = formState?.grade;
    const lowPrice = findPrices(setting?.prices, productType, gradingCompany, grade, condition)?.leastPrice ?? 0;
    const lastSoldPrice = findPrices(setting?.prices, productType, gradingCompany, grade, condition)?.lastSoldPrice ?? 0;
    const dateNormalizer = useDateTimeNormalizer();
    const [isRequiredImage, setIsRequiredImage] = useState(false);
    const [hasPreferredSerialNumber, setHasPreferredSerialNumber] = useState(false);
    const checkForRequiredFields = () => {
        const isFormValid = validateFormState(formState);
        setIsRequiredImage(false);
        setIsRightButtonDisabled(false);
        if (loading) {
            setIsRightButtonDisabled(true);
        }
        if (formValidationsLoading) {
            setIsRightButtonDisabled(true);
        }
        if (!isFormValid) {
            setIsRightButtonDisabled(true);
        }
        if (formState.productType === ProductTypes.GRADED && !hasFrontImage) {
            setIsRequiredImage(true);
            setIsRightButtonDisabled(true);
        }
        if (formState.productType === ProductTypes.GRADED && !hasBackImage) {
            setIsRequiredImage(true);
            setIsRightButtonDisabled(true);
        }
        if (formState.price > 15 && !hasFrontImage) {
            setIsRequiredImage(true);
            setIsRightButtonDisabled(true);
        }
        if (formState.price > 15 && !hasBackImage) {
            setIsRequiredImage(true);
            setIsRightButtonDisabled(true);
        }
        if (hasPreferredSerialNumber && !hasFrontImage) {
            setIsRequiredImage(true);
            setIsRightButtonDisabled(true);
        }
        if (hasPreferredSerialNumber && !hasBackImage) {
            setIsRequiredImage(true);
            setIsRightButtonDisabled(true);
        }
    };

    const editToggleHandler = () => {
        if (!isEdit) {
            dispatch({
                type: constants.IS_EDITING_CURRENT_LISTING_CARD,
                payload: {isEditingCurrentListingCard: true, currentCardProductId: product?.productId},
            });
        } else {
            dispatch({
                type: constants.IS_EDITING_CURRENT_LISTING_CARD,
                payload: {isEditingCurrentListingCard: false, currentCardProductId: {}},
            });
            resetProductData();
        }
        setIsEdit(!isEdit);
    };

    const handleDeleteAttachment = (isFrontImage: boolean) => {
        let targetImageFilename;
        if (isFrontImage) {
            setFrontImg({objectKey: ''});
            setHasFrontImage(false);
        } else {
            setBackImg({objectKey: ''});
            setHasBackImage(false);
        }
        // if (productType === ProductTypes.GRADED) {
        //     setIsRightButtonDisabled(true);
        // }
    };

    const clearImages = () => {
        setFrontImg({objectKey: ''});
        setHasFrontImage(false);
        setBackImg({objectKey: ''});
        setHasBackImage(false);
        setSellerSku('');
        setIsRightButtonDisabled('');
        setIsRequiredImage(false);
    };

    const changePrice = price => {
        if (price.indexOf('.') === -1) {
            price = currencyFormatter(`${price}.00`);
        } else {
            price = currencyFormatter(price);
        }
        setDefaultValues({
            price,
        });
    };
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setQuantity = e => {
        registerFormChange(e);
    };

    const [graded, setGraded] = useState(false);
    const [isRightbuttonDisabled, setIsRightButtonDisabled] = useState(true);
    const [editedProduct, setEditedProduct] = useState(null);

    const resetProductData = () => {
        if (frontImg) {
            frontImg.objectKey = editedProduct.sellerImgFront;
        }
        if (backImg) {
            backImg.objectKey = editedProduct.sellerImgBack;
        }
        setHasFrontImage(true);
        setHasBackImage(true);
        setIsRightButtonDisabled(false);
        setDefaultValues({
            productType: editedProduct.productType,
            condition: editedProduct.condition,
            grade: editedProduct.grade,
            price: editedProduct.price,
            quantity: editedProduct.availableQuantity,
            gradingCompany: editedProduct.gradingCompany?.toLowerCase(),
        });
    };

    const handlePriceBlur = event => {
        let formattedPrice = event.target.value;
        if (event.target.value.indexOf('.') === -1) {
            formattedPrice = currencyFormatter(`${event.target.value}.00`);
        } else {
            formattedPrice = currencyFormatter(event.target.value);
        }
        const numericPrice = Number(formattedPrice.replace(/[^0-9.-]+/g, ''));
        if (numericPrice < 0.25) {
            event.target.value = '0.25';
        } else {
            event.target.value = formattedPrice;
        }
        registerFormBlur(event);
    };

    const actionButtonHandler = () => {
        dispatch({
            type: constants.IS_EDITING_CURRENT_LISTING_CARD,
            payload: {isEditingCurrentListingCard: false, currentCardProductId: product?.productId},
        });
        if (isEdit) {
            if (frontImg.objectKey === '') {
                product.sellerImgFront = '';
            }
            if (backImg.objectKey === '') {
                product.sellerImgBack = '';
            }
        }
        setHasFrontImage(false);
        setHasBackImage(false);
        setEditedProduct(null);
        onFormSubmit(formState, {...product, frontImg, backImg, sellerSku});
    };

    const [modalOpen, toggleModalOpen] = useState(false);
    const handleClose = e => {
        if (e === true) {
            onLeftButtonClick(formState, {...product, frontImg, backImg}, resetValues);
        }
        toggleModalOpen(false);
    };

    const [mobileImageRequirementsModalOpen, toggleMobileImageRequirementsModalOpen] = useState(false);
    const handleMobileImageRequirementsModalClose = () => {
        toggleMobileImageRequirementsModalOpen(false);
    };
    const handleImageRequirementsClick = () => {
        if (!isDesktop) {
            toggleMobileImageRequirementsModalOpen(true);
        }
    };
    const [open, setOpen] = useState(false);
    const [sellerSku, setSellerSku] = useState(null);
    const [isStockImageFront, setStockImageFront] = useState(false);
    const [isStockImageBack, setStockImageBack] = useState(false);

    useEffect(() => {
        if (hasFrontImage || hasBackImage) {
            checkForRequiredFields();
        }
    }, [hasFrontImage, hasBackImage]);

    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (sellerCardData) {
            setHasPreferredSerialNumber(false);
            const preferredSerialNumbers = [
                'SN1',
                'SN2',
                'SN3',
                'SN4',
                'SN5',
                'SN6',
                'SN7',
                'SN8',
                'SN9',
                'SN10',
                'SN11',
                'SN12',
                'SN13',
                'SN14',
                'SN15',
                'SN16',
                'SN17',
                'SN18',
                'SN19',
                'SN20',
                'SN21',
                'SN22',
                'SN23',
                'SN24',
                'SN25',
            ];
            if (sellerCardData.playerAttribute?.length > 0) {
                const playerAttributes = sellerCardData.playerAttribute.split(',');
                playerAttributes.forEach(playerAttribute => {
                    if (preferredSerialNumbers.indexOf(playerAttribute.trim()) > -1) {
                        setHasPreferredSerialNumber(true);
                    }
                });
            }
        }
    }, [sellerCardData]);

    useEffect(() => {
        if (frontImgData) {
            product.sellerImgFront = frontImgData.data.objectKey;
            setFrontImg(frontImgData.data);
            setHasFrontImage(true);
            setStockImageFront(false);
            if (formValid && hasBackImage) {
                setIsRightButtonDisabled(false);
            } else {
                if (productType === ProductTypes.GRADED) {
                    setIsRightButtonDisabled(true);
                }
            }
        } else {
            setFrontImg(null);
        }
    }, [frontImgData]);

    useEffect(() => {
        if (backImgData) {
            product.sellerImgBack = backImgData.data.objectKey;
            setBackImg(backImgData.data);
            setHasBackImage(true);
            setStockImageBack(false);
            if (formValid && hasFrontImage) {
                setIsRightButtonDisabled(false);
            } else {
                if (productType === ProductTypes.GRADED) {
                    setIsRightButtonDisabled(true);
                }
            }
        } else {
            setBackImg(null);
        }
    }, [backImgData]);

    useEffect(() => {
        if (sellerState) {
            if (sellerState.sellersLockerCardsActiveTab === 0) {
                setEditingButtonVisible(true);
            } else {
                setEditingButtonVisible(false);
            }
            if (
                sellerState.sellersLockerCardsActiveTab === 1 &&
                sellerState.isEditingCurrentListingCard &&
                sellerState.currentCardProductId === product?.productId
            ) {
                setEditingButtonVisible(true);
                setFormValid(true);
                checkForRequiredFields();
            }
        }
    }, [sellerState]);

    useEffect(() => {
        if (product) {
            if (product.sellerImgFront === sellerState.stockImageFront) {
                setStockImageFront(true);
            } else {
                setStockImageFront(false);
            }

            if (product.sellerImgBack === sellerState.stockImageBack) {
                setStockImageBack(true);
            } else {
                setStockImageBack(false);
            }

            if (editedProduct === null) {
                setEditedProduct(Object.assign({}, product));
            }
            setHasFrontImage(false);
            if (product?.sellerImgFront) {
                setFrontImg({objectKey: product?.sellerImgFront});
                setHasFrontImage(true);
            }
            setHasBackImage(false);
            if (product?.sellerImgBack) {
                setBackImg({objectKey: product?.sellerImgBack});
                setHasBackImage(true);
            }
            setSellerSku(product.sellerSku);
            setDefaultValues({
                productType: product?.productType,
                condition: product?.condition ?? '',
                grade: product?.grade ?? '',
                price: product?.price ? product.price : '',
                quantity: product?.availableQuantity ? product.availableQuantity : 0,
                shippingCost: setting?.shippingCost,
                gradingCompany: product?.gradingCompany?.toLowerCase() ?? '',
            });
        }
    }, [product]);

    useEffect(() => {
        checkForRequiredFields();
    }, [loading, formValidationsLoading, formValid, isFormDisabled]);

    useEffect(() => {
        if (isFormDisabled) {
            setFormValid(!isFormDisabled);
            checkForRequiredFields();
            return;
        }

        if (formState.productType === 'graded') {
            formState.condition = '';
        } else {
            formState.grade = '';
            formState.gradingCompany = '';
        }
        setCardProductType(formState.productType);

        //Explicit checks to make it clear what state things need to be in before things are set.
        if (formState?.productType === 'graded' && graded === false) {
            setGraded(true);
            setQuantity({target: {name: 'quantity', value: 1}});
        }
        //If the product type is graded and our graded bool is already true we hit the first if already so nothing to do.
        else if (formState?.productType === 'graded' && graded === true) {
            //Lets Go
        } else {
            setGraded(false);
        }

        const isFormValid = validateFormState(formState);
        setFormValid(isFormValid);
        checkForRequiredFields();
    }, [formState, isFormDisabled]);

    return (
        <div className={isEditingButtonVisible ? classes.title : classes.titleNoCTAs}>
            <BSCCardBox className={classes.root}>
                {(formValidationsLoading || loading) && (
                    <BSCLoading
                        loading
                        loadingReasons={{
                            ['Getting Form Data']: formValidationsLoading,
                            ['Modifying Card']: loading,
                        }}
                    />
                )}
                {!loading && !formValidationsLoading && setting && (
                    <>
                        {sellerState.sellersLockerCardsActiveTab === 1 && (
                            <div style={{display: 'flex', marginBottom: 16}}>
                                {!isMobile && product?.updatedTimestamp && (
                                    <div className={classes.editUpdatedStamp}>
                                        Last Updated: {`${dateNormalizer.getDisplayDateTime(product.updatedTimestamp)} EST`}
                                    </div>
                                )}
                                {isMobile && product?.updatedTimestamp && (
                                    <div className={classes.editUpdatedStampMobile}>
                                        Last Updated: {`${dateNormalizer.getDisplayDateTime(product.updatedTimestamp)} EST`}
                                    </div>
                                )}
                                <div className={classes.editCTA}>
                                    <BscToggledEditButton isEdit={isEdit} onCLick={editToggleHandler}></BscToggledEditButton>
                                </div>
                            </div>
                        )}
                        <Grid container spacing={4} style={{minHeight: 420}}>
                            <Grid
                                item
                                md={5}
                                xs={12}
                                className={isMobile || isBelowTablet ? '' : classes.verticalSeparator}
                                style={{padding: '15px 8px 0px 8px'}}
                            >
                                <AddImage
                                    loading={loadingFrontImg || loadingBackImg}
                                    isDisabled={
                                        (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                        (sellerState.sellersLockerCardsActiveTab == 1 && !isEdit)
                                    }
                                    isRequiredImage={isRequiredImage}
                                    onImgBackAdded={addBackImgAttachment}
                                    onImgFrontAdded={addFrontImgAttachment}
                                    imgFront={frontImg?.objectKey ?? product?.sellerImgFront}
                                    imgBack={backImg?.objectKey ?? product?.sellerImgBack}
                                    onImgFrontDelete={() => handleDeleteAttachment(true)}
                                    onImgBackDelete={() => handleDeleteAttachment(false)}
                                    isStockImageFront={isStockImageFront}
                                    isStockImageBack={isStockImageBack}
                                />
                                <div style={{margin: '16px 0 0 12px', fontFamily: "'Poppins', sans-serif", fontSize: 14}}>
                                    <Grid container>
                                        <Grid item md={6} xs={6} style={{maxWidth: 154}}>
                                            <BSCTypography label="Image Requirements" onClick={handleImageRequirementsClick} underline />
                                        </Grid>
                                        <Grid item md={6} xs={6}>
                                            <Tooltip
                                                title={
                                                    <div style={{whiteSpace: 'pre-line'}}>
                                                        Images are recommended on all listings. They are required if the card meets any of
                                                        the following specifications:
                                                        <div style={{lineHeight: 2}}>
                                                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;Graded Cards
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;Cards over $15
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&bull;&nbsp;Cards Serial Numbered #/25 or less
                                                        </div>
                                                    </div>
                                                }
                                                placement="right"
                                            >
                                                <InfoOutlinedIcon
                                                    onClick={() => setOpen(true)}
                                                    style={{fontSize: '0.9em', cursor: 'pointer'}}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.reducedDivider} style={{marginTop: 22}}></div>
                                {isDesktop && (
                                    <div>
                                        <Grid container className={classes.notesSection}>
                                            <SellerSKUSection
                                                isDisabled={sellerState.sellersLockerCardsActiveTab == 1 && !isEdit}
                                                onSetSellerSku={setSellerSku}
                                                sellerSkuValue={sellerSku}
                                            />
                                        </Grid>
                                    </div>
                                )}
                            </Grid>
                            <Grid item md={7} xs={12} style={{padding: '32px 16px 16px 16px'}}>
                                <div style={{display: 'flex'}}>
                                    <CardConditions show>
                                        <BSCTypography className={classes.headingEmphasis} label="Card Information:" variant="h4" />
                                    </CardConditions>
                                </div>
                                <div className={classes.contentWrapper} style={{paddingTop: 8}}>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} xs={4}>
                                            {cardRef?.productType?.length > 0 && (
                                                <BSCSelect
                                                    disabled={
                                                        disableGrades ||
                                                        (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                        sellerState.sellersLockerCardsActiveTab == 1
                                                    }
                                                    fullWidth
                                                    formStateValidations={formStateValidations}
                                                    inputKey="productType"
                                                    onInputChange={registerFormChange}
                                                >
                                                    <MenuItem disabled key="placeHolder" value="">
                                                        <BSCTypography className={classes.selectDimensions} label="Type" />
                                                    </MenuItem>
                                                    {cardRef?.productType?.map(type => {
                                                        return (
                                                            <MenuItem key={type.key} value={type.key}>
                                                                <BSCTypography className={classes.selectDimensions} label={type.name} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </BSCSelect>
                                            )}
                                        </Grid>
                                        {productType === ProductTypes.RAW && (
                                            <Grid item md={4} xs={4}>
                                                <BSCSelect
                                                    disabled={
                                                        disableGrades ||
                                                        (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                        sellerState.sellersLockerCardsActiveTab == 1
                                                    }
                                                    fullWidth
                                                    inputKey="condition"
                                                    onInputChange={registerFormChange}
                                                    formStateValidations={formStateValidations}
                                                >
                                                    <MenuItem disabled key="placeHolder" value="">
                                                        <BSCTypography
                                                            className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                            label="Condition"
                                                        />
                                                    </MenuItem>

                                                    {!isVintage &&
                                                        cardRef?.condition?.map(cardRefCondition => {
                                                            return (
                                                                <MenuItem key={cardRefCondition.key} value={cardRefCondition.key}>
                                                                    <BSCTypography
                                                                        className={classes.selectDimensions}
                                                                        label={cardRefCondition.name}
                                                                    />
                                                                </MenuItem>
                                                            );
                                                        })}

                                                    {isVintage &&
                                                        cardRef?.conditionVintage?.map(cardRefCondition => {
                                                            return (
                                                                <MenuItem key={cardRefCondition.key} value={cardRefCondition.key}>
                                                                    <BSCTypography
                                                                        className={classes.selectDimensions}
                                                                        label={cardRefCondition.name}
                                                                    />
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </BSCSelect>
                                            </Grid>
                                        )}
                                        {cardRef && productType === ProductTypes.GRADED && (
                                            <Grid item md={4} xs={4}>
                                                <BSCSelect
                                                    disabled={
                                                        disableGrades ||
                                                        (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                        sellerState.sellersLockerCardsActiveTab == 1
                                                    }
                                                    fullWidth
                                                    inputKey="gradingCompany"
                                                    onInputChange={registerFormChange}
                                                    formStateValidations={formStateValidations}
                                                >
                                                    <MenuItem disabled key="placeHolder" value="">
                                                        <BSCTypography
                                                            className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                            label="Company"
                                                        />
                                                    </MenuItem>
                                                    {cardRef.gradingCompany?.map(company => {
                                                        return (
                                                            <MenuItem key={company.key} value={company.key}>
                                                                <BSCTypography
                                                                    className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                                    label={company.name}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </BSCSelect>
                                            </Grid>
                                        )}
                                        {cardRef && cardRef?.[gradingCompany + 'Grades'] && productType === ProductTypes.GRADED && (
                                            <Grid item md={4} xs={4}>
                                                <BSCSelect
                                                    disabled={
                                                        disableGrades ||
                                                        (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                        sellerState.sellersLockerCardsActiveTab == 1
                                                    }
                                                    fullWidth
                                                    inputKey="grade"
                                                    onInputChange={registerFormChange}
                                                    formStateValidations={formStateValidations}
                                                >
                                                    <MenuItem disabled key="placeHolder" value="">
                                                        <BSCTypography className={classes.selectDimensions} label="Grade" />
                                                    </MenuItem>
                                                    {cardRef[gradingCompany + 'Grades']?.map(company => {
                                                        return (
                                                            <MenuItem key={company.key} value={company.key}>
                                                                <BSCTypography
                                                                    className={classNames(classes.selectDimensions, classes.selectEllipsis)}
                                                                    label={company.name}
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </BSCSelect>
                                            </Grid>
                                        )}
                                        <Grid item md={12} xs={12}>
                                            <div className={classes.reducedDivider}></div>
                                        </Grid>
                                        <Grid item md={4} xs={4} style={{textAlign: 'left', paddingTop: 20}}>
                                            <BSCTypography className={classes.headingEmphasis} label="Your Price:" variant="h4" />
                                        </Grid>
                                        <Grid item md={4} xs={4} style={{fontSize: 12}}>
                                            <BSCTextField
                                                disabled={
                                                    (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                    (sellerState.sellersLockerCardsActiveTab == 1 && !isEdit)
                                                }
                                                className={classNames(classes.maxPrice)}
                                                placeholder={`max : ${setting.maxSellingPrice}`}
                                                formStateValidations={formStateValidations}
                                                inputKey="price"
                                                onBlur={handlePriceBlur}
                                                onChange={e => {
                                                    const re = /^(\d*\.{0,1}\d{0,2}$)/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        registerFormChange(e);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={4}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item md={8} xs={8}>
                                            <div className={classes.reducedButtonsDivider}></div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSCButton
                                                fullWidth
                                                disabled={
                                                    (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                    (sellerState.sellersLockerCardsActiveTab == 1 && !isEdit)
                                                }
                                                className={classes.lowButton}
                                                onClick={() => {
                                                    if (lowPrice) {
                                                        let minimumPrice = parseFloat(lowPrice);
                                                        if (minimumPrice <= 0) {
                                                            minimumPrice = 0.25;
                                                        }
                                                        changePrice(minimumPrice.toFixed(2));
                                                    }
                                                }}
                                            >{`${
                                                lowPrice
                                                    ? `${new Intl.NumberFormat('en-US', {
                                                          style: 'currency',
                                                          currency: 'USD',
                                                      }).format(lowPrice)}`
                                                    : '-'
                                            }`}</BSCButton>
                                            <div className={classes.instructions}>
                                                <span>Click to Match </span>
                                                <span style={{color: '#EF2424'}}>BSC Low</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <BSCButton
                                                fullWidth
                                                disabled={
                                                    (sellerState.sellersLockerCardsActiveTab == 0 && isFormDisabled === true) ||
                                                    (sellerState.sellersLockerCardsActiveTab == 1 && !isEdit)
                                                }
                                                className={classes.highButton}
                                                onClick={() => {
                                                    if (lastSoldPrice) {
                                                        const newPrice = Number.parseFloat(lastSoldPrice);
                                                        changePrice(newPrice.toFixed(2));
                                                    }
                                                }}
                                            >{`${
                                                lastSoldPrice
                                                    ? `${new Intl.NumberFormat('en-US', {
                                                          style: 'currency',
                                                          currency: 'USD',
                                                      }).format(lastSoldPrice)}`
                                                    : '-'
                                            }`}</BSCButton>
                                            <div className={classes.instructions}>
                                                <span>Click to Match </span>
                                                <span style={{color: '#358856'}}>Last Sold</span>
                                            </div>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <div className={classes.reducedDivider}></div>
                                        </Grid>
                                        <Grid container className={classes.quantitySection}>
                                            <Grid item md={4} xs={4} style={{textAlign: 'left', paddingTop: 10}}>
                                                <BSCTypography className={classes.headingEmphasis} label="Quantity:" variant="h4" />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div style={{paddingLeft: 4}}>
                                                    <BSCTextField
                                                        disabled={
                                                            productType === ProductTypes.GRADED ||
                                                            (sellerState.sellersLockerCardsActiveTab == 0 && graded) ||
                                                            (sellerState.sellersLockerCardsActiveTab == 1 && !isEdit)
                                                        }
                                                        className={classes.maxPrice}
                                                        style={{marginRight: 4}}
                                                        formStateValidations={formStateValidations}
                                                        inputKey="quantity"
                                                        onChange={e => {
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                setQuantity(e);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                &nbsp;
                                            </Grid>
                                        </Grid>
                                        {!isDesktop && (
                                            <>
                                                <Grid item md={12} xs={12}>
                                                    <div className={classes.reducedDivider}></div>
                                                </Grid>
                                                <SellerSKUSection
                                                    isDisabled={
                                                        productType === ProductTypes.GRADED ||
                                                        (sellerState.sellersLockerCardsActiveTab == 0 && graded) ||
                                                        (sellerState.sellersLockerCardsActiveTab == 1 && !isEdit)
                                                    }
                                                    onSetSellerSku={setSellerSku}
                                                    sellerSkuValue={sellerSku}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}
                {isEditingButtonVisible && (
                    <>
                        <BSCDivider style={{margin: '12px 0px 8px'}} />
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={6}>
                                <BSCButton
                                    disabled={loading || formValidationsLoading}
                                    fullWidth
                                    color="primary"
                                    onClick={() => {
                                        if (leftButtonLabel === 'Delete') {
                                            toggleModalOpen(true);
                                        } else {
                                            onLeftButtonClick(formState, {...product, frontImg, backImg}, resetValues);
                                            clearImages();
                                        }
                                    }}
                                >
                                    <BSCTypography label={leftButtonLabel} size14></BSCTypography>
                                </BSCButton>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <BSCButton disabled={isRightbuttonDisabled} fullWidth color="secondary" onClick={actionButtonHandler}>
                                    <BSCTypography label={rightButtonLabel} size14></BSCTypography>
                                </BSCButton>
                            </Grid>
                        </Grid>
                    </>
                )}
            </BSCCardBox>
            <MobileImageRequirementsModal open={mobileImageRequirementsModalOpen} onClose={handleMobileImageRequirementsModalClose} />
            <DeleteConfirmationModal open={modalOpen} onClose={(e: boolean) => handleClose(e)} />
        </div>
    );
};

export default SellCard;
