import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';

const useStyles = makeStyles((theme: Theme) => ({
    marginRight: {
        marginRight: 16,
    },
    marginBottom: {
        marginBottom: 16,
    },
    overflowHidden: {
        overflow: 'hidden',
    },
    modalDimensions: {
        height: '96px',
        [theme.breakpoints.down('xs')]: {height: '130px'},
    },
    confirmation: {
        fontSize: '20px !important',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        lineHeight: '1.167.toFixed',
        letterSpacing: 0.5,
        paddingBottom: 20,
    },
}));

interface NotSignedInModalProps {
    open: boolean;
    onClose: boolean;
}

function NotSignedInModal({open, onClose}: NotSignedInModalProps) {
    const classes = useStyles();

    return (
        <BSCModal headerLabel="Checkout" hasCustomHeaderStyling={true} open={open} onClose={onClose}>
            <div className={classes.modalDimensions}>
                <BSCTypography align="center" label="Please Sign-In to Checkout" className={classes.confirmation} />
                <div className={classes.overflowHidden}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                            <BSCButton color="primary" onClick={() => onClose()}>
                                <BSCTypography label="OK" />
                            </BSCButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </BSCModal>
    );
}

export default NotSignedInModal;
