import {makeStyles} from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import {useState} from 'react';

const useStyles = makeStyles({
    image: {
        opacity: '1',
        filter: 'blur(0px)',
        webkitFilter: 'blur(0px)',
        transition: 'filter .5s',
        visibility: 'visible',
        left: 0,
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
    },
    imageLoading: {
        position: 'absolute',
        left: '-100vh',
        opacity: '0',
        filter: 'blur(8px)',
        webkitFilter: 'blur(8px)',
        visibility: 'hidden',
    },
    skeleton: {
        width: '100%',
        height: '100%',
    },
});

interface BSCImageProps {
    src: string;
    title: string;
    placeHolderSrc?: string;
    className?: string | null;
    alt?: string;
    // TODO: Look at this any later
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: undefined;
}

function BSCImage({
    src,
    className = null,
    //TODO: Need to store the image locally
    title,
    placeHolderSrc = 'https://placehold.co/250x350/d9e0e6/6e757b?font=poppins&text=BSC+Image+Coming+Soon',
    alt = null,
    ...otherProps
}: BSCImageProps) {
    const [imageLoading, setImageLoading] = useState<boolean>(true);
    const classes = useStyles();

    const addDefaultSrc = ev => {
        ev.target.src = placeHolderSrc;
    };

    return (
        <>
            {imageLoading && <Skeleton style={{margin: 'auto'}} variant="rect" className={classes.skeleton} />}
            <img
                //height={120}
                {...otherProps}
                alt={alt}
                title={title}
                className={classNames(
                    {
                        [classes.imageLoading]: imageLoading,
                    },
                    className,
                    classes.image
                )}
                onLoadStart={() => setImageLoading(true)}
                onLoad={() => setImageLoading(false)}
                src={src ?? placeHolderSrc}
                // onError={() => setImageLoading(true)}
                onError={addDefaultSrc}
            />
        </>
    );
}

export default BSCImage;
