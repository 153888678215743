import {ModalProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import BSCModal from '../../../../features/common/bscModal/bscModal';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import {RootState} from '../../../../redux/reducers';

interface ContactUsModalProps extends Omit<ModalProps, 'children'> {
    message: string;
    enteredData?: undefined;
}

const useStyles = makeStyles({
    modalContent: {},
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContactUsModal = ({open, onClose, message, enteredData = undefined}: ContactUsModalProps) => {
    const classes = useStyles();
    const history = useHistory();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [pwaOS, setPwaOS] = useState(null);
    const handleClose = () => {
        if (pwaOS !== null) {
            history.goBack();
        } else {
            history.push({
                pathname: '/',
            });
        }
        onClose;
    };

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (message && enteredData) {
            if (message.includes('Sorry')) {
                const bodyText =
                    'Dear Admin, %0A%0A' +
                    'User ' +
                    enteredData.firstName +
                    ' ' +
                    enteredData.lastName +
                    ' has contacted. %0A%0A' +
                    'Subject: %0A' +
                    enteredData.subject +
                    '%0A%0A' +
                    'Message: %0A' +
                    enteredData.message +
                    '%0A%0A' +
                    'Email Id: ' +
                    enteredData.emailAddress +
                    '%0A%0A';
                setTimeout(() => {
                    window.open(`mailto:support@buysportscards.com?subject=${enteredData.subject}&body=${bodyText}`, '_blank')?.focus();
                }, 3000);
                setTimeout(() => {
                    handleClose();
                }, 5000);
            }
        }
    }, [enteredData, message]);

    return (
        <BSCModal open={open} onClose={handleClose}>
            <div style={{height: 64}}>
                <BSCTypography className={classes.modalContent} align="center" variant="h4" label={message} />
            </div>
        </BSCModal>
    );
};

export default ContactUsModal;
