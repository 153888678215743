/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Alert, Skeleton} from '@material-ui/lab';
import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import {SearchCard} from '../../../../client/openapitools/search';
import PlayerCard from '../../../../features/cards/playerCard';
import BSCErrorBanner from '../../../../features/common/bscAlert/bscAlert';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import useApi from '../../../../features/hooks/redux/apiConfig/useApi';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../redux/reducers';
import * as constants from '../../../../shared/constants';
import StorefrontBanner from './StorefrontBanner';
import {set} from 'lodash';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) => ({
    errorWrap: {
        padding: theme.spacing(1),
    },
}));

interface SearchResultsProps {
    results: SearchCard[];
    chipList?: [] | null;
    loading?: boolean;
    errors: Error | null;
    sortOrder: string;
    condition: string;
    sellerId: string;
    sellerName: string;
    currentPage: number;
    isInfiniteScrolling: boolean;
    hasMoreInfiniteResults: boolean;
    onBottomDetected?: () => void;
}

function SearchResults({
    results,
    chipList,
    loading = true,
    errors,
    sortOrder,
    condition,
    currentPage,
    isInfiniteScrolling,
    hasMoreInfiniteResults,
    onBottomDetected,
}: SearchResultsProps) {
    const DEFAULT_TITLE = 'BuySportsCards.com: Millions of Sports Cards for Sale';
    const classes = useStyles();
    const api = useApi();
    const dispatch = useDispatch();
    const {isMobile} = useScreenSnap();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedSellerId, setSelectedSellerId] = useState(null);
    const [selectedSellerName, setSelectedSellerName] = useState(null);
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const parsed = queryString.parse(urlSearchString);
    const {sellerId, sellerName} = parsed;
    useEffect(() => {
        if (sellerId && sellerName) {
            dispatch({
                type: constants.SELLER_STOREFRONT_SELECTED,
                payload: {sellerId: sellerId, sellerName: sellerName},
            });
        }
    }, [sellerId, sellerName]);

    const [pwaOS, setPwaOS] = useState(null);
    const [isRetrievingMoreData, setIsRetrievingMoreData] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);
    const lastPlayerCardElementRef = useCallback(
        node => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMoreInfiniteResults) {
                    setIsRetrievingMoreData(true);
                    console.log('>>> fetch more cards');
                    onBottomDetected && onBottomDetected();
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMoreInfiniteResults]
    );

    const handleClick = id => {
        localStorage.setItem('scrollableId', id);
        dispatch({
            type: constants.SET_MARKETPLACE_SCROLLABLE_ID,
            payload: {scrollableId: id},
        });
        dispatch({
            type: constants.SHOW_ALL_LISTINGS,
            payload: false,
        });
    };

    useEffect(() => {
        if (marketplaceState) {
            const tempSellerId = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerId'] : null;
            setSelectedSellerId(tempSellerId);
            const tempSellerName = marketplaceState.sellerStorefront !== {} ? marketplaceState.sellerStorefront['sellerName'] : null;
            setSelectedSellerName(tempSellerName);
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    useEffect(() => {
        if (results && results.length > 0 && selectedSellerId) {
            try {
                api.marketplaceApi.cardApi.getCardListingsBySeller(results[0].id, selectedSellerId).then(response => {
                    const responseData = response.data;
                    if (responseData && responseData.listings.length > 0) {
                        let calculatedFeedbackRating;
                        if (responseData.listings[0]?.seller?.sellerProfile?.aggregateRating) {
                            calculatedFeedbackRating = parseFloat(responseData.listings[0].seller.sellerProfile.aggregateRating).toFixed(1);
                            if (calculatedFeedbackRating.endsWith('.0')) {
                                calculatedFeedbackRating = calculatedFeedbackRating.replace('.0', '');
                            }
                            dispatch({
                                type: constants.PWA_SELLER_STOREFRONT_FEEDBACK_RATING,
                                payload: {pwaSellerStorefrontFeedbackRating: calculatedFeedbackRating},
                            });
                        }
                        dispatch({
                            type: constants.PWA_SELLER_STOREFRONT_CARDS_SOLD,
                            payload: {pwaSellerStorefrontCardsSold: responseData.listings[0].seller.sellerProfile.salesNumber},
                        });
                    }
                });
            } catch (error) {
                console.error('Error fetching card listings', error);
            }
        }
    }, [results, selectedSellerId]);

    useEffect(() => {
        if (params && results && chipList) {
            if (results.length > 0) {
                const productInfo = results[0];
                let rawTitle = 'Shop';
                const players = chipList.filter(chip => chip['type'] === 'players');
                let player = '';
                if (players.length > 0) {
                    productInfo.players.forEach((playerName, index) => {
                        if (index === 0) {
                            player = playerName;
                        } else {
                            player = playerName + ', ' + player;
                        }
                    });
                }
                const priceRangeStart = params.get('priceRangeStart');
                const priceRangeEnd = params.get('priceRangeEnd');
                const year = params.get('year[]');
                const setName = chipList.filter(chip => chip['type'] === 'setName');
                const variantName = chipList.filter(chip => chip['type'] === 'variantName');
                const teamName = chipList.filter(chip => chip['type'] === 'teamName');
                const sport = chipList.filter(chip => chip['type'] === 'sport');
                if (priceRangeEnd === '5' && priceRangeStart === null) {
                    rawTitle = rawTitle + ' Under $5';
                }
                if (priceRangeStart === '5' && priceRangeEnd === '20') {
                    rawTitle = rawTitle + ' $5 to $20';
                }
                if (priceRangeStart === '20' && priceRangeEnd === null) {
                    rawTitle = rawTitle + ' Over $20';
                }
                // if (priceRangeStart !== null && priceRangeEnd !== null) {
                //     rawTitle = rawTitle + ' $' + parseInt(priceRangeStart) + ' to $' + parseInt(priceRangeEnd);
                // }
                if (player !== '') {
                    rawTitle = rawTitle + ' ' + player;
                }
                if (year !== null) {
                    rawTitle = rawTitle + ' ' + year;
                }
                if (setName.length > 0) {
                    rawTitle = rawTitle + ' ' + setName[0].label;
                }
                if (variantName.length > 0) {
                    rawTitle = rawTitle + ' ' + variantName[0].label;
                }
                if (teamName.length > 0) {
                    rawTitle = rawTitle + ' ' + teamName[0].label;
                }
                if (sport.length > 0) {
                    rawTitle = rawTitle + ' ' + sport[0].label;
                }
                rawTitle += ' Cards';
                if (rawTitle !== 'Shop Cards') {
                    document.title = rawTitle;
                } else {
                    document.title = DEFAULT_TITLE;
                }
            } else {
                document.title = DEFAULT_TITLE;
            }
        } else {
            document.title = DEFAULT_TITLE;
        }
    }, [params, results, chipList]);

    const [searchCompletedFlag, setSearchCompletedFlag] = useState(-1);
    useEffect(() => {
        if (isMobile && marketplaceState.scrollableId === '' && pwaOS === null && currentPage > 0) {
            const returnPoint = document.getElementById('topOfResultsGrid');
            if (returnPoint !== null) {
                returnPoint.scrollIntoView(true);
            }
        } else {
            let returnPoint;
            if (marketplaceState.scrollableId !== '') {
                setTimeout(() => {
                    returnPoint = document.getElementById(marketplaceState.scrollableId);
                    if (returnPoint && returnPoint !== null) {
                        returnPoint.scrollIntoView({behavior: 'auto', block: 'center', inline: 'center'});
                    }
                }, 500);
            }
        }
        if (results && !loading) {
            setSearchCompletedFlag(results.length);
        }
    }, [results, currentPage, loading]);

    const [hasDataReady, setHasDataReady] = useState(false);
    useEffect(() => {
        if (isRetrievingMoreData === false) {
            setIsRetrievingMoreData(false);
            setHasDataReady(false);
            setSearchCompletedFlag(-1);
            if (results) {
                setTimeout(() => {
                    setHasDataReady(true);
                    setSearchCompletedFlag(results.length);
                }, 2000);
            }
        }
    }, [results]);

    return (
        <>
            {results?.length === 0 && (
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
            )}
            <Grid id="topOfResultsGrid" container spacing={2}>
                {selectedSellerName && pwaOS === null && (
                    <StorefrontBanner sellerId={selectedSellerId} sellerName={selectedSellerName} cta="Leave" />
                )}
                {pwaOS === null && loading && (
                    <>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <PlayerCard loading={loading} data={null} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <PlayerCard loading={loading} data={null} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <PlayerCard loading={loading} data={null} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <PlayerCard loading={loading} data={null} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <PlayerCard loading={loading} data={null} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <PlayerCard loading={loading} data={null} />
                        </Grid>
                    </>
                )}
                {errors && (
                    <Grid xs={12}>
                        <Box className={classes.errorWrap}>
                            <BSCErrorBanner errors={errors} />
                        </Box>
                    </Grid>
                )}
                {pwaOS === null &&
                    results?.map(data => (
                        <Grid id={data.id} key={data.id} item xs={12} sm={6} lg={4} xl={3} onClick={() => handleClick(data.id)}>
                            <PlayerCard data={data} sortOrder={sortOrder} condition={condition} />
                        </Grid>
                    ))}
                {pwaOS === null && results?.length === 0 && (
                    <Grid item xs={12}>
                        <Alert severity="warning">Expand your search criteria to discover more deals!</Alert>
                    </Grid>
                )}
                {isInfiniteScrolling && hasDataReady === false && (
                    <>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Skeleton variant="rect" height={150} style={{margin: '8px 8px 0 12px', borderRadius: 8}} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Skeleton variant="rect" height={150} style={{margin: '8px 8px 0 12px', borderRadius: 8}} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Skeleton variant="rect" height={150} style={{margin: '8px 8px 0 12px', borderRadius: 8}} />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Skeleton variant="rect" height={150} style={{margin: '8px 8px 0 12px', borderRadius: 8}} />
                        </Grid>
                    </>
                )}
                {isInfiniteScrolling && hasDataReady && (
                    <>
                        {results.length === 0 && searchCompletedFlag !== -1 && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Alert severity="warning">Expand your search criteria to discover more deals!</Alert>
                                </Grid>
                            </React.Fragment>
                        )}
                        {results.length > 0 &&
                            results.length < 50 &&
                            results?.map((data: SearchCard, index) => (
                                <React.Fragment key={`${data.id} ${index}`}>
                                    <Grid id={data.id} item xs={12} sm={6} lg={4} xl={3} onClick={() => handleClick(data.id)}>
                                        <PlayerCard data={data} sortOrder={sortOrder} condition={condition} />
                                    </Grid>
                                </React.Fragment>
                            ))}
                        {results.length > 0 &&
                            results.length >= 50 &&
                            results?.map((data: SearchCard, index) => (
                                <React.Fragment key={`${data.id} ${index}`}>
                                    {results.length === index + 1 ? (
                                        <Grid
                                            ref={lastPlayerCardElementRef}
                                            id={data.id}
                                            item
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            xl={3}
                                            onClick={() => handleClick(data.id)}
                                        >
                                            <PlayerCard data={data} sortOrder={sortOrder} condition={condition} />
                                        </Grid>
                                    ) : (
                                        <Grid id={data.id} item xs={12} sm={6} lg={4} xl={3} onClick={() => handleClick(data.id)}>
                                            <PlayerCard data={data} sortOrder={sortOrder} condition={condition} />
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                        {isInfiniteScrolling && searchCompletedFlag === -1 && (
                            <Grid item xs={12}>
                                <BSCLoading loading={true} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <BSCLoading loading={loading} />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}

export default memo(SearchResults);
