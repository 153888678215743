import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import * as constants from '../../../../shared/constants';

interface ManageSellButtonProps {
    sellerQty: number;
    cardId: string;
    searchCatalogType: boolean;
    className;
}

function ManageSellButton({sellerQty, cardId, searchCatalogType, className, ...otherProps}: ManageSellButtonProps) {
    const history = useHistory();
    const dispatch = useDispatch();

    return searchCatalogType === 'true' ? (
        <BSCButton
            {...otherProps}
            onClick={e => {
                e.stopPropagation;
                dispatch({
                    type: constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB,
                    payload: {sellersLockerCardsActiveTab: 1},
                });
                dispatch({
                    type: constants.PWA_SELL_YOUR_CARDS_ACTIVE_TAB,
                    payload: {pwaSellYourCardsActiveTab: 0},
                });
                history.push({
                    pathname: `/sellers/sell-your-card/edit/${cardId}`,
                });
            }}
            color="secondary"
            className={className}
            style={{width: 128}}
        >
            Manage{sellerQty > 0 ? ` (${sellerQty})` : ''}
        </BSCButton>
    ) : (
        <BSCButton
            {...otherProps}
            onClick={e => {
                e.stopPropagation;
                dispatch({
                    type: constants.SELLERS_LOCKER_CARDS_ACTIVE_TAB,
                    payload: {sellersLockerCardsActiveTab: 0},
                });
                dispatch({
                    type: constants.PWA_SELL_YOUR_CARDS_ACTIVE_TAB,
                    payload: {pwaSellYourCardsActiveTab: 0},
                });
                history.push({
                    pathname: `/sellers/sell-your-card/add/${cardId}`,
                });
            }}
            color="primary"
            className={className}
        >
            Add
        </BSCButton>
    );
}

export default ManageSellButton;
