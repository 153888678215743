import {Configuration, LogLevel, PopupRequest, PublicClientApplication} from '@azure/msal-browser';

export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_SIGNUP_SIGNIN,
        signUp: process.env.REACT_APP_SIGNUP,
        signIn: process.env.REACT_APP_SIGNIN,
        passwordReset: process.env.REACT_APP_PASSWORD_RESET,
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_AUTHORITY,
        },
        signUp: {
            authority: process.env.REACT_APP_AUTHORITY_SIGNUP,
        },
        signIn: {
            authority: process.env.REACT_APP_AUTHORITY_SIGNIN,
        },
        passwordReset: {
            authority: process.env.REACT_APP_AUTHORITY_PASSWORDRESET,
        },
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN,
};

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || '', // This is the ONLY mandatory field that you need to supply.
        authority:
            localStorage.getItem('pwaSettings') !== null
                ? b2cPolicies.authorities.signIn.authority
                : b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain || ''], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {},
};

if (process.env.REACT_APP_VERBOSE_MSAL_LOGGING) {
    if (msalConfig.system) {
        msalConfig.system.loggerOptions = {
            // Your logger options here
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false,
        };
    }
}

export const AUTH_SCOPES = {
    OPENID: 'openid',
    OFFLINE_ACCESS: 'offline_access',
    PROFILE: 'profile',
    API: process.env.REACT_APP_API_ACCESS_URI,
};

export const AUTH_REQUESTS = {
    LOGIN: {
        scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE, AUTH_SCOPES.API],
    },
    EMAIL: {
        scopes: [],
    },
    REFRESH_TOKEN: {
        scopes: [process.env.REACT_APP_CLIENT_ID],
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['openid', process.env.REACT_APP_API_ACCESS_URI],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};

export const msalInstance = new PublicClientApplication(msalConfig);
async function initializeMSAL() {
    await msalInstance.initialize();
}

initializeMSAL();
