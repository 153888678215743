import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../redux/reducers';
import {Switch, Route} from 'react-router';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import ReplyMessage from '../../../marketplace/pages/dashboard/tabs/messages/components/replyMessage';
import CreateMessage from '../../../marketplace/pages/dashboard/tabs/messages/components/createMessage';
import ReferencesClient from '../../../../client/references/referencesClient';
import SellersMessageList from './sellerMessageList';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    rootPWA: {
        height: 675,
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
}));

function SellersMessages() {
    const classes = useStyles();
    const {data: msgRef} = useQuery(() => ReferencesClient.getMessageReferences(), []);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const [pwaOS, setPwaOS] = useState(null);

    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    return (
        <BSCContainer variant="page" className={pwaOS !== null ? classes.rootPWA : null}>
            <Switch>
                <Route exact path="/sellers/messages">
                    <SellersMessageList msgRef={msgRef} />
                </Route>
                <Route exact path={'/sellers/messages/reply/:messageId'}>
                    <ReplyMessage isMarketPlace={false} messageRef={msgRef} orderUrl="/sellers/orders" />
                </Route>
                <Route exact path={'/sellers/messages/create/'}>
                    <CreateMessage isMarketPlace={false} messageRef={msgRef} orderUrl="/sellers/orders" />
                </Route>
            </Switch>
        </BSCContainer>
    );
}

export default SellersMessages;
