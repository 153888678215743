import {SellerSearchRequest, ShopSearchRequest} from '../client/openapitools/search';
import {SearchSorts} from '../enums/filterEnum';

//import {QueryString} from '../packages/core';

interface MarketPlaceSearchArgs {
    terms: {
        sort?: SearchSorts; //InlineObject1SortEnum;
        p?: string | null;
        q?: string | null;
        pageNumber?: number;
        inStock?: boolean;
        type?: string;
        rawCondition?: undefined | null;
        gradingCompany?: undefined | null;
        gradeRangeStart?: number | null;
        gradeRangeEnd?: number | null;
        letterGrade?: undefined | null;
        priceRangeStart?: number | null;
        priceRangeEnd?: number | null;
        myInventory?: boolean;
        savedSearchId?: string | null;
        savedSellerSearchId?: string | null;
    };
    listingSearch?: object | null;
    sellerKey: string;
    resultsPerPage?: number;
}

interface query {
    q?: string;
}

export const searchCreator = ({
    terms,
    listingSearch,
    sellerKey,
    resultsPerPage,
}: MarketPlaceSearchArgs): {queryString: string; postObj: ShopSearchRequest} => {
    const {
        sort,
        q,
        p,
        inStock,
        type,
        rawCondition,
        gradingCompany,
        gradeRangeStart,
        gradeRangeEnd,
        letterGrade,
        priceRangeStart,
        priceRangeEnd,
        savedSearchId,
        savedSellerSearchId,
        ...otherTerms
    } = terms;

    const condition = {};
    if (rawCondition?.length > 0) {
        condition.raw = rawCondition;
    }
    if (gradingCompany?.length > 0) {
        condition.graded = {
            company: gradingCompany,
            rangeStart: parseFloat(gradeRangeStart?.toString()),
            rangeEnd: parseFloat(gradeRangeEnd?.toString()),
            letterGrade: letterGrade,
        };
    }

    const postObj = {
        filters: {},
        listingSearch: sellerKey ? {sellerKey} : {},
        page: p === undefined ? 0 : parseInt(p),
        size: resultsPerPage,
        sort: sort ?? 'best-sellers',
        inStock: (inStock?.toString()?.toLowerCase() ?? 'true') === 'true',
        type,
        productCriteria: {
            condition,
            priceRangeStart,
            priceRangeEnd,
        },
        savedSearchId,
        savedSellerSearchId,
    };

    if (otherTerms && Object.keys(otherTerms).length > 0) {
        postObj.filters = otherTerms;
        if (postObj.filters['condition']) {
            delete postObj.filters['condition'];
        }
    }

    if (listingSearch && Object.keys(listingSearch).length > 0) {
        postObj.listingSearch = {
            sellerKey: listingSearch,
        };
    }

    const obj: query = {};

    if (q) {
        obj.q = q;
    }

    return {queryString: obj.q, postObj};
};

export const sellerSearchCreator = ({terms}: MarketPlaceSearchArgs): {queryString: string; postObj: SellerSearchRequest} => {
    const {
        sort,
        q,
        p,
        type,
        rawCondition,
        gradingCompany,
        gradeRangeStart,
        gradeRangeEnd,
        letterGrade,
        priceRangeStart,
        priceRangeEnd,
        myInventory,
        savedSearchId,
        savedSellerSearchId,
        ...otherTerms
    } = terms;

    const condition = {};
    if (rawCondition?.length > 0) {
        condition.raw = rawCondition;
    }
    if (gradingCompany?.length > 0) {
        condition.graded = {
            company: gradingCompany,
            rangeStart: parseFloat(gradeRangeStart?.toString()),
            rangeEnd: parseFloat(gradeRangeEnd?.toString()),
            letterGrade: letterGrade,
        };
    }

    const postObj = {
        filters: {},
        myInventory: myInventory ?? false,
        page: p === undefined ? 0 : parseInt(p),
        size: 50,
        sort: sort ?? 'default',
        type,
        productCriteria: {
            condition,
            priceRangeStart,
            priceRangeEnd,
        },
        savedSearchId,
        savedSellerSearchId,
    };

    if (otherTerms && Object.keys(otherTerms).length > 0) {
        postObj.filters = otherTerms;
        if (postObj.filters['condition']) {
            delete postObj.filters['condition'];
        }
    }

    const obj: query = {};

    if (q) {
        obj.q = q;
    }

    return {queryString: obj.q, postObj};
};
