import Grid from '@material-ui/core/Grid/Grid';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ConversationHistoryNormalizer from '../../../../../../../client/common/conversation/history/conversationsHistoryNormalizer';
import useDateNormalizer from '../../../../../../../client/common/normalizer/dateTimeNormalizer';
import Normalizer from '../../../../../../../client/common/normalizer/normalizer';
import {Conversation, MessageReference} from '../../../../../../../client/openapitools/common';
import BSCCardBox from '../../../../../../../features/common/bscCardBox/bscCardBox';
import BSCLoading from '../../../../../../../features/common/bscLoading/bscLoading';
import BSCPagination from '../../../../../../../features/common/bscPagination/bscPagination';
import BSCTypography from '../../../../../../../features/common/bscTypography/bscTypography';
import BSCTableRowSkeleton from '../../../../../../../features/common/skeletons/BSCTableRowSkeleton';
import useScreenSnap from '../../../../../../../packages/core/src/hooks/useScreenSnap';
import {RootState} from '../../../../../../../redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        padding: '0',
        marginBottom: theme.spacing(2),
        overflowX: 'auto',
        borderRadius: 0,
    },
    table: {
        minWidth: 505,
    },
    tr: {
        '&:hover': {
            cursor: 'pointer',
        },
        '&:nth-child(even)': {
            backgroundColor: '#eee',
        },
    },
    th: {
        color: '#41474C',
        padding: '8px 15px',
        background: '#DAE0E6',
        borderBottom: 'none',
    },
    td: {
        borderBottom: '2px solid #e5e5e5',
        fontSize: theme.typography.subtitle1.fontSize,
    },
    checkboxWrapper: {
        background: theme.palette.common.white,
        border: '0.520833px solid #DAE0E6',
        boxShadow: '0px 0px 4px #B8C2CC',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        margin: 'auto 10px',
    },
    checkbox: {
        background: '#EF2424',
        boxShadow: 'inset 0px 0px 2.08333px rgba(0, 0, 0, 0.25), inset 0px 2.08333px 2.08333px rgba(255, 255, 255, 0.24)',
        borderRadius: '50%',
        width: '14px',
        height: '14px',
    },
    checked: {
        background: '#e5e5e5',
    },
}));

interface MessageTableProps {
    sizePerPage: number;
    conversationHistoryResults: Conversation[];
    loading: boolean;
    msgRef: MessageReference;
    page: number;
    setPage: (pageNum) => {};
}

const StickyHeadTable = ({sizePerPage, conversationHistoryResults, loading, msgRef, page, setPage}: MessageTableProps) => {
    const history = useHistory();
    const classes = useStyles();
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {isDesktop} = useScreenSnap();
    const dateNormalizer = useDateNormalizer();
    const handleRowClick = useCallback(
        (messageId, read) => {
            history.push({
                pathname: `messages/reply/${messageId}`,
                state: {isAlreadyRead: read},
            });
        },
        [history]
    );

    const pageHandler = useCallback((event, page) => {
        setPage(page - 1);
        window.scrollTo(0, 0);
    }, []);

    const [pwaOS, setPwaOS] = useState(null);
    useEffect(() => {
        if (marketplaceState) {
            if (marketplaceState.pwaSettings.pwaOS !== '') {
                setPwaOS(marketplaceState.pwaSettings.pwaOS);
            }
        }
    }, [marketplaceState]);

    return (
        <>
            <BSCCardBox className={classes.root} style={pwaOS !== null ? {height: 'calc(100vh - 215px)'} : {}}>
                {isDesktop && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table" className={classes.table}>
                            <TableHead>
                                {(conversationHistoryResults?.length > 0 || loading) && (
                                    <TableRow>
                                        <TableCell key="read" className={classes.th}>
                                            <BSCTypography size14 label=" " />
                                        </TableCell>
                                        <TableCell key="fromUserName" className={classes.th}>
                                            <BSCTypography size14 label="From" semibold />
                                        </TableCell>
                                        <TableCell key="subjectName" className={classes.th}>
                                            <BSCTypography size14 label="Subject" semibold />
                                        </TableCell>
                                        <TableCell key="orderNo" className={classes.th}>
                                            <BSCTypography size14 label="Order Number" semibold />
                                        </TableCell>
                                        <TableCell key="date" className={classes.th}>
                                            <BSCTypography size14 label="Date" semibold />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableHead>
                            <TableBody>
                                {loading && <BSCTableRowSkeleton amountOfRows={4} amountOfCells={5} />}
                                {ConversationHistoryNormalizer.getPagedConversationHistory(
                                    conversationHistoryResults,
                                    page,
                                    sizePerPage
                                )?.map((pagedConversation, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            onClick={() => handleRowClick(pagedConversation.convId, pagedConversation.read)}
                                            className={classes.tr}
                                        >
                                            <TableCell key="read" className={classes.td}>
                                                <div className={classes.checkboxWrapper}>
                                                    <div
                                                        className={classNames(classes.checkbox, {
                                                            [classes.checked]: pagedConversation.read,
                                                        })}
                                                    ></div>
                                                </div>
                                            </TableCell>
                                            <TableCell key="fromUserName" className={classes.td}>
                                                {pagedConversation.fromUserName}
                                            </TableCell>
                                            <TableCell key="subjectName" className={classes.td}>
                                                {Normalizer.getMessageSubject(msgRef, pagedConversation.subjectKey)}
                                            </TableCell>
                                            <TableCell key="orderNo" className={classes.td}>
                                                {pagedConversation.orderNo}
                                            </TableCell>
                                            <TableCell key="date" className={classes.td}>
                                                {dateNormalizer.getDisplayDateTime(pagedConversation.date)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!isDesktop && (
                    <>
                        {(conversationHistoryResults?.length > 0 || loading) && <BSCLoading loading={false} />}
                        {!loading && (
                            <Grid container>
                                {ConversationHistoryNormalizer.getPagedConversationHistory(conversationHistoryResults, 0, 999)?.map(
                                    (pagedConversation, index) => {
                                        return (
                                            <Grid
                                                key={index}
                                                item
                                                container
                                                xs={12}
                                                className={(classes.th, classes.tr)}
                                                style={{height: 64, borderBottom: '2px solid #e5e5e5'}}
                                                onClick={() => handleRowClick(pagedConversation.convId)}
                                            >
                                                <Grid item xs={1} spacing={1} className={classes.td} style={{paddingTop: 20}}>
                                                    {' '}
                                                    <div className={classes.checkboxWrapper}>
                                                        <div
                                                            className={classNames(classes.checkbox, {
                                                                [classes.checked]: pagedConversation.read,
                                                            })}
                                                        ></div>
                                                    </div>
                                                </Grid>
                                                <Grid item container xs={11} className={classes.td} style={{padding: 8}}>
                                                    <Grid item xs={6} spacing={1}>
                                                        <BSCTypography style={{padding: 4}} size10 label={pagedConversation.fromUserName} />
                                                        <BSCTypography style={{padding: 4}} size10 label={pagedConversation.orderNo} />
                                                    </Grid>
                                                    <Grid item xs={6} spacing={1}>
                                                        <BSCTypography
                                                            style={{padding: 4}}
                                                            size10
                                                            label={Normalizer.getMessageSubject(msgRef, pagedConversation.subjectKey)}
                                                        />
                                                        <BSCTypography
                                                            style={{padding: 4}}
                                                            size10
                                                            label={dateNormalizer.getDisplayDateTime(pagedConversation.date)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </BSCCardBox>
            {isDesktop && (
                <BSCPagination
                    total={conversationHistoryResults?.length}
                    sizePerPage={sizePerPage}
                    defaultPage={page + 1}
                    onChange={pageHandler}
                />
            )}
        </>
    );
};

export default StickyHeadTable;
