/* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as constants from '../../../../shared/constants';
import React, {useCallback, useEffect, useReducer, useMemo} from 'react';
import {FormHelperText} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import BSCContainer from '../../../../features/common/bscContainer/bscContainer';
import BSCCardBox from '../../../../features/common/bscCardBox/bscCardBox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {Link} from 'react-router-dom';
import BSCTypography from '../../../../features/common/bscTypography/bscTypography';
import BSCScroller from '../../../../features/common/bscScroller/bscScroller';
import BSCLoading from '../../../../features/common/bscLoading/bscLoading';
import BSCDivider from '../../../../features/common/bscDivider/bscDivider';
import BSCTextField from '../../../../features/common/bscTextField/bscTextField';
import SellersFormValidationsClient from '../../../../client/s3/sellersFormValidations/sellersFormValidationsClient';
import {useQuery} from '../../../../packages/core/src/hooks/useQuery';
import useForm from '../../../../packages/core/src/hooks/useForm';
import SellersFormValidationsNormalizer from '../../../../client/s3/sellersFormValidations/sellersFormValidationsNormalizer';
import BSCButton from '../../../../features/common/bscButton/bscButton';
import BSCRadioGroup from '../../../../features/common/bscRadioGroup/bscRadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BSCRadio from '../../../../features/common/bscRadio/bscRadio';
import {useState} from 'react';
import DwollaPayment from '../sellersPortal/components/DwollaPayment';
import {Address6} from 'ip-address';
import useReduxUser from '../../../../features/hooks/redux/user/useReduxUser';
import useApi from '../../../../features/hooks/redux/apiConfig/useApi';
import {useAutoCompleteAddress} from '../../../../features/common/bscAddress/bscAddress';
import {Alert} from '@material-ui/lab';
import produce from 'immer';
import {SellerProfile, UserApi as SellerUserApi, VerifyAmount} from '../../../../client/openapitools/seller';
import Checkbox from '@material-ui/core/Checkbox';
import {useHistory} from 'react-router';
import {currencyFormatter} from '../../../../packages/core/src/util/util';
import useScreenSnap from '../../../../packages/core/src/hooks/useScreenSnap';
import DwollaWebClient from '../sellersPortal/components/DwollaWebClient';
import {useDispatch, useSelector} from 'react-redux';
import {useAuthFunctions, useAuthState} from '../../../../AuthContext';
import {useMsal} from '@azure/msal-react';
import {AccountInfo, SilentRequest} from '@azure/msal-browser';
import {AUTH_REQUESTS} from '../../../../authConfig';
import useReduxToast from '../../../../features/hooks/redux/toast/useReduxToast';
import {UserApi} from '../../../../client/openapitools/marketplace/api';
import {RootState} from '../../../../redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#0002',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        //backgroundImage: 'url(/templates/src/backgrounds/bsc-back1.png)',
        backgroundColor: 'black',
        zIndex: 1,
    },
    container: {
        height: 'fit-content',
        position: 'relative',
        padding: theme.spacing(2, 0, 0, 0),
    },
    dwollaContainer: {
        height: 290,
        textAlign: 'center',

        '&: > .dwolla-input-container label': {
            textAlign: 'left !important',
            display: 'block !important',
            fontSize: '14px  !important',
            fontFamily: 'Poppins, sans-serif !important',
            fontWeight: '400 !important',
            lineHeight: '1.5 !important',
        },
    },
    label: {
        '&:after': {
            content: '*',
            color: theme.palette.primary,
        },
    },
    main: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
        '& input': {
            padding: theme.spacing(1.25, 1.75),
        },
    },
    radioGroup: {
        // '&>label>span:first-child': {
        //     padding: theme.spacing(0.5),
        // },
        alignItems: 'flex-start',
        marginLeft: theme.spacing(1),
    },
    closeIcon: {
        marginLeft: 'auto',
        paddingBottom: '5px',
        paddingTop: '5px',
        padding: theme.spacing(1),
        fontSize: '1.5rem',
        '&:hover': {
            color: theme.palette.common.black,
        },
    },
    paddingLeft: {
        paddingLeft: '10px',
    },
    topMargin: {
        marginTop: '8px',
    },
    topBottomMargin: {
        marginTop: '8px',
        marginBottom: '24px',
    },
    textCenter: {
        textAlign: 'center',
    },
    shippingTiersCell: {
        width: '14%',
        margin: '0 auto',
        borderRight: 'solid 1px',
    },
    shippingTiersLabel: {
        fontWeight: 'bold',
        fontSize: 14,
        padding: 2,
    },
    shippingTiersLabelMobile: {
        fontWeight: 'bold',
        fontSize: 12,
        padding: 2,
        marginTop: 6,
    },
    shippingTiersLabelMobileVariant: {
        fontWeight: 'bold',
        fontSize: 12,
        padding: '8px 0 0',
    },
    shippingTiersOptionsLabel: {
        fontWeight: 'bold',
        fontSize: 12,
        padding: 2,
        marginTop: 7,
    },
    shippingTiersOptionsCheckbox: {
        paddingRight: 0,
        paddingTop: 6,
        display: 'inline-block',
    },
    ctas: {
        textAlign: 'left',
        '& p:first-child': {
            color: '#358856 !important',
        },
    },
    ctasMobile: {
        textAlign: 'right',
        '& p:first-child': {
            color: '#358856 !important',
        },
    },
    textInputMobile: {
        '& div': {
            '& input': {
                fontSize: '12px !important',
                padding: '10px 0 13px 0px',
                textAlign: 'center',
                margin: 1,
            },
        },
    },
}));

interface SignUpWizardPartProps {
    onSubmit;
    classes;
    loading?: boolean;
    sellerProfile: SellerProfile;
    handleBack?: () => void;
}

const AddressInfo = ({onSubmit, classes, sellerProfile = {}}: SignUpWizardPartProps) => {
    const {address, setAddress, formValid, firstName, lastName, address1, address2, city, state, zip} = useAutoCompleteAddress(
        sellerProfile.storeAddress,
        {},
        {
            style: {
                fontSize: 12,
            },
        }
    );
    const api = useApi();
    const [sellerStoreName, setsellerStoreName] = useState(sellerProfile.sellerStoreName ?? '');
    const [storePhoneNumber, setstorePhoneNumber] = useState(sellerProfile.storePhoneNumber ?? '');
    const [validate, setValidate] = useState(false);
    const [isStoreNameMissing, setIsStoreNameMissing] = useState(sellerProfile.sellerStoreName ? true : false);
    const [isStoreNameValid, setIsStoreNameValid] = useState(true);
    const [isStorePhoneValid, setIsStorePhoneValid] = useState(false);
    const {data: sellersListing} = useQuery(() => api.marketplaceApi.sellerApi.getAllSellers(), []);
    const handleSubmit = () => {
        onSubmit({storeAddress: address, sellerStoreName, storePhoneNumber});
    };

    useEffect(() => {
        if (sellerProfile) {
            setsellerStoreName(sellerProfile.sellerStoreName ?? '');
            setAddress(sellerProfile.storeAddress ?? '');
        }
    }, [sellerProfile]);

    const isFormValid = useMemo(
        () => formValid && sellerStoreName && storePhoneNumber && firstName && lastName && isStoreNameValid && isStorePhoneValid,
        [formValid, sellerStoreName, firstName, lastName, storePhoneNumber, validate, isStoreNameValid, isStorePhoneValid]
    );

    useEffect(() => {
        validateStoreName(sellerStoreName);
        validatePhoneNumber(storePhoneNumber);
        setValidate(true);
    }, []);

    const [isStoreNameTaken, setIsStoreNameTaken] = useState(false);
    const checkForDuplicateStoreName = storeName => {
        let isDuplicate = false;
        if (sellersListing && sellersListing.data) {
            const allSellers = [...sellersListing.data];
            allSellers.map(seller => {
                if (seller.sellerStoreName === storeName) {
                    setIsStoreNameTaken(true);
                    isDuplicate = true;
                }
            });
        }
        return isDuplicate;
    };

    const validateStoreName = name => {
        setIsStoreNameTaken(false);
        if (name.trim() !== '') {
            setIsStoreNameMissing(false);

            const isDuplicateStoreName = checkForDuplicateStoreName(name);
            if (isDuplicateStoreName) {
                setIsStoreNameTaken(true);
                return false;
            }
            if (name.toLowerCase().indexOf('.com') === -1 && name.toLowerCase().indexOf('dotcom') === -1) {
                setIsStoreNameValid(true);
                return true;
            } else {
                setIsStoreNameValid(false);
                return false;
            }
        } else {
            setIsStoreNameMissing(true);
            return false;
        }
    };

    const validatePhoneNumber = phone => {
        const phoneRegex = '^\\(?[\\d]{3}\\)?[\\s-]?[\\d]{3}[\\s-]?[\\d]{4}$';
        if (phone.trim() !== '' && phone.match(phoneRegex)) {
            setIsStorePhoneValid(true);
            return true;
        } else {
            setIsStorePhoneValid(false);
            return false;
        }
    };

    const handleStoreNameChange = e => {
        if (validateStoreName(e.target.value)) {
            setsellerStoreName(e.target.value);
        }
    };
    const handleStorePhoneChange = e => {
        if (validatePhoneNumber(e.target.value)) {
            setstorePhoneNumber(e.target.value);
        }
    };

    return (
        <Grid container className={classes.main}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} className={classes.topMargin}>
                        <BSCTypography label="Your Seller Name" />
                    </Grid>
                    <Grid item xs={12}>
                        <BSCTextField
                            inputKey="sellerStoreName"
                            placeholder="Enter Your Seller Name"
                            onChange={e => setsellerStoreName(e.target.value)}
                            onBlur={handleStoreNameChange}
                            value={sellerStoreName}
                            fullWidth
                        />
                        <FormHelperText style={{color: '#f44336'}} hidden={!isStoreNameMissing}>
                            Your Seller Name is required
                        </FormHelperText>
                        <FormHelperText style={{color: '#f44336'}} hidden={isStoreNameValid}>
                            Your Seller Name cannot contain a .com
                        </FormHelperText>
                        <FormHelperText style={{color: '#f44336'}} hidden={!isStoreNameTaken}>
                            Your Seller Name is already taken. Please choose another.
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={12}>
                    <Grid item xs={12} className={classes.topMargin}>
                        <BSCTypography label="Phone Number" />
                    </Grid>
                    <Grid item xs={12}>
                        <BSCTextField
                            inputKey="storePhoneNumber"
                            placeholder="Enter Number"
                            onChange={e => setstorePhoneNumber(e.target.value)}
                            onBlur={handleStorePhoneChange}
                            value={storePhoneNumber}
                            fullWidth
                        />
                        <FormHelperText style={{color: '#f44336'}} hidden={isStorePhoneValid}>
                            A valid Phone Number is Required
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.topMargin}>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <BSCTypography label="First Name" />
                    </Grid>
                    <Grid item xs={12}>
                        {firstName}
                    </Grid>
                </Grid>
                <Grid item xs={6} className={classes.paddingLeft}>
                    <Grid item xs={12}>
                        <BSCTypography label="Last Name" />
                    </Grid>
                    <Grid item xs={12}>
                        {lastName}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.topMargin}>
                <Grid container>
                    <Grid item xs={12}>
                        <BSCTypography label="Address 1" />
                    </Grid>
                    <Grid item xs={12}>
                        {address1}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.topMargin}>
                <Grid container>
                    <Grid item xs={12}>
                        <BSCTypography label="Address 2" />
                    </Grid>
                    <Grid item xs={12}>
                        {address2}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.topMargin}>
                <Grid item xs={7}>
                    <Grid container>
                        <Grid item xs={12}>
                            <BSCTypography label="City" />
                        </Grid>
                        <Grid item xs={12}>
                            {city}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} className={classes.paddingLeft}>
                    <Grid item xs={12}>
                        <BSCTypography label="State" />
                    </Grid>
                    <Grid item xs={12}>
                        {state}
                    </Grid>
                </Grid>
                <Grid item xs={3} className={classes.paddingLeft}>
                    <Grid item xs={12}>
                        <BSCTypography label="Zip" />
                    </Grid>
                    <Grid item xs={12}>
                        {zip}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.topMargin}>
                <BSCButton disabled={!isFormValid} color="primary" onClick={handleSubmit} fullWidth>
                    Next
                </BSCButton>
            </Grid>
        </Grid>
    );
};

const StoreInfo = ({onSubmit, classes, loading, sellerProfile = {}, handleBack}: SignUpWizardPartProps) => {
    const api = useApi();
    const {isMobile} = useScreenSnap();
    const [offers, setOffers] = useState('');
    const [shippingTier_XS, setShippingTier_XS] = useState('0.00');
    const [shippingTier_S, setShippingTier_S] = useState('0.00');
    const [shippingTier_M, setShippingTier_M] = useState('0.00');
    const [shippingTier_L, setShippingTier_L] = useState('0.00');
    const [shippingTier_XL, setShippingTier_XL] = useState('0.00');
    const [shippingTierGraded, setShippingTierGraded] = useState('0.00');
    const [shippingTierFree, setShippingTierFree] = useState('0.00');
    const [hasFreeShippingTier, setHasFreeShippingTier] = useState(false);
    const re = /^(\d*\.{0,1}\d{0,2}$)/;
    const [isFormValid, setIsFormValid] = useState(false);

    const handleSubmit = () => {
        const outboundShippingTiers = [];
        outboundShippingTiers.push({tierCode: 'XS', rate: parseFloat(shippingTier_XS)});
        outboundShippingTiers.push({tierCode: 'S', rate: parseFloat(shippingTier_S)});
        outboundShippingTiers.push({tierCode: 'M', rate: parseFloat(shippingTier_M)});
        outboundShippingTiers.push({tierCode: 'L', rate: parseFloat(shippingTier_L)});
        outboundShippingTiers.push({tierCode: 'XL', rate: parseFloat(shippingTier_XL)});
        outboundShippingTiers.push({tierCode: 'G', rate: parseFloat(shippingTierGraded)});
        if (hasFreeShippingTier) {
            outboundShippingTiers.push({tierCode: 'F', rate: parseFloat(shippingTierFree)});
        }
        const listingSettings = {offers};
        onSubmit({listingSettings, shippingSettings: outboundShippingTiers});
    };

    const shippingRateFormatter = rate => {
        if (rate !== '') {
            const formattedRate = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(rate);
            return formattedRate.substring(1, formattedRate.length);
        }
        return rate;
    };

    const handleRadioChange = event => {
        setOffers(event.target.value);
        setIsFormValid(true);
    };

    const handleInputBlur = event => {
        let incomingValue = event.target.value;
        if (incomingValue === '') {
            incomingValue = '0.00';
        }
        switch (event.target.name) {
            case 'shippingTier_XS':
                setShippingTier_XS(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_S':
                setShippingTier_S(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_M':
                setShippingTier_M(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_L':
                setShippingTier_L(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTier_XL':
                setShippingTier_XL(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTierGraded':
                setShippingTierGraded(shippingRateFormatter(incomingValue));
                break;
            case 'shippingTierFree':
                setShippingTierFree(shippingRateFormatter(incomingValue));
                break;
        }
    };

    const handleFreeShippingClick = () => {
        setShippingTier_XS('0.00');
        setShippingTier_S('0.00');
        setShippingTier_M('0.00');
        setShippingTier_L('0.00');
        setShippingTier_XL('0.00');
        setShippingTierGraded('0.00');
    };

    const handleRecommendedRatesClick = () => {
        setShippingTier_XS('0.85');
        setShippingTier_S('1.35');
        setShippingTier_M('3.99');
        setShippingTier_L('4.99');
        setShippingTier_XL('5.99');
        setShippingTierGraded('3.99');
    };

    useEffect(() => {
        if (sellerProfile) {
            if (sellerProfile.shippingSettings) {
                sellerProfile.shippingSettings.forEach(tier => {
                    switch (tier.tierCode) {
                        case 'XS':
                            setShippingTier_XS(shippingRateFormatter(tier.rate));
                            break;
                        case 'S':
                            setShippingTier_S(shippingRateFormatter(tier.rate));
                            break;
                        case 'M':
                            setShippingTier_M(shippingRateFormatter(tier.rate));
                            break;
                        case 'L':
                            setShippingTier_L(shippingRateFormatter(tier.rate));
                            break;
                        case 'XL':
                            setShippingTier_XL(shippingRateFormatter(tier.rate));
                            break;
                        case 'G':
                            setShippingTierGraded(shippingRateFormatter(tier.rate));
                            break;
                        case 'F':
                            setShippingTierFree(shippingRateFormatter(tier.rate));
                            setHasFreeShippingTier(true);
                            break;
                    }
                });
            }
            if (sellerProfile.listingSettings) {
                setOffers(sellerProfile.listingSettings.offers.toString());
                setIsFormValid(true);
            }
        }
    }, [sellerProfile]);

    return (
        <Grid container className={classes.main} direction="column">
            <Grid item>
                <BSCTypography variant="h4" center underline>
                    Seller Settings
                </BSCTypography>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="h5">Do you want to allow buyers to send you offers?</BSCTypography>
            </Grid>
            <Grid item>
                <BSCRadioGroup inputKey="offers" onChange={handleRadioChange} value={offers} className={classes.radioGroup} start>
                    <FormControlLabel value="true" control={<BSCRadio />} label="Yes" />
                    <FormControlLabel value="false" control={<BSCRadio />} label="No" />
                </BSCRadioGroup>
            </Grid>
            <Grid item container className={classes.topMargin}>
                <Grid item md={7} xs={12}>
                    <BSCTypography variant="h5">Enter Your Shipping Rates:</BSCTypography>
                </Grid>
                <Grid item md={5} xs={12}>
                    <div className={isMobile ? classes.ctasMobile : classes.ctas}>
                        <BSCTypography size12 bold style={{paddingTop: 2, cursor: 'pointer'}} onClick={handleFreeShippingClick}>
                            Use Free Shipping
                        </BSCTypography>
                        <BSCTypography
                            size12
                            bold
                            color="primary"
                            style={{paddingTop: 2, cursor: 'pointer'}}
                            onClick={handleRecommendedRatesClick}
                        >
                            Use Recommended Rates
                        </BSCTypography>
                    </div>
                </Grid>{' '}
            </Grid>
            <Grid item style={{marginTop: 4, border: 'solid 2px #DAE0E6'}}>
                <Grid container md={12} style={{textAlign: 'center'}}>
                    <div className={classes.shippingTiersCell} style={{width: '28%'}}>
                        <BSCTypography className={isMobile ? classes.shippingTiersLabelMobileVariant : classes.shippingTiersLabel}>
                            # of Raw Cards
                        </BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={isMobile ? classes.shippingTiersLabelMobile : classes.shippingTiersLabel}>
                            1 - 4
                        </BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={isMobile ? classes.shippingTiersLabelMobile : classes.shippingTiersLabel}>
                            5 - 12
                        </BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={isMobile ? classes.shippingTiersLabelMobile : classes.shippingTiersLabel}>
                            13 - 24
                        </BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTypography className={isMobile ? classes.shippingTiersLabelMobile : classes.shippingTiersLabel}>
                            25 - 49
                        </BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell} style={{borderRight: 'none'}}>
                        <BSCTypography
                            style={
                                isMobile
                                    ? {fontWeight: 'bold', fontSize: 12, padding: 2, marginTop: 6}
                                    : {fontWeight: 'bold', fontSize: 14, padding: 2}
                            }
                        >
                            50+
                        </BSCTypography>
                    </div>
                </Grid>
                <Grid container md={12} style={{textAlign: 'center', borderTop: 'solid 1px #DAE0E6'}}>
                    <div className={classes.shippingTiersCell} style={{width: '28%'}}>
                        <BSCTypography
                            style={
                                isMobile
                                    ? {paddingTop: 8, fontWeight: 'bold', fontSize: 12}
                                    : {paddingTop: 8, fontWeight: 'bold', fontSize: 14}
                            }
                        >
                            Price
                        </BSCTypography>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTextField
                            inputKey="shippingTier_XS"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_XS(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            className={isMobile ? classes.textInputMobile : null}
                            value={shippingTier_XS}
                        ></BSCTextField>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTextField
                            inputKey="shippingTier_S"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_S(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            className={isMobile ? classes.textInputMobile : null}
                            value={shippingTier_S}
                        ></BSCTextField>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTextField
                            inputKey="shippingTier_M"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_M(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            className={isMobile ? classes.textInputMobile : null}
                            value={shippingTier_M}
                        ></BSCTextField>
                    </div>
                    <div className={classes.shippingTiersCell}>
                        <BSCTextField
                            inputKey="shippingTier_L"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_L(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            className={isMobile ? classes.textInputMobile : null}
                            value={shippingTier_L}
                        ></BSCTextField>
                    </div>
                    <div className={classes.shippingTiersCell} style={{borderRight: 'none'}}>
                        <BSCTextField
                            inputKey="shippingTier_XL"
                            fullWidth
                            onChange={e => {
                                if (re.test(e.target.value)) {
                                    setShippingTier_XL(e.target.value);
                                }
                            }}
                            onBlur={handleInputBlur}
                            className={isMobile ? classes.textInputMobile : null}
                            value={shippingTier_XL}
                        ></BSCTextField>
                    </div>
                </Grid>
            </Grid>

            <Grid item container className={classes.topMargin}>
                <Grid item md={4} xs={6}>
                    <BSCTypography className={classes.shippingTiersOptionsLabel}>Price Per Graded Card:</BSCTypography>
                </Grid>
                <Grid item md={2} xs={6} style={isMobile ? {} : {flexBasis: '13%'}}>
                    <BSCTextField
                        inputKey="shippingTierGraded"
                        onChange={e => {
                            if (re.test(e.target.value)) {
                                setShippingTierGraded(e.target.value);
                            }
                        }}
                        onBlur={handleInputBlur}
                        className={isMobile ? classes.textInputMobile : null}
                        style={isMobile ? {width: '30%', marginLeft: 4} : {minWidth: 'fit-content'}}
                        value={shippingTierGraded}
                    ></BSCTextField>
                </Grid>
                <Grid item md={1} xs={1} style={isMobile ? {marginTop: 8, marginBottom: 32} : {flexBasis: 0, marginLeft: 36}}>
                    <Checkbox
                        className={classes.shippingTiersOptionsCheckbox}
                        color="primary"
                        onChange={e => {
                            setHasFreeShippingTier(e.target.checked);
                            if (!e.target.checked) {
                                setShippingTierFree('');
                            }
                        }}
                        style={{paddingLeft: 0}}
                        checked={hasFreeShippingTier}
                    />
                </Grid>
                <Grid item md={3} xs={5} style={isMobile ? {marginTop: 8, marginBottom: 32} : {minWidth: 121, textAlign: 'right'}}>
                    <BSCTypography className={classes.shippingTiersOptionsLabel}>Free Shipping Over</BSCTypography>
                </Grid>
                <Grid item md={2} xs={6} style={isMobile ? {marginTop: 8, marginBottom: 32} : {flexBasis: '14%'}}>
                    <BSCTextField
                        disabled={!hasFreeShippingTier}
                        inputKey="shippingTierFree"
                        onChange={e => {
                            if (re.test(e.target.value)) {
                                setShippingTierFree(e.target.value);
                            }
                        }}
                        onBlur={handleInputBlur}
                        className={isMobile ? classes.textInputMobile : null}
                        value={shippingTierFree}
                        style={
                            hasFreeShippingTier
                                ? isMobile
                                    ? {width: '30%', marginLeft: 4}
                                    : {minWidth: 'fit-content'}
                                : isMobile
                                  ? {backgroundColor: 'rgba(0, 0, 0, 0.12)', width: '30%', marginLeft: 4}
                                  : {backgroundColor: 'rgba(0, 0, 0, 0.12)', width: '30%', marginLeft: 3, minWidth: 'fit-content'}
                        }
                    ></BSCTextField>
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.textCenter}>
                <Grid item xs={6} md={6} style={{paddingTop: 16, paddingRight: 16}}>
                    <BSCButton loading={loading} color="primary" fullWidth onClick={handleBack}>
                        Back
                    </BSCButton>
                </Grid>
                <Grid item xs={6} md={6} style={{paddingTop: 16, paddingLeft: 16}}>
                    <BSCButton disabled={!isFormValid} loading={loading} color="secondary" fullWidth onClick={handleSubmit}>
                        Next
                    </BSCButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const AboutYou = ({onSubmit, classes, sellerProfile = {}, handleBack}: SignUpWizardPartProps) => {
    const handleSubmit = () => {
        onSubmit(state);
    };
    const api = useApi();

    const {data: refChannels, loading: refLoading} = useQuery(() => api.commonApi.referenceApi.getReferralChannels(), []);

    const reducer = (draft, action: {target: string; payload?}) => {
        switch (action.target) {
            case 'reinitialize':
                draft.localCardShop = action.payload.localCardShop;
                draft.referralChannel = action.payload.referralChannel;
                break;
            default:
                draft[action.target] = action.payload;
                break;
        }

        if (draft.acceptTerms === true && draft.referralChannel && draft.localCardShop !== undefined) {
            draft.isFormValid = true;
        } else {
            draft.isFormValid = false;
        }
    };

    const [state, dispatch] = useReducer(produce(reducer), {
        localCardShop: sellerProfile.localCardShop,
        referralChannel: sellerProfile.referralChannel,
        isFormValid: false,
    });

    useEffect(() => {
        dispatch({target: 'reinitialize', payload: sellerProfile});
    }, [sellerProfile]);

    const handleChange = event => {
        dispatch({target: event.target.name, payload: event.target.value});
    };
    const handleBlur = event => {
        const val = event.target.value;
        dispatch({target: event.target.name, payload: val});
    };

    useEffect(() => {
        dispatch({target: 'default', payload: state.sellerProfile?.localCardShop});
    }, []);

    return (
        <Grid container className={classes.main} direction="column">
            <Grid item>
                <BSCTypography variant="h4" center underline>
                    About You
                </BSCTypography>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="h5">Are you a Local Card Shop? </BSCTypography>
            </Grid>
            <Grid item>
                <BSCRadioGroup
                    inputKey="localCardShop"
                    onChange={handleChange}
                    value={state.localCardShop?.toString() ?? null}
                    className={classes.radioGroup}
                    start
                >
                    <FormControlLabel value="true" control={<BSCRadio />} label="Yes" />
                    <FormControlLabel value="false" control={<BSCRadio />} label="No" />
                </BSCRadioGroup>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="h5">How did you hear about BuySportsCards.com?</BSCTypography>
            </Grid>
            <Grid item container direction="row">
                <BSCRadioGroup
                    inputKey="referralChannel"
                    onChange={handleChange}
                    value={state.referralChannel}
                    className={classes.radioGroup}
                    start
                >
                    {refChannels?.data.map(
                        (x, index) =>
                            x.active && (
                                <Grid key={index} item xs={4}>
                                    <FormControlLabel value={x.key} control={<BSCRadio />} label={x.name} />
                                </Grid>
                            )
                    )}
                </BSCRadioGroup>
            </Grid>
            <Grid item container justifyContent={'center'} style={{marginBottom: 24}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.acceptTerms ?? null}
                            onChange={event => dispatch({target: event.target.name, payload: event.target.checked})}
                            name="acceptTerms"
                            color="primary"
                        />
                    }
                    label={
                        <>
                            <BSCTypography>
                                I have read and agree to the{' '}
                                <Link to="/terms" rel="noopener noreferrer" target="_blank">
                                    Terms of Service
                                </Link>
                            </BSCTypography>
                        </>
                    }
                />
            </Grid>
            <Grid item container xs={12} className={classes.textCenter}>
                <Grid item xs={6} md={6} style={{paddingRight: 16}}>
                    <BSCButton color="primary" fullWidth onClick={handleBack}>
                        Back
                    </BSCButton>
                </Grid>
                <Grid item xs={6} md={6} style={{paddingLeft: 16}}>
                    <BSCButton disabled={!state.isFormValid} color="secondary" fullWidth onClick={handleSubmit}>
                        Next
                    </BSCButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const NoticePage = ({onSubmit, classes, loading, sellerProfile = {}, handleBack}: SignUpWizardPartProps) => {
    const handleSubmit = () => {
        onSubmit();
    };

    return (
        <Grid container className={classes.main} direction="column">
            <Grid item>
                <BSCTypography variant="body2" center underline size24>
                    IMPORTANT
                </BSCTypography>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="body2" size16>
                    You will now be moving on to enter your bank information. BSC utilizes Dwolla to ensure your data is stored securely.
                </BSCTypography>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="body2" size16></BSCTypography>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="body2" size16>
                    BuySportsCards.com has implemented this step because we take protecting your information very seriously. To learn more
                    about Dwolla, visit{' '}
                    <a href="https://www.dwolla.com/updates/ach-bank-verification/" target="_blank">
                        Dwolla Verification Methods
                    </a>
                </BSCTypography>
            </Grid>
            <Grid item container className={classes.textCenter}>
                <Grid item xs={12}>
                    <BSCButton
                        disabled={loading}
                        loading={loading}
                        color="secondary"
                        fullWidth
                        onClick={handleSubmit}
                        className={classes.topMargin}
                    >
                        Continue
                    </BSCButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const MDVVerify = ({onSubmit, classes, loading, sellerProfile = {}}: SignUpWizardPartProps) => {
    const handleSubmit = () => {
        onSubmit(firstAmount, secondAmount);
    };

    const [firstAmount, setFirstAmount] = useState('0.0');
    const [secondAmount, setSecondAmount] = useState('0.0');

    const handleFirstBlur = event => {
        setFirstAmount(currencyFormatter(event.target.value));
    };
    const handleSecondBlur = event => {
        setSecondAmount(currencyFormatter(event.target.value));
    };

    return (
        <Grid container className={classes.main} direction="column">
            <Grid item>
                <BSCTypography variant="body2" size16 center semibold>
                    Please verify the Micro Deposits made to your account.
                </BSCTypography>
            </Grid>
            <Grid item>
                <BSCTypography size12 center semibold>
                    (It may take up to 3 business days to receive your deposits.)
                </BSCTypography>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="body2">Enter the first amount deposited to your account:</BSCTypography>
            </Grid>
            <Grid item>
                <BSCTextField
                    placeholder="First Amount"
                    fullWidth
                    onChange={e => setFirstAmount(e.target.value)}
                    onBlur={handleFirstBlur}
                    value={firstAmount}
                ></BSCTextField>
            </Grid>
            <Grid item className={classes.topMargin}>
                <BSCTypography variant="body2">Enter the second amount deposited to your account:</BSCTypography>
            </Grid>
            <Grid item>
                <BSCTextField
                    placeholder="Second Amount"
                    fullWidth
                    onChange={e => setSecondAmount(e.target.value)}
                    onBlur={handleSecondBlur}
                    value={secondAmount}
                ></BSCTextField>
            </Grid>
            <Grid item container className={classes.textCenter}>
                <Grid item xs={12}>
                    <BSCButton disabled={loading} loading={loading} color="secondary" onClick={handleSubmit} className={classes.topMargin}>
                        <BSCTypography label={'Verify'} size12 />
                    </BSCButton>
                    {/* <Link href="#" to={'/sellers'} style={{textDecoration: 'underline', color: '#000', fontWeight: 'bold'}}>
                        Verify
                    </Link> */}
                </Grid>
            </Grid>
        </Grid>
    );
};
const wizardSteps = {
    basicInfo: 'BASIC_INFO',
    address: 'ADDRESS_INFO',
    storeInfo: 'STORE_INFO',
    aboutYou: 'ABOUT_YOU',
    noticePage: 'NOTICE_PAGE',
};

const SignUpWizard = ({
    onSignupInfoComplete,
    signupError,
    dwollaCustomerLoading,
    sellerProfile = {},
    noticeConfirmed,
}: {
    onSignupInfoComplete;
    signupError;
    dwollaCustomerLoading: boolean;
    sellerProfile: SellerProfile;
    noticeConfirmed: (conf) => void;
}) => {
    const [step, setStep] = useState(wizardSteps.address);
    const [signupState, setSignUpState] = useState<SellerProfile>(sellerProfile);
    const classes = useStyles();

    const handleFormStepComplete = form => {
        if (step === wizardSteps.basicInfo) {
            setStep(wizardSteps.address);
            setSignUpState(form);
        }
        if (step === wizardSteps.address) {
            const signup = {...signupState, ...form};
            setSignUpState(signup);
            setStep(wizardSteps.storeInfo);
        }
        if (step === wizardSteps.storeInfo) {
            const signup = {...signupState, ...form};
            setSignUpState(signup);
            setStep(wizardSteps.aboutYou);
        }
        if (step === wizardSteps.aboutYou) {
            const signup = {...signupState, ...form};
            setSignUpState(signup);
            onSignupInfoComplete(signup);
            setStep(wizardSteps.noticePage);
        }
        if (step === wizardSteps.noticePage) {
            noticeConfirmed(true);
        }
    };

    const handleBack = () => {
        if (step === wizardSteps.storeInfo) {
            setStep(wizardSteps.address);
        }
        if (step === wizardSteps.aboutYou) {
            setStep(wizardSteps.storeInfo);
        }
    };

    const [StepComponent, setStepComponent] = useState<React.ReactNode>();

    useEffect(() => {
        switch (step) {
            case wizardSteps.address:
                setStepComponent(<AddressInfo onSubmit={handleFormStepComplete} classes={classes} sellerProfile={signupState} />);
                break;
            case wizardSteps.storeInfo:
                setStepComponent(
                    <StoreInfo
                        loading={dwollaCustomerLoading}
                        onSubmit={handleFormStepComplete}
                        classes={classes}
                        sellerProfile={signupState}
                        handleBack={handleBack}
                    />
                );
                break;

            case wizardSteps.aboutYou:
                setStepComponent(
                    <AboutYou onSubmit={handleFormStepComplete} classes={classes} sellerProfile={signupState} handleBack={handleBack} />
                );
                break;

            case wizardSteps.noticePage:
                setStepComponent(
                    <NoticePage
                        onSubmit={handleFormStepComplete}
                        classes={classes}
                        sellerProfile={signupState}
                        loading={dwollaCustomerLoading}
                    />
                );
                break;
            default:
                setStepComponent(<AddressInfo onSubmit={handleFormStepComplete} classes={classes} sellerProfile={signupState} />);
                break;
        }
    }, [step, dwollaCustomerLoading, signupState]);

    if (signupError) {
        return <Alert color="warning">{signupError}</Alert>;
    }

    return (
        <>
            {sellerProfile?.applicationStatus === 'pending' && step !== wizardSteps.noticePage && (
                <Alert color="warning">Signup is pending. Please verify your information and complete adding payment information</Alert>
            )}
            {StepComponent}
        </>
    );
};

const SellerSignup = () => {
    const history = useHistory();
    const {instance} = useMsal();
    const {apiAuthConfig} = useAuthState();
    let sellerUserApi = new SellerUserApi(apiAuthConfig);
    const marketplaceState = useSelector((state: RootState) => state.marketplace);
    const {isDesktop} = useScreenSnap();
    const classes = useStyles();
    const {addToast} = useReduxToast();
    const authFunc = useAuthFunctions();

    // const {userState} = useReduxUser();
    const [userState, setUserState] = useState(null);

    useEffect(() => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const response = await userApi.getUserProfile();
                            setUserState(response.data);
                        } catch (error) {
                            console.error('Error fetching user', error);
                            addToast({
                                severity: 'error',
                                message: 'An error has occurred.',
                                contextKey: 'Authentication',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    }, [instance]);

    const dispatch = useDispatch();
    const {loading: sellerFormValidationsLoading, data: sellerFormValidations} = useQuery(
        () => SellersFormValidationsClient.getSellersFormValidations(),
        []
    );

    const [dwollaCustomerLoading, setDwollaCustomerLoading] = useState<boolean>(false);
    const [noticeConfirmed, setNoticeConfirmed] = useState<boolean>(false);
    const [dwollaCustomerId, setDwollaCustomerId] = useState<string>(null);
    const [dwollaCustomerUrl, setDwollaCustomerUrl] = useState<string>(null);
    const [ip, setIP] = useState('');
    const {
        formState,
        validatedSchema: formStateValidations,
        registerFormChange,
        formComplete,
    } = useForm({
        validationSchema: SellersFormValidationsNormalizer.getSignupValidation(sellerFormValidations),
    });

    const [signupError, setSignupError] = useState<string>();
    const [verifyError, setVerifyError] = useState<string>('');

    const showMDVVerify =
        userState?.sellerProfile?.paymentSettings?.fundingSourceStatus === 'unverified' &&
        userState?.sellerProfile?.paymentSettings?.verifyMicroDepositsUrl;

    /*
        Ya, we are calling update seller for everything.  Who knows why but that is the way the API is setup.

        CU-1mev1ef
        go ahead and make always PUT seller/profile call with the same User payload(with seller profile populated as already updated in the card)
    */

    const [createSellerProfileLoading, setCreateSellerProfileLoading] = useState<boolean>(false);
    const createSellerProfile = user => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        setCreateSellerProfileLoading(true);
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const sellerUserApi = new SellerUserApi(apiConfig);
                            const sellerProfileResponse = await sellerUserApi.updateSellerUserProfile(user);
                            setCreateSellerProfileLoading(false);
                            if (sellerProfileResponse.status === 200 || sellerProfileResponse.status === 201) {
                                setDwollaCustomerUrl(sellerProfileResponse.data?.sellerProfile?.paymentSettings?.customerUrl);
                                const tempDwollaCustomerId =
                                    sellerProfileResponse.data?.sellerProfile?.paymentSettings?.customerUrl.split('/')[
                                        sellerProfileResponse.data?.sellerProfile?.paymentSettings?.customerUrl.split('/').length - 1
                                    ];
                                setDwollaCustomerId(tempDwollaCustomerId);
                                setDwollaCustomerLoading(false);
                            } else {
                                setSignupError('There was an error attempting to complete signup.  Please try again later.');
                            }
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to create Seller user profile.',
                                contextKey: 'Seller User Profile',
                            });
                            setCreateSellerProfileLoading(false);
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    const putSellerAddress = sellerAddressData => {
        const account = instance.getActiveAccount();
        if (account !== null) {
            const tokenRequest: SilentRequest = {
                account: account as AccountInfo | undefined,
                scopes: AUTH_REQUESTS.LOGIN.scopes,
            };
            instance
                .acquireTokenSilent(tokenRequest)
                .then(async response => {
                    if (response.accessToken) {
                        const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                        try {
                            const userApi = new UserApi(apiConfig);
                            const sellerAddressResponse = await userApi.updateAddress(sellerAddressData);
                            return sellerAddressResponse;
                        } catch (error) {
                            console.log(error);
                            addToast({
                                severity: 'error',
                                message: 'Failed to update Seller address.',
                                contextKey: 'Seller Address',
                            });
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(error => {
                    addToast({
                        severity: 'error',
                        message: 'User must be Signed In.',
                        contextKey: 'Authentication',
                    });
                    setTimeout(() => {
                        authFunc.logout();
                    }, 5000);
                });
        }
    };

    useEffect(() => {
        const address = new Address6('2001:0:ce49:7601:e866:efff:62c3:fffe');
        const teredo = address.inspectTeredo();
        setIP(teredo.client4);
    }, []);

    const onComplete = () => {
        //This is intentionally not using history.push.
        //This will force the app to call get user when the page loads.
        // window.location.href = '/sellers';
        window.location.href = '/';
    };

    const onSubmit = useCallback(
        async data => {
            setDwollaCustomerLoading(true);
            putSellerAddress({...data?.storeAddress, type: 'store'});
            createSellerProfile({
                sellerProfile: {
                    ...data,
                    ipAddress: ip,
                    localCardShop: data.localCardShop.toString().toLowerCase() === 'true',
                    sellerEmailId: userState.email,
                },
            });
        },
        [setSignupError, createSellerProfile, setDwollaCustomerId]
    );

    const handleMDVSubmit = useCallback(
        (firstAmt, secondAmt) => {
            const amt1 = {currency: 'USD', value: firstAmt};
            const amt2 = {currency: 'USD', value: secondAmt};

            // verifyMicroDeposits({amount1: amt1, amount2: amt2}).then(respon => {
            //     if (respon.status === respon.statusTypes.fulfilled) {
            //         setVerifyError('');
            //         dispatch({
            //             type: constants.HAS_VERIFIED_MICRO_DEPOSITS,
            //             payload: {hasVerifiedMicroDeposits: true},
            //         });
            //         history.replace('/sellers');
            //     } else {
            //         setVerifyError('The amounts could not be verified.');
            //     }
            // });
            const account = instance.getActiveAccount();
            if (account !== null) {
                const tokenRequest: SilentRequest = {
                    account: account as AccountInfo | undefined,
                    scopes: AUTH_REQUESTS.LOGIN.scopes,
                };
                instance
                    .acquireTokenSilent(tokenRequest)
                    .then(async response => {
                        if (response.accessToken) {
                            const apiConfig = {...apiAuthConfig, accessToken: response.accessToken};
                            try {
                                sellerUserApi = new SellerUserApi(apiConfig);
                                const vmdResponse = await sellerUserApi.verifyMicroDeposits({amount1: amt1, amount2: amt2});
                                if (vmdResponse.status === 200 || vmdResponse.status === 201) {
                                    setVerifyError('');
                                    dispatch({
                                        type: constants.HAS_VERIFIED_MICRO_DEPOSITS,
                                        payload: {hasVerifiedMicroDeposits: true},
                                    });
                                    history.replace('/sellers');
                                } else {
                                    setVerifyError('The amounts could not be verified.');
                                }
                            } catch (error) {
                                console.error('Error fetching user', error);
                                addToast({
                                    severity: 'error',
                                    message: 'An error has occurred.',
                                    contextKey: 'Authentication',
                                });
                            }
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch(error => {
                        addToast({
                            severity: 'error',
                            message: 'User must be Signed In.',
                            contextKey: 'Authentication',
                        });
                        setTimeout(() => {
                            authFunc.logout();
                        }, 5000);
                    });
            }
        },
        [instance]
    );

    const handleHardClose = () => {
        if (marketplaceState.pwaSettings.pwaOS !== '') {
            dispatch({
                type: constants.PWA_NAVBAR_ACTIVE_TAB,
                payload: {
                    pwaNavbarActive: 0,
                },
            });
        }
        history.push('/');
    };

    return (
        <BSCContainer className={classes.root}>
            {userState && (
                <BSCCardBox
                    className={classes.container}
                    maxWidth={dwollaCustomerId && noticeConfirmed ? 700 : 490}
                    width={dwollaCustomerId && noticeConfirmed ? 700 : 490}
                    height={dwollaCustomerId && noticeConfirmed ? (!isDesktop ? '460px !important' : '420px !important') : 'fit-content'}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8} className={classes.textCenter}>
                            <BSCTypography label="Become a BSC Seller!" style={{fontSize: 24}} />
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                            {/* <IconButton className={classes.closeIcon} color="default" aria-label="close" component={Link} to="/"> */}
                            <IconButton className={classes.closeIcon} color="default" aria-label="close" onClick={handleHardClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <BSCDivider />
                        </Grid>
                        <Grid item xs={12} className={noticeConfirmed ? classes.dwollaContainer : ''}>
                            {!showMDVVerify &&
                                (dwollaCustomerId && noticeConfirmed ? (
                                    <DwollaWebClient
                                        sellerUserApi={sellerUserApi}
                                        customerId={dwollaCustomerId}
                                        customerUrl={dwollaCustomerUrl}
                                        onDwollaComplete={onComplete}
                                    />
                                ) : (
                                    <BSCScroller maxHeight={'75vh'} scrollX={false}>
                                        <SignUpWizard
                                            onSignupInfoComplete={onSubmit}
                                            signupError={signupError}
                                            sellerProfile={userState?.sellerProfile}
                                            dwollaCustomerLoading={dwollaCustomerLoading}
                                            noticeConfirmed={setNoticeConfirmed}
                                        ></SignUpWizard>
                                    </BSCScroller>
                                ))}
                            {showMDVVerify && (
                                <>
                                    {verifyError && <BSCTypography label={verifyError} style={{color: 'red'}} size16 />}

                                    <MDVVerify classes={classes} onSubmit={handleMDVSubmit} />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </BSCCardBox>
            )}
        </BSCContainer>
    );
};

export default SellerSignup;
